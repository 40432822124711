import { IntegrationItemType } from "src/app/common/models/integration/integration-item-type.enum";
import { ObjectUtils } from "src/app/common/utils/object.utils";
import { AppEnvironment } from "src/environments/app-enviroment.model";

/**
 * Test окружение.
 */
export const environment: AppEnvironment = {
    production: false,
    authServerUrl: 'https://id-test.entera.pro',
    appServerUrl: 'https://app-test.entera.pro',
    enteraLandingUrl: 'http://entera.pro',
    ncaLayerUrl: 'wss://127.0.0.1:13579/',
    global: false,
    localKnowledgeBaseUrl: "https://entera.omnidesk.ru/l_rus/knowledge_base/",
    globalKnowledgeBaseUrl: "https://space-team.atlassian.net/wiki/spaces/EG/overview",
    registryForeignFilterDefault: null,
    privacyPolicy: "https://entera.pro/privacy",
    globalPrivacyPolicy: "https://enteraglobal.in/privacy",
    metrikaDisabled: false,
    newSpacesPage: true,
    userNotificationInterval: 24,
    maxRowCountForMatching: 100,
    pageSizeToMatching: 50,
    pageSizeToMoveInAnotherSpace: 100,
    pageSizeToDelete: 100,
    pageSizeToDownload: 100,
    yandexCounters: {
        "main": {
            id: 53863255,
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true
        }
    },
    tagList: ["Вариант 1", "Вариант 2", "Вариант 3"],
    integrations: [
        // Моё дело.
        {
            canBeSaved: true,
            serverType: "MOE_DELO",
            itemType: IntegrationItemType.TOKEN,
            imageUrl: "/assets/images/integrations/moe-delo-logo.png",
            helpUrl: "https://entera.omnidesk.ru/knowledge_base/item/202748"
        },
        // 1C.
        {
            itemType: IntegrationItemType.LINK,
            imageUrl: "/assets/images/integrations/1c-logo.jpg",
            helpUrl: "https://entera.pro/zagruzka-dannih-v-1c-zapusk-modulya-vneshney-obrabotki/",
            linkUrl: "http://entera.pro/1c"

        },
        // 1C Fresh.
        {
            itemType: IntegrationItemType.LINK,
            imageUrl: "/assets/images/integrations/1c-fresh-logo.png",
            linkUrl: "https://entera.omnidesk.ru/knowledge_base/item/238460?sid=55990",
            titleI18nKey: "spaces.settings.tabs.integrations.types.link.oneCFresh.title"
        },
        // 1C БухОбслуживание.
        {
            itemType: IntegrationItemType.LINK,
            imageUrl: "/assets/images/integrations/1c-accounting-logo.png",
            helpUrl: "https://entera.omnidesk.ru/knowledge_base/item/200764",
            linkUrl: "http://entera.pro/1cf"
        },
        // ESF KZ
        {
            serverType: "ESF_KZ",
            itemType: IntegrationItemType.BUTTON,
            imageUrl: "/assets/images/integrations/esf_kz_logo.png",
            helpUrl: "",
            titleI18nKey: "spaces.settings.tabs.integrations.types.buttons.esfkz.title",
            externalTypes: [],
            matchable: false,
        },
        // QuickBooks.
        {
            serverType: "QUICK_BOOKS",
            itemType: IntegrationItemType.OAUTH2,
            imageUrl: "/assets/images/integrations/quickbooks-logo.png",
            linkUrl: "/api/v1/integrations/quickbooks/oauth2/connect/:spaceId",
            helpUrl: "https://space-team.atlassian.net/wiki/spaces/EG/pages/59736065/How+to+connect+Entera+with+QuickBooks",
            titleI18nKey: "spaces.settings.tabs.integrations.types.oauth2.quickbooks.title",
            externalTypes: [
                {
                    id: "EXPENSE",
                    direction: "inbound",
                    serverType: "EXPENSE",
                    name: "Expense",
                    paymentAccount: true,
                    inventory: false,
                },
                {
                    id: "BILL",
                    direction: "inbound",
                    serverType: "BILL",
                    name: "Bill",
                    paymentAccount: false,
                    inventory: false,
                    default: true,
                },
            ],
            matchable: true,
        },
        // ZohoBooks.
        {
            serverType: "ZOHO_BOOKS",
            itemType: IntegrationItemType.OAUTH2,
            imageUrl: "/assets/images/integrations/zohobooks-logo.png",
            linkUrl: "/api/v1/integrations/zohobooks/oauth2/connect/:spaceId",
            helpUrl: "https://space-team.atlassian.net/wiki/spaces/EG/pages/59244545/How+to+connect+Entera+with+ZohoBooks",
            titleI18nKey: "spaces.settings.tabs.integrations.types.oauth2.zohobooks.title",
            externalTypes: [
                {
                    id: "EXPENSE",
                    direction: "inbound",
                    serverType: "EXPENSE",
                    name: "Expense",
                    paymentAccount: true,
                    inventory: false,
                    matchingFieldsSettings: {
                        matches: {
                            chargeReverse: {
                                visibility: true,
                            },
                        }
                    },
                },
                {
                    id: "BILL_CATEGORY",
                    direction: "inbound",
                    serverType: "BILL",
                    name: "Bill (category)",
                    paymentAccount: false,
                    inventory: false,
                    default: true,
                    matchingFieldsSettings: {
                        matches: {
                            chargeReverse: {
                                visibility: true,
                            },
                        }
                    },
                },
                {
                    id: "BILL_INVENTORY",
                    direction: "inbound",
                    serverType: "BILL",
                    name: "Bill (inventory)",
                    paymentAccount: false,
                    inventory: true,
                    matchingFieldsSettings: {
                        matches: {
                            chargeReverse: {
                                visibility: true,
                            },
                            items: {
                                item: {
                                    unit: {required: false},
                                    group: {required: false, visibility: false},
                                    salesAccount: {required: false, visibility: true},
                                    purchaseAccount: {required: true, visibility: true},
                                },
                                quantity: {required: true},
                            },
                        },
                    },
                },
                {
                    id: "SALES_INVOICE",
                    direction: "outbound",
                    serverType: "SALES_INVOICE",
                    name: "Sales invoice",
                    paymentAccount: false,
                    inventory: true,
                    matchingFieldsSettings: {
                        matches: {
                            chargeReverse: {
                                visibility: true,
                            },
                            items: {
                                item: {
                                    unit: {required: false},
                                    group: {required: false, visibility: true},
                                    salesAccount: {required: true, visibility: true},
                                    purchaseAccount: {required: false, visibility: true},
                                },
                                quantity: {required: true},
                            },
                        },
                    },
                },
            ],
            itemCreationDisabled: false,
            matchable: true,
            items: {
                supplyType: ["GOODS", "SERVICE"],
            },
        },
        // Tally
        {
            serverType: "TALLY",
            itemType: IntegrationItemType.BUTTON,
            imageUrl: "/assets/images/integrations/tally-logo.png",
            helpUrl: "https://space-team.atlassian.net/wiki/spaces/EG/pages/52658191/How+to+connect+and+update+Tally+with+Entera+App",
            titleI18nKey: "spaces.settings.tabs.integrations.types.tally.title",
            externalTypes: [
                {
                    id: "PURCHASE",
                    direction: "inbound",
                    serverType: "Purchase",
                    name: "Purchase (accounts)",
                    paymentAccount: false,
                    inventory: false,
                    default: true,
                    companyGST: true,
                    costCentres: true,
                    matchingFieldsSettings: {
                        matches: {
                            category: {
                                visibility: true,
                                required: true,
                                metaFilter: { type: ["both", "category"] },
                            },
                        },
                        modifiers: {
                            "Round off": { category: { visibility: true, required: false, metaFilter: { type: [] }}},
                            "TCS": { category: { visibility: true, required: false, metaFilter: { type: [] }}, percentage: { visibility: true }},
                            "TDS": { category: { visibility: true, required: false, metaFilter: { type: [] }}, percentage: { visibility: true }},
                            "Discount": { category: { visibility: true, required: false, metaFilter: { type: [] }}},
                        },
                    }
                },
                {
                    id: "PURCHASE_INVENTORY",
                    direction: "inbound",
                    serverType: "Purchase",
                    name: "Purchase (inventory)",
                    paymentAccount: false,
                    inventory: true,
                    changableItemType: true,
                    locations: true,
                    costCentres: true,
                    companyGST: true,
                    matchingFieldsSettings: {
                        matches: {
                            category: {
                                visibility: true,
                                required: true,
                                metaFilter: { type: ["category"] },
                            },
                            items: {
                                item: {
                                    unit: {required: false, visibility: true},
                                    group: {required: false, visibility: true},
                                    salesAccount: {required: false, visibility: false},
                                    purchaseAccount: {required: false, visibility: false},
                                },
                                category: {
                                    metaFilter: { type: ["both", "category"] },
                                },
                                quantity: {required: true},
                            },
                            modifiers: {
                                "Round off": { category: { visibility: true, required: false, metaFilter: { type: [] }}},
                                "TCS": { category: { visibility: true, required: false, metaFilter: { type: [] }}, percentage: { visibility: true }},
                                "TDS": { category: { visibility: true, required: false, metaFilter: { type: [] }}, percentage: { visibility: true }},
                                "Discount": { category: { visibility: true, required: false, metaFilter: { type: [] }}},
                            },
                        },
                    },
                },
                {
                    id: "JOURNAL_INVENTORY",
                    direction: "inbound",
                    serverType: "Journal",
                    name: "Journal",
                    paymentAccount: false,
                    inventory: true,
                    changableItemType: true,
                    locations: true,
                    costCentres: true,
                    companyGST: true,
                    matchingFieldsSettings: {
                        matches: {
                            category: {
                                visibility: true,
                                required: true,
                                metaFilter: { type: ["category"] },
                            },
                            items: {
                                item: {
                                    unit: {required: false, visibility: true},
                                    group: {required: false, visibility: true},
                                    salesAccount: {required: false, visibility: false},
                                    purchaseAccount: {required: false, visibility: false},
                                },
                                category: {
                                    metaFilter: { type: ["both", "category"] },
                                },
                                quantity: {required: true},
                            },
                            modifiers: {
                                "Round off": { category: { visibility: true, required: false, metaFilter: { type: [] }}},
                                "TCS": { category: { visibility: true, required: false, metaFilter: { type: [] }}, percentage: { visibility: true }},
                                "TDS": { category: { visibility: true, required: false, metaFilter: { type: [] }}, percentage: { visibility: true }},
                                "Discount": { category: { visibility: true, required: false, metaFilter: { type: [] }}},
                            },
                        },
                    },
                },
                {
                    id: "SALES_INVOICE",
                    direction: "outbound",
                    serverType: "Sales",
                    name: "Sales invoice (category)",
                    paymentAccount: false,
                    inventory: false,
                    default: true,
                    costCentres: true,
                    companyGST: true,
                    matchingFieldsSettings: {
                        matches: {
                            category: {
                                visibility: true,
                                required: true,
                                metaFilter: { type: ["both", "sale"] },
                            },
                            modifiers: {
                                "Round off": { category: { visibility: true, required: false, metaFilter: { type: [] }}},
                                "TCS": { category: { visibility: true, required: false, metaFilter: { type: [] }}, percentage: { visibility: true }},
                                "TDS": { category: { visibility: true, required: false, metaFilter: { type: [] }}, percentage: { visibility: true }},
                                "Discount": { category: { visibility: true, required: false, metaFilter: { type: [] }}},
                            },
                        }
                    }
                },
                {
                    id: "SALES_INVOICE_INVENTORY",
                    direction: "outbound",
                    serverType: "Sales",
                    name: "Sales invoice (inventory)",
                    paymentAccount: false,
                    inventory: true,
                    changableItemType: true,
                    locations: true,
                    costCentres: true,
                    companyGST: true,
                    matchingFieldsSettings: {
                        matches: {
                            category: {
                                visibility: true,
                                required: true,
                                metaFilter: { type: ["sale"] },
                            },
                            items: {
                                item: {
                                    unit: {required: true, visibility: true},
                                    group: {required: true, visibility: true},
                                    salesAccount: {required: false, visibility: false},
                                    purchaseAccount: {required: false, visibility: false},
                                },
                                category: {
                                    metaFilter: { type: ["both", "sale"] },
                                },
                                quantity: {required: true},
                            },
                            modifiers: {
                                "Round off": { category: { visibility: true, required: false, metaFilter: { type: [] }}},
                                "TCS": { category: { visibility: true, required: false, metaFilter: { type: [] }}, percentage: { visibility: true }},
                                "TDS": { category: { visibility: true, required: false, metaFilter: { type: [] }}, percentage: { visibility: true }},
                                "Discount": { category: { visibility: true, required: false, metaFilter: { type: [] }}},
                            },
                        },
                    },
                },
                {
                    id: "CREDIT_NOTE",
                    direction: "outbound",
                    serverType: "CreditNote",
                    name: "Credit note (category)",
                    paymentAccount: false,
                    inventory: false,
                    default: true,
                    costCentres: true,
                    companyGST: true,
                    matchingFieldsSettings: {
                        matches: {
                            category: {
                                visibility: true,
                                required: true,
                                metaFilter: { type: ["both", "sale"] },
                            },
                            modifiers: {
                                "Round off": { category: { visibility: true, required: false, metaFilter: { type: [] }}},
                                "TCS": { category: { visibility: true, required: false, metaFilter: { type: [] }}},
                                "TDS": { category: { visibility: true, required: false, metaFilter: { type: [] }}},
                                "Discount": { category: { visibility: true, required: false, metaFilter: { type: [] }}},
                            },
                        }
                    }
                },
                {
                    id: "CREDIT_NOTE_INVENTORY",
                    direction: "outbound",
                    serverType: "CreditNote",
                    name: "Credit note (inventory)",
                    paymentAccount: false,
                    inventory: true,
                    changableItemType: true,
                    locations: true,
                    costCentres: true,
                    companyGST: true,
                    matchingFieldsSettings: {
                        matches: {
                            category: {
                                visibility: true,
                                required: true,
                                metaFilter: { type: ["sale"] },
                            },
                            items: {
                                item: {
                                    unit: {required: true, visibility: true},
                                    group: {required: true, visibility: true},
                                    salesAccount: {required: false, visibility: false},
                                    purchaseAccount: {required: false, visibility: false},
                                },
                                category: {
                                    metaFilter: { type: ["both", "sale"] },
                                },
                                quantity: {required: true},
                            },
                            modifiers: {
                                "Round off": { category: { visibility: true, required: false, metaFilter: { type: [] }}},
                                "TCS": { category: { visibility: true, required: false, metaFilter: { type: [] }}},
                                "TDS": { category: { visibility: true, required: false, metaFilter: { type: [] }}},
                                "Discount": { category: { visibility: true, required: false, metaFilter: { type: [] }}},
                            },
                        },
                    },
                },
            ],
            matchable: true,
            supplierCreationDisabled: false,
            typeMetaTypeMap: {
                "Duties & Taxes": "tax",
                "Fixed Assets": "both",
                "Current Assets": "both",
                "Direct Expenses": "both",
                "Indirect Expenses": "both",
                "Direct Incomes": "both",
                "Purchase Accounts": "category",
                "Sales Accounts": "sale",
                "Bank Accounts": "bank",
                "BANK OD A/c": "bank",
                "Bank OCC A/c": "bank",
                "Cash-in-Hand": "cash",
            },
            items: {
                supplyType: ["GOODS", "SERVICES"],
            }
        },
    ],
    ga: {
        enabled: true,
        trackers: [
            { id: 'UA-124248741-1' },
        ],
    },
    bitrix24: {
        enable: true,
        scriptUrl: "https://cdn.bitrix24.ru/b7928661/crm/site_button/loader_3_uqumkk.js"
    },
    omnidesk: {

        widgetId: '3175-ra723nb3'
    },
    facebookPixel: {
        enabled: false,
    },
    documentsArchive: {
        maxIdCount: 1000,
        maxCreatedDateDayDifference: 93
    },
    matching: {
        integrationUpdateInterval: 5000,
    },
    bankStatements: {
        uploadingEnabled: true,
    },
    excelImport: {
        uploadingEnabled: true,
    },
    sbisManualUpload: {
        enabled: true,
    },
    esfKzManualUpload: {
        enabled: true,
    }
};

/**
 * Выполняет загрузку конфигурации.
 */
function loadConfig(): void {

    const CONFIG_URL: string = "assets/config/config.json";

    const configRequest: XMLHttpRequest = new XMLHttpRequest();

    function applyConfig() {

        if (configRequest.status < 400) {

            const newEnv = {...environment, ...JSON.parse(configRequest.response)};
            ObjectUtils.freezeInDeep(newEnv, false);
            Object.assign(environment, newEnv);
        }
    }

    configRequest.open("GET", CONFIG_URL, false);
    configRequest.setRequestHeader("Cache-Control", "no-cache");
    configRequest.onload = applyConfig;
    configRequest.send();
}

try {

    loadConfig();
}
catch (e) {

    console.log("Can't load config", e);
}
