import { Input } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Output } from "@angular/core";
import { Component } from "@angular/core";
import { ApiResponse } from "src/app/common/models/api-response";
import { FileWithMetaData } from "src/app/common/models/file-with-meta-data";

/**
 * Компонента строки с данными файла, загруженного для отправки на распознавание, в мобильном интерфейсе приложения.
 */
@Component({
    selector: "mobile-document-upload-file-row",
    styleUrls: ["./mobile-document-upload-file-row.component.scss"],
    templateUrl: "./mobile-document-upload-file-row.component.html"
})
export class MobileDocumentUploadFileRowComponent {
    //region Inputs

    /**
     * Флаг активности процесса загрузки файлов для отправки на распознавание.
     */
    @Input()
    loading: boolean;

    /**
     * Ошибка, возникшая при отправке файлов на распознавание.
     */
    @Input()
    error: ApiResponse;

    /**
     * Данные файла для отображения.
     */
    @Input()
    fileWithMetaData: FileWithMetaData;

    //endregion
    //region Outputs

    /**
     * Удаление документа из массива файлов для отправки на распознавание.
     */
    @Output()
    remove = new EventEmitter<File>();

    //endregion
    //region Events

    /**
     * Обработчик события удаления файла из массива файлов для отправки на распознавание.
     *
     * @param file Файл для удаления из массива.
     */
    removeFile(file: File) {

        this.remove.emit(file);
    }

    //endregion
}
