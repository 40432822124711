import { Injectable } from "@angular/core";
import { CanLoad } from "@angular/router";
import { Observable } from "rxjs";
import { CanLoadRoute } from "src/app/common/models";
import { PermissionGuard } from "./permission.guard";
import { AdminPermissionItem } from "src/app/common/models/admin-permission-item";

/**
 * Route Guard.
 * Проверка наличия права ADMIN для входа в раздел администрирования.
 */
@Injectable({
    providedIn: "root"
})
export class AdminGuard implements CanLoad {
    //region Ctor

    constructor(
        private _permissionGuard: PermissionGuard
    ) { }

    //endregion
    //region Public

    canLoad(route: CanLoadRoute): Observable<boolean> {

        return this._permissionGuard.hasAnyPermission(
            [AdminPermissionItem.ADMIN_IMPERSONATE, AdminPermissionItem.CLIENT_CONTROL],
            route.path
        );
    }

    //endregion
}
