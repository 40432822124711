import { HttpErrorResponse } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as crypto from "crypto-js";
import { of } from "rxjs";
import { throwError } from "rxjs";
import { from } from "rxjs";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { catchError, map } from "rxjs/operators";
import { mergeMap } from "rxjs/operators";
import { toArray } from "rxjs/operators";
import { FileWithLink } from "src/app/common/models/file-with-link";
import { FileWithMetaData } from "src/app/common/models/file-with-meta-data";
import { ApiResponse } from "src/app/common/models/index";

/**
 * Сервис для работы с файлами.
 */
@Injectable({
    providedIn: "root",
})
export class FileService {
    //region Constants

    /**
     * Ограничение количества параллельно выполняемых генераций хешей.
     */
    public static MAX_CONCURRENT_HASH_GENERATING = 4;

    /**
     * Размер части контента файла при чтении файла по частям.
     */
    public static CHUNK_SIZE = 1024 * 1024;

    /**
     * Регекс для выделения идентификатора печатной формы чека самозанятых.
     */
    public static SELF_EMPLOYED_NUMBER_PATTERN: RegExp = /([^/]+)\/print$/;

    /**
     * Регекс для выделения идентификатора печатной формы чека самозанятых.
     */
    public static SELF_EMPLOYED_TAX_ID_PATTERN: RegExp = /\/receipt\/([^\/]+)\//;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса.
     *
     * @param _translateService Сервис для работы с i18n-сообщениями.
     */
    constructor(private _translateService: TranslateService, private _httpClient: HttpClient) {}

    //endregion
    //region Public

    /**
     * Скачивает файл по ссылке.
     *
     * @param link Ссылка.
     * @param fileName Наименование файла, которое нужно присвоить
     *
     * @return Файл.
     */
    public downloadFileByLink(link: string, fileName: string): Observable<File> {

        const errorMessage = this._translateService.instant("common.download-file-by-link.error", { link });

        return this._httpClient.get(link, { responseType: "blob" }).pipe(
            map(blob => new File([blob], fileName)),
            catchError((_: HttpErrorResponse) => throwError({ result: false, errorMessage })),
        );
    }

    /**
     * Скачивает файлы по ссылке.
     *
     * @param links Ссылки на файлы.
     *
     * @return Файлы.
     */
    public downloadSelfEmployedFilesByLinks(links: string[]): Observable<FileWithLink[] | ApiResponse> {

        return from(links).pipe(
            mergeMap(
                (link: string) => {

                    const numberPattern = link.match(FileService.SELF_EMPLOYED_NUMBER_PATTERN);
                    const taxIdPattern = link.match(FileService.SELF_EMPLOYED_TAX_ID_PATTERN);
                    const fileName = `Чек самозанятого № ${numberPattern[1] || "-"} ИНН ${taxIdPattern[1] || "-"}`;

                    return this.downloadFileByLink(link, fileName).pipe(map(file => ({file, link})));
                },
            ),
            toArray(),
            catchError((response: ApiResponse) => of(response)),
        );
    }

    /**
     * Выполняет генерацию хеша SHA-256 для файла.
     *
     * Читает файл по "кускам".
     *
     * @param file Файл.
     *
     * @return Хеш SHA-256.
     */
    public fileToSHA256Hash(file: File): Observable<FileWithMetaData> {

        let offset = 0;
        const sha256 = crypto.algo.SHA256.create();

        return new Observable((observer) => {

            function readNextChunk() {

                const reader = new FileReader();

                reader.onload = function(event) {

                    const result = (event.target as any).result as ArrayBuffer;
                    const wordArray = crypto.lib.WordArray.create(result);
                    sha256.update(wordArray);

                    if (offset < file.size) {

                        readNextChunk();
                    } else {

                        const hash = sha256.finalize();
                        const sha256string = hash.toString(crypto.enc.Base64);
                        observer.next({ file: file, hash: sha256string });
                        observer.complete();
                    }
                };

                reader.onerror = (error) => {

                    observer.error({ result: false, errorMessage: "common.generate-hash.error", error });
                };

                const slice = file.slice(offset, offset + FileService.CHUNK_SIZE);
                reader.readAsArrayBuffer(slice);
                offset += FileService.CHUNK_SIZE;
            }

            readNextChunk();
        });
    }

    /**
     * Выполняет генерацию хешей SHA-256 для массива файлов.
     *
     * @param files Файлы.
     *
     * @return Список хешей SHA-256.
     */
    public filesToSHA256Hash(files: File[]): Observable<FileWithMetaData[]> {

        return from(files).pipe(
            mergeMap(file => this.fileToSHA256Hash(file), FileService.MAX_CONCURRENT_HASH_GENERATING),
            toArray(),
        );
    }

    //endregion
}
