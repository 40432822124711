import moment from "moment";
import { FilterAndSortAndSearchState } from "src/app/spaces/modules/documents-registry/models/filter-and-sort-and-search-state.model";
import { environment } from "../../../../../../../environments/environment";
import { DocumentsArchiveActionType } from "../../actions";
import { DocumentsArchiveDlgAction } from "../../actions";
import { DocumentsArchiveDlgState } from "./documents-archive-dlg.state";

const initialState: DocumentsArchiveDlgState = {
    spaceId: null,
    badRequest: false,
    count: null,
    loading: false,
    success: false,
    error: null
};

/**
 * Обработка состояния дилога создания архива в ответ на события.
 *
 * @param state Текущее состояние.
 * @param action Событие.
 */
export function documentsArchiveDlgReducer(state: DocumentsArchiveDlgState = initialState, action: DocumentsArchiveDlgAction): DocumentsArchiveDlgState {

    let result: DocumentsArchiveDlgState = state;

    switch (action.type) {

        // ...
        case DocumentsArchiveActionType.INIT: {

            result = initialState;

            if (action.payload.ids && action.payload.ids.length) {

                result = {
                    ...result,
                    spaceId: action.payload.spaceId,
                    filter: null,
                    ids: action.payload.ids,
                    badRequest: action.payload.ids.length > environment.documentsArchive.maxIdCount,
                    count: action.payload.ids.length,
                };
            }
            else {

                result = {
                    ...result,
                    spaceId: action.payload.spaceId,
                    filter: action.payload.info,
                    ids: null,
                };
            }

            break;
        }

        // ...
        case DocumentsArchiveActionType.LOAD_COUNT: {

            result = {
                ...result,
                success: false,
                loading: true,
                error: null
            };

            break;
        }

        // ...
        case DocumentsArchiveActionType.MAKE_ARCHIVE: {

            result = {
                ...result,
                success: false,
                loading: true,
                error: null
            };

            break;
        }

        // ...
        case DocumentsArchiveActionType.LOAD_COUNT_SUCCESS: {

            result = {
                ...result,
                success: false,
                loading: false,
                error: null,
                count: action.payload,
                badRequest: !checkFilter(state.filter, action.payload),
            };

            if (!action.payload) {

                result = {
                    ...result,
                    badRequest: true
                };
            }

            break;
        }

        // ...
        case DocumentsArchiveActionType.MAKE_ARCHIVE_SUCCESS: {

            result = {
                ...result,
                success: true,
                loading: false,
                error: null
            };

            break;
        }

        // ...
        case DocumentsArchiveActionType.ERROR: {

            result = {
                ...result,
                success: false,
                loading: false,
                error: action.payload
            };

            break;
        }
    }

    return result;
}

/**
 * Возможно создать архив на основе фильтра?
 *
 * Один из диапазонов дат - дат документов или же дат создания документов - должен быть корректным.
 *
 * @param filter Фильтр запроса.
 * @param count Количество страниц после применения фильтра.
 *
 * @return Да/нет.
 */
function checkFilter(filter: FilterAndSortAndSearchState, count: number): boolean {

    const createdFrom = typeof filter.createdDateFrom === "string"
        ? moment(filter.createdDateFrom)
        : filter.createdDateFrom;
    const createdTo = typeof filter.createdDateTo === "string"
        ? moment(filter.createdDateTo)
        : filter.createdDateTo || moment();
    const dateFrom = typeof filter.documentDateFrom === "string"
        ? moment(filter.documentDateFrom)
        : filter.documentDateFrom;
    const dateTo = typeof filter.documentDateTo === "string"
        ? moment(filter.documentDateTo)
        : filter.documentDateTo || moment();

    let correctCreatedDateRange = false;
    let correctDocumentDateRange = false;

    if (createdFrom) {

        const days = createdTo.diff(createdFrom, "days");
        correctCreatedDateRange = days >= 0 && days <= environment.documentsArchive.maxCreatedDateDayDifference;
    }
    if (dateFrom) {

        const days = dateTo.diff(dateFrom, "days");
        correctDocumentDateRange = days >= 0 && days <= environment.documentsArchive.maxCreatedDateDayDifference;
    }

    return correctCreatedDateRange || correctDocumentDateRange;
}
