import { DOCUMENT } from "@angular/common";
import { Inject } from "@angular/core";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import * as LogRocket from "logrocket";
import { of } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { CurrentUserInfo } from "src/app/common/models";
import { ApiResponse } from "src/app/common/models/api-response";
import { BroadcastMessage } from "src/app/common/models/broadcast-message.model";
import { UserService } from "src/app/common/services/user.service";
import { broadcastMessageDlgActions } from "src/app/root/store/actions/broadcast-message-dlg.action";
import { environment } from "src/environments/environment";
import { FirstUploadDlgService } from "../../services/first-upload-dlg.service";
import { CurrentUserConfirmPhoneSuccessAction } from "../actions";
import { CurrentUserActionType } from "../actions";
import { CurrentUserLoadFailAction } from "../actions";
import { CurrentUserLoadSuccessAction } from "../actions";
import { CurrentUserLogoutFailAction } from "../actions";
import { CurrentUserLogoutSuccessAction } from "../actions";
import { RouterGoAction } from "../actions";
import { CurrentUserRecognitionTaskUploadedAction } from "../actions";
import { UploadToRecognizeActionType } from "../actions";
import { RecognitionTaskCreatedDlgAction } from "../actions";
import { UploadTaskToRecognizeSuccessAction } from "../actions";
import { RecognitionTaskCreatedDlgOpenAction } from "../actions/recognition-task-created-dlg.action";
import { UploadToRecognizeState } from "../reducers";
import { uploadToRecognizeSelector } from "../reducers";
import { RootState } from "../reducers";
import { currentUserInfoSelector } from "../selectors";
import { CurrentUserUpdateAndRedirectAfterCreateSpaceAction } from "../actions";
import { CurrentUserUpdateAndRedirectAfterCreateSpaceSuccessfullyAction } from "../actions";
import { CurrentUserUpdateAndRedirectAfterCreateSpaceFailAction } from "../actions";

/**
 * Side-эффекты на события, связанные с текущим пользователем.
 */
@Injectable()
export class CurrentUserEffects {
    //region Private fields

    /**
     * Данные текущего пользователя.
     */
    private _currentUser: CurrentUserInfo;

    /**
     * Сервис для вызова диалога с подсказкой после первой загрузки файла на распознавание.
     */
    private readonly _firstUploadDlgService: FirstUploadDlgService;

    /**
     * Показывать диалоговое окно после загрузки файлов на распознавание?
     */
    private _uploadDialogFlag: boolean;

    //endregion
    //region Ctor

    constructor(
        private _userService: UserService,
        private _actions$: Actions,
        @Inject(DOCUMENT) private _document: any,
        private _store: Store<RootState>,
        private _router: Router,
        firstUploadDlgService: FirstUploadDlgService,
    ) {
        // Подписываемся на данные текущего пользователя.
        this._store
            .pipe(
                select(currentUserInfoSelector)
            )
            .subscribe((currentUser) => this._currentUser = currentUser);

        this._store
            .pipe(
                select(uploadToRecognizeSelector),
                takeWhile((state: UploadToRecognizeState) => !state.loaded)
            )
            .subscribe((uploadState: UploadToRecognizeState) =>
                this._uploadDialogFlag = uploadState.openPostUploadDialog
            );
        this._firstUploadDlgService = firstUploadDlgService;
    }

    //endregion
    //region Effects

    /**
     * Обработка события требования загрузки данных текущего пользователя.
     *
     * Если аутентифицированного пользователя нет, то будет редирект на форму логина.
     */
    @Effect()
    loadCurrentUserInfo$ = this._actions$
        .pipe(
            ofType(CurrentUserActionType.LOAD),
            switchMap(() =>
                this._userService.getCurrentUser()
                    .pipe(
                        map(currentUserInfo => new CurrentUserLoadSuccessAction(currentUserInfo)),
                        catchError((response: ApiResponse) => of(new CurrentUserLoadFailAction(response)))
                    )
            )
        );

    /**
     * Обработка события удачной загрузки данных о пользователе.
     *
     * Если мы находимся на странице welcome, то необходимо совершить переход на главную страницу.
     */
    @Effect({dispatch: false})
    successCurrentUserInfo$ = this._actions$
        .pipe(
            ofType(CurrentUserActionType.LOAD_SUCCESS),
            // TODO Хардкод URL'а.
            filter(() => this._router.url === "/welcome"),
            tap(() => this._store.dispatch(new RouterGoAction({path: [""]}))),
        );

    /**
     * Обработка событий удачной загрузки данных о пользователе, когда пользователю есть сообщения.
     *
     * Открывает диалог сообщений для пользователя.
     */
    @Effect({dispatch: false})
    openBroadcastMessageDialog$ = this._actions$
        .pipe(
            ofType(CurrentUserActionType.LOAD_SUCCESS),
            map((action: CurrentUserLoadSuccessAction): CurrentUserInfo => action.payload),
            filter((payload: CurrentUserInfo): boolean => !!payload),
            map((payload: CurrentUserInfo): BroadcastMessage[] => payload.broadcastMessages),
            filter((messages: BroadcastMessage[]): boolean => !!messages && !!messages.length),
            tap((value: BroadcastMessage[]): void => this._store.dispatch(broadcastMessageDlgActions.open({value}))),
        );

    /**
     * Обработка событий удачной загрузки данных о пользователе.
     *
     * Открывает новую сессию в логрокете и идентифицирует пользователя.
     */
    @Effect({dispatch: false})
    identifyForLogrocket$ = this._actions$
        .pipe(
            ofType(CurrentUserActionType.LOAD_SUCCESS),
            map((action: CurrentUserLoadSuccessAction): CurrentUserInfo => action.payload),
            filter((payload: CurrentUserInfo): boolean => !!payload),
            tap((value: CurrentUserInfo): void => {

                LogRocket.identify(value.id, {
                    name: this._userService.getUserName(value),
                    email: value.email,
                });
            }),
        );

    /**
     * Обработка события требования выхода текущего пользователя из системы.
     */
    @Effect()
    currentUserLogout$ = this._actions$
        .pipe(
            ofType(CurrentUserActionType.LOGOUT),
            switchMap(() =>
                this._userService.logout()
                    .pipe(
                        map(() => new CurrentUserLogoutSuccessAction()),
                        catchError((response: ApiResponse) => of(new CurrentUserLogoutFailAction(response)))
                    )
            )
        );

    /**
     * Обработка успешного выхода текущего пользователя из системы.
     *
     * Завершает текущую сессию логрокета и начинает новую.
     */
    @Effect({dispatch: false})
    currentUserLogoutSuccess$ = this._actions$
        .pipe(
            ofType(CurrentUserActionType.LOGOUT_SUCCESS),
            tap(() => {

                // Если был вход под пользователем, то переходим на корневой путь.
                if (this._currentUser.impersonated) {

                    this._document.location.href = window.location.origin;
                }
                // Если нет, то переходим на лендинг.
                else {

                    this._document.location.href = environment.enteraLandingUrl;
                }
            })
        );

    /**
     * Обработка успешной загрузки задачи на распознавание.
     *
     * Если для текущего пользователя загруженная задача на распознавание - первая, учитывая все его пространства
     * документов, то отобразить диалог с подсказкой и пометить, что у этого пользователя теперь есть задачи на
     * распознавание.
     */
    @Effect()
    createCurrentUserRecognitionTaskUploadedAction$ = this._actions$
        .pipe(
            ofType(UploadToRecognizeActionType.UPLOAD_SUCCESS),
            filter((): boolean => !this._currentUser.hasRecognitionTasks),
            tap((): void => this._firstUploadDlgService.open()),
            map((): Action => new CurrentUserRecognitionTaskUploadedAction()),
        );

    /**
     * Обработка успешной загрузки задачи на распознавание.
     *
     * Создаёт событие, требующее открытия диалога после создания задачи на распознавание.
     */
    @Effect()
    createRecognitionTaskCreatedDlgOpenAction$ = this._actions$
        .pipe(
            ofType(UploadToRecognizeActionType.UPLOAD_SUCCESS),
            filter((): boolean => this._currentUser.hasRecognitionTasks),
            filter((): boolean => this._currentUser.settings.showRecognitionTaskCreatedDialog),
            filter((): boolean => this._uploadDialogFlag),
            map((action: UploadTaskToRecognizeSuccessAction): RecognitionTaskCreatedDlgAction =>
                new RecognitionTaskCreatedDlgOpenAction(action.payload[0])
            ),
        );

    @Effect({dispatch: false})
    phoneConfirmCallback$ = this._actions$
        .pipe(
            ofType(CurrentUserActionType.PHONE_CONFIRM_SUCCESS),
            map((action: CurrentUserConfirmPhoneSuccessAction): Function => action.payload.callback),
            filter(Boolean),
            tap((callback: Function) => callback()),
        );

    /**
     * Обработка события создания нового пространства документов и загрузки текущего пользователя.
     */
    @Effect()
   updateCurrentUser$ = this._actions$.pipe(
        ofType(CurrentUserActionType.UPDATE_AND_REDIRECT),
       switchMap((action: CurrentUserUpdateAndRedirectAfterCreateSpaceAction) =>
           this._userService.getCurrentUser()
               .pipe(
                   map(currentUserInfo =>
                       new CurrentUserUpdateAndRedirectAfterCreateSpaceSuccessfullyAction(action.payload, currentUserInfo)
                   ),
                   catchError((response: ApiResponse) =>
                       of(new CurrentUserUpdateAndRedirectAfterCreateSpaceFailAction(response))
                   )
               )
       ),
    );

    /**
     * Обработка события удачного создания нового пространства документов.
     */
    @Effect()
    goToCreatedSpace$ = this._actions$.pipe(
        ofType(CurrentUserActionType.UPDATE_AND_REDIRECT_SUCCESS),
        map((action: CurrentUserUpdateAndRedirectAfterCreateSpaceSuccessfullyAction) =>
            new RouterGoAction({ path: ["/", "spaces", action.space.id, "documents"] }
            )),
    );

    //endregion
}
