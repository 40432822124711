import { HttpErrorResponse } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { throwError } from "rxjs";
import { of } from "rxjs";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { ErrorUtils } from "src/app/common/utils/error.utils";
import { UrlUtils } from "src/app/common/utils/url.utils";
import { Company } from "../models";
import { CompanyPagedRequest } from "../models";

/**
 * Сервис для работы с компаниями.
 */
@Injectable({
    providedIn: 'root'
})
export class CompaniesService {
    //region Ctor

    constructor(
        private _httpClient: HttpClient
    ) { }

    //endregion
    //region Public

    /**
     * Постраничный запрос компаний.
     *
     * @param request Данные запроса.
     */
    searchCompanies(request: CompanyPagedRequest): Observable<Company[]> {

        let params = this._getParams(request);

        return this._httpClient
            .get(`/api/v1/companies`, { params: params })
            .pipe(
                map((result: any) => result.companies as Company[]),
                catchError((err: HttpErrorResponse) => throwError(ErrorUtils.from(err))),
            );
    }

    /**
     * Запрос компаний по идентификаторам.
     *
     * @param ids Идентификаторы компаний.
     */
    searchCompaniesByIds(ids: string[]): Observable<Company[]> {

        if (ids.length) {

            return this._httpClient
                .get(`/api/v1/companies/batch${UrlUtils.getQueryString({ids: ids})}`)
                .pipe(
                    map((result: any) => result.companies as Company[])
                );
        }
        else {

            return of([]);
        }
    }

    /**
     * Постраничный поиск покупателя.
     *
     * @param request Даннные запроса.
     */
    searchCustomers(request: CompanyPagedRequest): Observable<Company[]> {

        let params = this._getParams(request);

        return this._httpClient
            .get(`/api/v2/customers`, { params: params })
            .pipe(
                map((result: any) => result.companies as Company[]),
                catchError((err: HttpErrorResponse) => throwError(ErrorUtils.from(err))),
            );
    }

    /**
     * Постраничный поиск поставщика.
     *
     * @param request Даннные запроса.
     */
    searchSuppliers(request: CompanyPagedRequest): Observable<Company[]> {

        let params = this._getParams(request);

        return this._httpClient
            .get(`/api/v2/suppliers`, { params: params })
            .pipe(
                map((result: any) => result.companies as Company[]),
                catchError((err: HttpErrorResponse) => throwError(ErrorUtils.from(err))),
            );
    }

    //endregion
    //region Private

    /**
     * Создание параметров http запроса из объекта запроса постраничного списка компаний.
     *
     * @param request Объект запроса.
     */
    private _getParams(request: CompanyPagedRequest):HttpParams {

        if (!request.page) {

            request.page = 1;
        }

        if (!request.pageSize) {

            request.pageSize = 10;
        }

        let params: HttpParams = new HttpParams()
            .set("page", request.page.toFixed())
            .set("pageSize", request.pageSize.toFixed());

        params = params.set("foreign", `${!!request.foreign}`);

        if (request.country) {

            params = params.set("country", request.country);
        }

        params = params.set("requisitesRequired", `${!!request.requisitesRequired}`);

        if (request.search) {

            params = params.set("search", request.search);
        }

        if (request.spaceId) {

            params = params.set("spaceId", request.spaceId);
        }

        if (request.id) {

            params = request.id.reduce((params, id) => params.append("id", id), params)
        }

        return params;
    }

    //endregion
}
