import { Directive } from '@angular/core';
import { AbstractControl } from "@angular/forms";
import { NG_VALIDATORS } from "@angular/forms";
import { ValidationErrors } from "@angular/forms";
import { Validator } from "@angular/forms";
import { ValidatorFn } from '@angular/forms';

/**
 * Валидатор для проверки надёжности пароля.
 */
@Directive({
    selector: '[secure-password]',
    providers: [ { provide: NG_VALIDATORS, useExisting: SecurePasswordValidator, multi: true } ]
})
export class SecurePasswordValidator implements Validator {
    //region Private constants

    /**
     * Минимальная длина пароля.
     *
     * @private
     */
    private static readonly _MIN_LENGTH: number = 8;

    /**
     * Кол-во разных символов в пароле.
     *
     * @private
     */
    private static readonly _UNIQUE_SYMBOLS_COUNT: number = 6;

    //endregion
    //region Public static

    /**
     * Возвращает функцию валидации надёжности пароля.
     *
     * @return Функция валидации надёжности пароля.
     */
    static get(): ValidatorFn {

        return (control: AbstractControl) => {

            let result = null;

            if (control && control.value && !SecurePasswordValidator._isPasswordSecure(control.value)) {

                result = { 'securePassword': true }
            }

            return result;
        };
    }

    //endregion
    //region Public

    /**
     * Выполняет валидацию надёжности пароля для заданного поля формы.
     *
     * @param control Поле формы.
     *
     * @return Результат валидации.
     */
    validate(control: AbstractControl): ValidationErrors | null {

        return SecurePasswordValidator.get()(control);
    }

    //endregion
    //region Private

    /**
     * Заданный пароль является надёжным?
     *
     * @param password Пароль.
     *
     * @return Да/Нет.
     *
     * @private
     */
    private static _isPasswordSecure(password: string): boolean {

        let result = false;

        if (typeof password === 'string' && password.length >= SecurePasswordValidator._MIN_LENGTH) {

            const uniqueSymbolsHolder: any = password
                .split('')
                .reduce(
                    (uniqueSymbolsHolder: any, symbol: string) => {

                        uniqueSymbolsHolder[symbol] = true;
                        return uniqueSymbolsHolder;
                    },
                    {}
                );
            const uniqueSymbolsCount: number = Object.keys(uniqueSymbolsHolder).length;

            if (uniqueSymbolsCount >= SecurePasswordValidator._UNIQUE_SYMBOLS_COUNT) {

                result = true;
            }
        }

        return result;
    }

    //endregion
}
