import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { environment } from "src/environments/environment";
import { MAT_DATE_FORMATS } from "@angular/material/core";

export const CUSTOM_FORMATS = {
    parse: {
        dateInput: environment.global && 'YYYY-MM-DD' || 'L',
    },
    display: {
        dateInput: environment.global && 'YYYY-MM-DD' || 'L',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: environment.global && 'YYYY-MM-DD' || 'L',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

// Используем useUtc: true, чтобы при выборе даты текстовое представление было ровно полночь выбранной даты.
// Если не использовать эту настройку, то по МСК дата будет предыдущая 21:00 (-3 часа относительно UTC). 
export const matMomentDateAdapterOptionsProvider = { 
    provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    useValue: { useUtc: true }
};

export const matDateFormats = {
    provide: MAT_DATE_FORMATS,
    useValue: CUSTOM_FORMATS
};
