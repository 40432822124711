import { EnteraSelectComponent } from "src/app/common/components/entera-select/entera-select.component";
import { EnteraMatSelectWrapperComponent } from "src/app/common/components/entera-simple-mat-select-wrapper/entera-mat-select-wrapper.component";
import { MatchSettingsDlgComponent } from "src/app/common/components/match-settings-dlg/match-settings-dlg.component";
import { MobileDocumentUploadFileButtonComponent } from "src/app/common/components/mobile-document-upload-file-button/mobile-document-upload-file-button.component";
import { MobileDocumentUploadFileListComponent } from "src/app/common/components/mobile-document-upload-file-list/mobile-document-upload-file-list.component";
import { MobileDocumentUploadFileRowComponent } from "src/app/common/components/mobile-document-upload-file-row/mobile-document-upload-file-row.component";
import { NotificationWithValidationComponent } from "src/app/common/components/notification-with-validation/notification-with-validation.component";
import { PurchaseDlgComponent } from "src/app/common/components/purchase-dlg/purchase-dlg.component";
import { commonTemplates } from "src/app/common/components/templates/commonTemplates";
import { TwoInputsDlgComponent } from "src/app/common/components/two-inputs-dlg/two-inputs-dlg.component";
import { NotificationWithInfoComponent } from "src/app/common/notification-with-info/notification-with-info.component";
import { WhiteRedGreenButtonsDlgComponent } from "src/app/common/components/white-red-green-buttons-dlg/white-red-green-buttons-dlg.component";
import { BalanceInfoMenuComponent } from "./balance-info-menu/balance-info-menu.component";
import { CompanyCreatorFormComponent } from "./company-creator-form/company-creator-form.component";
import { EmptyComponent } from "./empty/empty.component";
import { EnteraHeaderComponent } from "./entera-header/entera-header.component";
import { EnteraProgressBarComponent } from "./entera-progress-bar/entera-progress-bar.component";
import { ErrorDetailsComponent } from "./error-details/error-details.component";
import { FixedFooterComponent } from "./fixed-footer/fixed-footer.component";
import { ForbiddenErrorDetailsComponent } from "./forbidden-error-details/forbidden-error-details.component";
import { HaveQuestionsMenuComponent } from "./have-questions-menu/have-questions-menu.component";
import { InfoMenuComponent } from "./info-menu/info-menu.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { NumberInputComponent } from "./number-input/number-input.component";
import { PagesViewerComponent } from "./pages-viewer/pages-viewer.component";
import { PhoneConfirmDlgComponent } from "./phone-confirm-dlg/phone-confirm-dlg.component";
import { ServerSideErrorDetailsDlgComponent } from "./server-side-error-details-dlg/server-side-error-details-dlg.component";
import { ServerSideErrorDetailsComponent } from "./server-side-error-details/server-side-error-details.component";
import { SimpleAlertDlgComponent } from "./simple-alert-dlg/simple-alert-dlg.component";
import { SimplePromptDlgComponent } from "./simple-prompt-dlg/simple-prompt-dlg.component";
import { SingleSelectComponent } from "./single-select/single-select.component";
import { SpacesInfoMenuComponent } from "./spaces-info-menu/spaces-info-menu.component";
import * as fromUploadDialog from "./upload-to-recognize-dialog";
import { UserInfoMenuComponent } from "./user-info-menu/user-info-menu.component";
import { CreateLedgerDlgComponent } from "src/app/common/components/create-ledger-dlg/create-ledger-dlg.component";

export { NotFoundComponent } from "./not-found/not-found.component";
export { ForbiddenErrorDetailsComponent } from "./forbidden-error-details/forbidden-error-details.component";
export { EmptyComponent } from "./empty/empty.component";
export { SimpleAlertDlgComponent } from "./simple-alert-dlg/simple-alert-dlg.component";
export { SingleSelectComponent } from "./single-select/single-select.component";
export { PagesViewerComponent } from "./pages-viewer/pages-viewer.component";
export { SimplePromptDlgComponent } from "./simple-prompt-dlg/simple-prompt-dlg.component";
export { CompanyCreatorFormComponent }  from "./company-creator-form/company-creator-form.component";
export { PhoneConfirmDlgComponent } from "./phone-confirm-dlg/phone-confirm-dlg.component";

export const entryComponents = [
    ServerSideErrorDetailsComponent,
    fromUploadDialog.UploadToRecognizeDialogComponent,
    SimpleAlertDlgComponent,
    WhiteRedGreenButtonsDlgComponent,
    SimplePromptDlgComponent,
    TwoInputsDlgComponent,
    MatchSettingsDlgComponent,
    CreateLedgerDlgComponent,
    CompanyCreatorFormComponent,
    PhoneConfirmDlgComponent,
    PurchaseDlgComponent,
];

export const components = [
    ...entryComponents,
    FixedFooterComponent,
    NotFoundComponent,
    ForbiddenErrorDetailsComponent,
    EnteraHeaderComponent,
    InfoMenuComponent,
    UserInfoMenuComponent,
    SpacesInfoMenuComponent,
    NotificationWithInfoComponent,
    NotificationWithValidationComponent,
    BalanceInfoMenuComponent,
    EmptyComponent,
    SingleSelectComponent,
    EnteraSelectComponent,
    ErrorDetailsComponent,
    ServerSideErrorDetailsDlgComponent,
    ...fromUploadDialog.components,
    EnteraProgressBarComponent,
    SimpleAlertDlgComponent,
    WhiteRedGreenButtonsDlgComponent,
    SimplePromptDlgComponent,
    NumberInputComponent,
    PagesViewerComponent,
    CompanyCreatorFormComponent,
    PhoneConfirmDlgComponent,
    HaveQuestionsMenuComponent,
    MobileDocumentUploadFileRowComponent,
    MobileDocumentUploadFileListComponent,
    MobileDocumentUploadFileButtonComponent,
    EnteraMatSelectWrapperComponent,
    ...commonTemplates,
];
