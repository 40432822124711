import { Injectable } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ofType } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { of } from "rxjs";
import { Observable } from "rxjs";
import { withLatestFrom } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models/api-response";
import { BroadcastMessageResponse } from "src/app/common/models/broadcast-message-response.model";
import { BroadcastMessage } from "src/app/common/models/broadcast-message.model";
import { DlgService } from "src/app/common/services/dlg.service";
import { BroadcastMessagesDlgComponent } from "src/app/root/containers/broadcast-messages-dlg/broadcast-messages-dlg.component";
import { BroadcastMessagesService } from "src/app/root/services/broadcast-messages.service";
import { broadcastMessageDlgActions } from "src/app/root/store/actions/broadcast-message-dlg.action";
import { BroadcastMessagesDlgActionType } from "src/app/root/store/actions/broadcast-message-dlg.action";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { RootState } from "src/app/root/store/reducers/index";
import { broadcastMessagesDlgSelectors } from "src/app/root/store/selectors/broadcast-message-dlg.selector";

/**
 * Side-эффекты на события, связанные с диалогом для сообщений пользователю.
 */
@Injectable()
export class BroadcastMessagesDlgEffect {
    //region Fields

    /**
     * Сервис для вызова диалога для отображения сообщений пользователю.
     */
    private readonly _dlgService: DlgService;

    /**
     * Сервис для вызова диалога для отображения сообщений пользователю.
     */
    private readonly _translateService: TranslateService;

    /**
     * Сервис для отправки ответов пользователей на сообщения.
     */
    private readonly _broadcastMessagesService: BroadcastMessagesService;

    /**
     * Экземпляр открытого в данный момент диалога для отображения сообщений пользователю.
     */
    private _openedDlg: MatDialogRef<BroadcastMessagesDlgComponent>;

    //endregion
    //region Ctor

    /**
     * Конструктор side-эффектов на события, связанные с диалогом сообщений пользователю.
     *
     * @param _store Состояние приложения.
     * @param dlgService Сервис для вызова диалога для отображения сообщений пользователю.
     * @param _actions$ Поток событий, происходящих в системе.
     * @param translateService Сервис перевода текста.
     * @param broadcastMessagesService Сервис отправки ответов на сообщения пользователей.
     */
    constructor (
        private readonly _store: Store<RootState>,
        private readonly _actions$: Actions,
        dlgService: DlgService,
        translateService: TranslateService,
        broadcastMessagesService: BroadcastMessagesService

    ) {
        this._dlgService = dlgService;
        this._translateService = translateService;
        this._broadcastMessagesService = broadcastMessagesService;
    }

    //endregion
    //region Effects

    /**
     * Обработка события с требованием открыть диалог сообщений для пользователя.
     */BroadcastMessageSendResponseProps
    @Effect({ dispatch: false })
    openDialog$ = this._actions$
        .pipe(
            ofType(BroadcastMessagesDlgActionType.OPEN),
            tap((): void => {

                if (this._openedDlg) {

                    this._openedDlg.close();
                }

                this._openedDlg = this._dlgService.openBroadcastMessagesDialog();
            }),
        );

    /**
     * Обработка события с требованием закрыть диалог.
     */
    @Effect({ dispatch: false })
    closeDialog$ = this._actions$
        .pipe(
            ofType(BroadcastMessagesDlgActionType.CLOSE),
            filter((): boolean => !!this._openedDlg),
            tap((): void => this._openedDlg.close()),
        );

    /**
     * Обработка событий отправки ответа пользователя на сообщения.
     */
    @Effect()
    sendResponse$ = this._actions$
        .pipe(
            ofType(BroadcastMessagesDlgActionType.SEND_RESPONSE),
            withLatestFrom(this._store.select(broadcastMessagesDlgSelectors.currentMessage)),
            switchMap(
                ([props, message]: [BroadcastMessageResponse, BroadcastMessage]) =>
                    this._broadcastMessagesService.sendResponse(message.id, props).pipe(
                        map(() => broadcastMessageDlgActions.sendResponseSuccess()),
                        catchError((error: ApiResponse): Observable<Action> =>
                            of(broadcastMessageDlgActions.sendResponseFail({error: error})),
                        ),
                    ),
            ),
        );

    /**
     * Обработка событий успешной отправки ответа пользователя на сообщения.
     */
    @Effect()
    sendResponseSuccess$ = this._actions$
        .pipe(
            ofType(BroadcastMessagesDlgActionType.SEND_RESPONSE_SUCCESS),
            withLatestFrom(this._store.select(broadcastMessagesDlgSelectors.messageCount)),
            filter((): boolean => !broadcastMessagesDlgSelectors.messageCount),
            map(() => broadcastMessageDlgActions.close)
        )

    /**
     * Обработка событий неудачной отправки ответа пользователя на сообщения.
     */
    @Effect({dispatch: false})
    sendResponseFail$ = this._actions$
        .pipe(
            ofType(BroadcastMessagesDlgActionType.SEND_RESPONSE_FAIL),
            tap((error: ErrorResponseProps<void>): void => {

                const errorMessage : string = error.error.errorMessage || "Unknown error";
                const tryReloadingMessage : string = this._translateService
                    .instant("broadcastMessages.sendResponse.tryReloadingPage")

                this._dlgService.openSimpleDlg({
                    headerKey: "common.fail",
                    text: of(errorMessage + " " + tryReloadingMessage),
                })
            }),
        )

    //endregion
}
