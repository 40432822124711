import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { Subscription } from "rxjs";
import { ApiResponse } from "src/app/common/models";
import { WhiteRedGreenButtonsDlgData } from "src/app/common/models/white-red-green-buttons-dlg-data";

/**
 * Компонент простого диалога с текстом и 3 разноцветными кнопками.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "white-red-green-buttons-dlg",
    templateUrl: "./white-red-green-buttons-dlg.component.html",
    styleUrls: ["./white-red-green-buttons-dlg.component.scss"],
})
export class WhiteRedGreenButtonsDlgComponent implements OnInit {
    //region Public fields

    /**
     * I18n-ключ заголовка диалога.
     */
    headerKey: string;

    /**
     * Параметры для подстановки в заголовок диалога.
     */
    headerParams: Object;

    /**
     * I18n-ключ текста для отображения в диалоге.
     */
    textKey: string;

    /**
     * Параметры для подстановки в текст диалога.
     */
    textParams: Object;

    /**
     * Находтся ли диалог в стандии загрузки.
     */
    loading: boolean;

    /**
     * Ошибка, полученная от сервера.
     */
    error: ApiResponse;

    //endregion
    //region Private fields

    /**
     * Данные диалога.
     *
     * @private
     */
    private _data: WhiteRedGreenButtonsDlgData;

    /**
     * Сервис для получения текстов на текущем языке по их ключам.
     *
     * @private
     */
    private _translateService: TranslateService;

    /**
     * Подписки компонента.
     */
    private _subscriptions: Subscription[] = [];

    //endregion
    //region Ctor

    /**
     * Конструктор компонента простого диалога с текстом и 3 разноцветными кнопками.
     *
     * @param _dialogRef Компонент диалога.
     * @param _changeDetector Сервис для управления запуском определения angular'ом изменений данных,
     * произошедших в компоненте.
     * @param data Данные для простых диалогов.
     * @param translateService Сервис для работы с i18n-сообщениями.
     */
    constructor(
        private _dialogRef: MatDialogRef<WhiteRedGreenButtonsDlgComponent>,
        @Inject(MAT_DIALOG_DATA) data: WhiteRedGreenButtonsDlgData,
        translateService: TranslateService,
    ) {
        this._setData(data);
        this._translateService = translateService;
    }

    //endregion
    //region Hooks

    /**
     * Логика при инициализации компонента.
     */
    ngOnInit(): void {

        // Использование afterClosed() не вызывает cancelCallback
        this._subscriptions.push(this._dialogRef.beforeClosed().subscribe(this._data.closeCallback));
    }

    /**
     * Логика при уничтожении компонента.
     */
    ngOnDestroy(): void {

        // Отписываемся от всех подписок.
        this._subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    }

    //endregion
    //region Getters and Setters

    /**
     * I18n-ключ текста белой кнопки диалога.
     */
    get whiteBtnKey(): string {

        return this._data.whiteBtnKey;
    }

    /**
     * I18n-ключ текста красной кнопки диалога.
     */
    get redBtnKey(): string {

        return this._data.redBtnKey;
    }

    /**
     * I18n-ключ текста зеленой кнопки диалога.
     */
    get greenBtnKey(): string {

        return this._data.greenBtnKey;
    }

    /**
     * Текст диалога.
     */
    get text(): Observable<string> {

        let result: Observable<string>;

        if (this._data.text) {

            result = this._data.text;
        }
        else {

            result = this._translateService.get(this.textKey, this.textParams);
        }

        return result;
    }

    //endregion
    //region Events

    /**
     * Обработчик клика по красной кнопке диалога.
     */
    redClickHandler(): void {

        if (this._data.redCallback) {

            this._data.redCallback();
        }

        this._dialogRef.close();
    }

    /**
     * Обработчик клика по зеленой кнопке диалога.
     */
    greenClickHandler(): void {

        if (this._data.greenCallback) {

            this._data.greenCallback();
        }

        this._dialogRef.close();
    }

    /**
     * Обработчик клика по белой кнопке диалога.
     */
    whiteClickHandler(): void {

        if (this._data.whiteCallback) {

            this._data.whiteCallback();
        }

        this._dialogRef.close();
    }

    //endregion
    //region Private

    /**
     * Выполняет обновление данных диалога на основе заданных данных.
     *
     * @param data Данные диалога.
     *
     * @private
     */
    private _setData(data: WhiteRedGreenButtonsDlgData): void {

        this._data = data;
        this.headerKey = this._data.headerKey;
        this.headerParams = this._data.headerParams || {};
        this.textKey = this._data.textKey;
        this.textParams = this._data.textParams || {};
        this.loading = this._data.loading;
        this.error = this._data.error;
    }

    //endregion
}
