import {PermissionItem} from "src/app/common/models/permission-item";

/**
 * Право администратора на какую-либо операцию.
 */
export class AdminPermissionItem implements PermissionItem {
    //region Admin Permissions

    /**
     * Административные задачи.
     */
    public static readonly ADMIN = new AdminPermissionItem("ADMIN");

    /**
     * Вход под пользователем.
     */
    public static readonly ADMIN_IMPERSONATE
        = new AdminPermissionItem("ADMIN_IMPERSONATE", AdminPermissionItem.ADMIN);

    /**
     * Контроль баланса пространств.
     */
    public static readonly CLIENT_CONTROL
        = new AdminPermissionItem("CLIENT_CONTROL", AdminPermissionItem.ADMIN);

    /**
     * Видеть все документы.
     */
    public static readonly SEE_ALL_DOCUMENTS
        = new AdminPermissionItem("SEE_ALL_DOCUMENTS", AdminPermissionItem.ADMIN);

    /**
     * Изменять пространства документов клиента.
     */
    public static readonly EDIT_CLIENT_SPACES
        = new AdminPermissionItem("EDIT_CLIENT_SPACES", AdminPermissionItem.CLIENT_CONTROL);

    /**
     * Видеть ссылку на редактирование
     */
    public static readonly ADVANCE_EDIT_ALL_DOCUMENTS =
        new AdminPermissionItem("ADVANCE_EDIT_ALL_DOCUMENTS", AdminPermissionItem.ADMIN);

    /**
     * Изменять баланс клиента.
     */
    public static readonly CHANGE_CLIENT_BALANCE
        = new AdminPermissionItem(("CHANGE_CLIENT_BALANCE"), AdminPermissionItem.ADMIN);

    /**
     * Увеличивать баланс клиента.
     */
    public static readonly INCREASE_CLIENT_BALANCE
        = new AdminPermissionItem("INCREASE_CLIENT_BALANCE", AdminPermissionItem.ADMIN);

    /**
     * Право изменять данные клиента.
     */
    public static readonly EDIT_CLIENT_INFO
        = new AdminPermissionItem("EDIT_CLIENT_INFO", AdminPermissionItem.CLIENT_CONTROL);

    /**
     * Право активировать/деактивировать клиента.
     */
    public static readonly  INACTIVATE_CLIENT
        = new AdminPermissionItem("INACTIVATE_CLIENT", AdminPermissionItem.CLIENT_CONTROL);

    /**
     * Право изменять активность подписки на функционал отчёта реализации.
     */
    public static readonly SALES_REPORT = new AdminPermissionItem(
        "EDIT_SALES_REPORT_SUBSCRIPTION",
        AdminPermissionItem.CLIENT_CONTROL,
    );

    /**
     * Право изменять подписки на функционал выгрузки документов в ЭДО.
     */
    public static readonly EDIT_EDI_UPLOAD_SUBSCRIPTION = new AdminPermissionItem(
        "EDIT_EDI_UPLOAD_SUBSCRIPTION",
        AdminPermissionItem.CLIENT_CONTROL,
    );

    /**
     * Право изменять информацию о партнере.
     */
    public static readonly EDIT_PARTNER_INFO
        = new AdminPermissionItem("EDIT_PARTNER_INFO", AdminPermissionItem.CLIENT_CONTROL);

    /**
     * Создавать новые пространства документов.
     */
    public static readonly CREATE_CLIENT_SPACE = new AdminPermissionItem(
        "CREATE_CLIENT_SPACE",
        AdminPermissionItem.EDIT_CLIENT_SPACES,
    );

    /**
     * Редактировать пространства документов.
     */
    public static readonly EDIT_CLIENT_SPACE = new AdminPermissionItem(
        "EDIT_CLIENT_SPACE",
        AdminPermissionItem.EDIT_CLIENT_SPACES,
    );

    /**
     * Редактировать данные пространства документов.
     */
    public static readonly EDIT_SPACE_INFO = new AdminPermissionItem(
        "EDIT_SPACE_INFO",
        AdminPermissionItem.EDIT_CLIENT_SPACE,
    );

    /**
     * Изменять баланс пространства документов.
     */
    public static readonly CHANGE_SPACE_BALANCE = new AdminPermissionItem(
        "CHANGE_SPACE_BALANCE",
        AdminPermissionItem.EDIT_CLIENT_SPACE,
    );

    /**
     * Переключать флаги пространства документов.
     */
    public static readonly TOGGLE_SPACE_FLAGS = new AdminPermissionItem(
        "TOGGLE_SPACE_FLAGS",
        AdminPermissionItem.EDIT_CLIENT_SPACE,
    );

    /**
     * Переключать флаги пространства документов.
     */
    public static readonly TOGGLE_EXTENDED_TYPES_SPACE_FLAG = new AdminPermissionItem(
        "TOGGLE_EXTENDED_TYPES_SPACE_FLAG",
        AdminPermissionItem.TOGGLE_SPACE_FLAGS,
    );

    /**
     * Переключать флаг TRIAL-приоритета пространства документов.
     */
    public static readonly TOGGLE_SPACE_TRIAL_FLAG = new AdminPermissionItem(
        "TOGGLE_SPACE_TRIAL_FLAG",
        AdminPermissionItem.TOGGLE_SPACE_FLAGS,
    );

    /**
     * Переключать флаг использования баланса клиента для пространства документов.
     */
    public static readonly TOGGLE_SPACE_USE_CLIENT_BALANCE_FLAG = new AdminPermissionItem(
        "TOGGLE_SPACE_USE_CLIENT_BALANCE_FLAG",
        AdminPermissionItem.TOGGLE_SPACE_FLAGS,
    );

    /**
     * Переключать флаг использования пространства документов для иностранных документов.
     */
    public static readonly TOGGLE_FOREIGN_SPACE_FLAG = new AdminPermissionItem(
        "TOGGLE_FOREIGN_SPACE_FLAG",
        AdminPermissionItem.TOGGLE_SPACE_FLAGS,
    );

    /**
     * Изменять приоритет пространства документов.
     */
    public static readonly UPDATE_SPACE_PRIORITY = new AdminPermissionItem(
        "UPDATE_SPACE_PRIORITY",
        AdminPermissionItem.EDIT_CLIENT_SPACE,
    );

    /**
     * Приглашать пользователей в в папки.
     */
    public static readonly INVITE_USERS_TO_SPACE = new AdminPermissionItem(
        "INVITE_USERS_TO_SPACE",
        AdminPermissionItem.EDIT_CLIENT_SPACE,
    );

    /**
     * Видеть доверенные почты, с которых могут приходить задачи на распознавание в эту папку.
     */
    public static readonly SEE_TRUSTED_EMAILS = new AdminPermissionItem(
        "SEE_TRUSTED_EMAILS",
        AdminPermissionItem.CLIENT_CONTROL,
    );

    /**
     * Редактировать почты, с которых могут приходить задачи на распознавание в эту папку.
     */
    public static readonly EDIT_TRUSTED_EMAILS = new AdminPermissionItem(
        "EDIT_TRUSTED_EMAILS",
        AdminPermissionItem.SEE_TRUSTED_EMAILS,
    );

    /**
     * Перемещать документы из одной папки в другую.
     */
    public static readonly MOVE_DOCUMENTS = new AdminPermissionItem(
        "MOVE_DOCUMENTS",
        AdminPermissionItem.CLIENT_CONTROL,
    );

    /**
     * Редактировать документы в папке.
     */
    public static readonly EDIT_ALL_DOCUMENTS = new AdminPermissionItem(
        "EDIT_ALL_DOCUMENTS",
        AdminPermissionItem.ADMIN
    );

    //endregion
    //region Fields

    /**
     * Ключ права.
     */
    readonly value: string;

    /**
     * Родительское право.
     */
    readonly parent: AdminPermissionItem;

    //endregion
    //region Ctor

    /**
     * Конструктор права администратора.
     *
     * @param value Ключ права.
     * @param parent Родительское право.
     */
    private constructor(value: string, parent: AdminPermissionItem = null) {
        this.value = value;
        this.parent = parent;
    }

    //endregion
    //region Getters and Setters

    /**
     * I18n-ключ названия права.
     */
    nameKey(): string {

        return "AdminPermissionItem." + this.value;
    }

    //endregion
}
