<div class="info-menu"
    [matMenuTriggerFor]="infoMenu"
    [class.info-menu_without-icon]="iconDisabled"
    [class.info-menu_without-menu]="!menuItems.length"
    [class.info-menu_large]="large"
    (mouseenter)="hovered = true"
    (mouseleave)="hovered = false"
>
    <mat-icon *ngIf="!iconDisabled"
        class="info-menu__icon"
        [fontSet]="hovered && menuItems.length ? hoveredIconFontSet : iconFontSet"
        [innerHtml]="hovered && menuItems.length ? hoveredIcon : icon"
    ></mat-icon>
    <span
        class="info-menu__label"
        [innerHtml]="label ? label : (labelKey | translate)"
    ></span>
    <mat-icon
        *ngIf="menuItems.length"
        class="info-menu__arrow-down-icon"
        [class.mat-icon_large]="large"
    >keyboard_arrow_down</mat-icon>
</div>
<mat-menu
    #infoMenu
    [xPosition]="xPosition"
    [yPosition]="yPosition"
    [class]="overlayPanelClass"
>
    <button *ngFor="let menuItem of menuItems; i as index;"
        mat-menu-item
        [class.mat-menu-item_right-icon]="menuItem.rightIcon"
        [class.mat-menu-item_selected]="menuItem.selected"
        [class.mat-menu-item_green]="menuItem.green"
        (click)="menuItemClickHandler(menuItem)"
        [disabled]="menuItem.disabled"
    >
        <mat-icon
            *ngIf="menuItem.icon"
            class="menu-item__left-icon"
            [class.mat-icon_green]="menuItem.green"
        >
            {{menuItem.icon}}
        </mat-icon>
        <span
            class="menu-item__label"
            [innerHtml]="menuItem.text ? menuItem.text : (menuItem.messageKey | translate)"
        ></span>
        <div *ngIf="menuItem.rightIcon">
            <mat-icon
                class="menu-item__right-icon"
                [fontSet]="menuItem.rightIconFontSet"
                [class.mat-icon_green]="menuItem.rightIconHovered"
                [matTooltip]="menuItem.rightIconTitleKey ? (menuItem.rightIconTitleKey | translate) : ''"
                matTooltipShowDelay="300"
                [innerHtml]="menuItem.rightIcon"
                (mouseenter)="menuItem.rightIconHovered = true"
                (mouseleave)="menuItem.rightIconHovered = false"
                (click)="$event.stopPropagation();menuItemRightIconClickHandler(menuItem);menuBtn.closeMenu()"
            ></mat-icon>
        </div>
    </button>
</mat-menu>
