import { RecognitionTaskSourcesActionType } from "src/app/spaces/store/actions/recognition-task-sources.action";
import { DocumentCreatorsActionType } from "./document-creators.action";
import { DocumentStatesActionType } from "./document-states.action";

export * from "src/app/root/store/actions/document-types.action";
export * from "./document-states.action";
export * from "./document-creators.action";
export * from "./documents.action";
export * from "./documents-archive-dlg.action";
export * from "./documents-count.action";
export * from "src/app/spaces/modules/documents-registry/store/actions/documents-registry-export.action";

/**
 * События, по которым можно понять, что на сервере произошла ошибка.
 *
 * Эти события слушаются в одном месте, ответ сервера сохраняется в состояние приложения, после чего
 * выполняется переход на страницу сообщения об ошибке.
 */
export const serverSideErrors: string[] = [
    RecognitionTaskSourcesActionType.LOAD_FAIL,
    DocumentStatesActionType.LOAD_FAIL,
    DocumentCreatorsActionType.LOAD_FAIL,
];
