<div class="recognition-upload-dialog">
    <h1 *ngIf="data.dlgType === 'RECOGNITION'" class="recognition-upload-dialog__title mat-h1 mat-h1_slim text-center">
        {{"common.upload-to-recognize-dialog.title" | translate}}
    </h1>
    <h1 *ngIf="data.dlgType === 'BANK_STATEMENT'" class="recognition-upload-dialog__title mat-h1 mat-h1_slim text-center">
        {{"common.upload-bank-statement-dialog.title" | translate}}
    </h1>
    <h1 *ngIf="data.dlgType === 'SELF_EMPLOYED'" class="recognition-upload!-dialog__title mat-h1 mat-h1_slim text-center">
        {{"common.upload-self-employed-dialog.title" | translate}}
    </h1>
    <div
        *ngIf="data.dlgType === 'BANK_STATEMENT' && !(currentSpace$ | async)?.paid"
         class="recognition-upload-dialog__limit-block text-center"
    >
        <span>{{ "common.upload-bank-statement-dialog.trialLimit" | translate: { limit: (currentSpace$ | async).trialBankStatementLimit} }}</span>
    </div>
    <entera-file-upload-zone *ngIf="!uploadState.error && uploadState.progress !== 100 && !uploadState.duplicateCheckingByHash"
        [uploadState]="uploadState"
        [multipleFileMode]="data.dlgType !== 'BANK_STATEMENT'"
        [fileFormats]="getAllowedFileExtensions()"
        (addFiles)="addFilesHandler($event)"
        (remove)="remove.emit($event)"
    >
    </entera-file-upload-zone>
    <div *ngIf="canUploadFiles"
        class="recognition-upload-dialog__send text-center"
        [ngClass]="{ 'recognition-upload-dialog__centered': data.dlgType === 'SELF_EMPLOYED'}"
    >
        <div
            *ngIf="(permissions$ | async)['RECOGNITION_FLAGS'] && data.dlgType === 'RECOGNITION'"
            class="recognition-upload-dialog__process-flags"
        >
            <mat-checkbox [formControl]="forceOcr">
                {{ 'common.upload-to-recognize-dialog.forceOcr' | translate }}
            </mat-checkbox><br/>
            <mat-checkbox [formControl]="forceProcessing">
                {{ 'common.upload-to-recognize-dialog.forceProcessing' | translate }}
            </mat-checkbox><br/>
            <mat-checkbox [formControl]="forceQueue">
                {{ 'common.upload-to-recognize-dialog.forceQueue' | translate }}
            </mat-checkbox>
        </div>
        <mat-form-field *ngIf="data.dlgType === 'SELF_EMPLOYED'" class="recognition-upload-dialog__link">
            <input
                matInput
                [formControl]="linkInputFormCtrl"
                [placeholder]="'common.upload-self-employed-dialog.link.placeholder' | translate"
            >
            <button
                mat-icon-button
                *ngIf="linkInputFormCtrl?.value && linkInputFormCtrl.value.length"
                matSuffix
                (click)="clearLinksHandler()"
            >
                <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="linkInputFormCtrl.hasError('hasIncorrectParts')">
                {{ "common.upload-self-employed-dialog.link.errors.hasIncorrectParts" | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field
            *ngIf="data.dlgType === 'RECOGNITION' || (data.dlgType === 'SELF_EMPLOYED' && hasFiles)"
            class="recognition-upload-dialog__comment"
        >
            <input
                matInput
                [placeholder]="'common.upload-to-recognize-dialog.comment' | translate"
                [formControl]="comment"
                maxlength="250"
            >
            <mat-hint>{{'common.upload-to-recognize-dialog.commentHint' | translate}}</mat-hint>
        </mat-form-field>
        <button
            mat-raised-button
            color="primary"
            (click)="sendButtonClickHandler()"
        >
            <mat-icon>send</mat-icon>
            {{"common.upload-to-recognize-dialog.buttons.upload" | translate}}
        </button>
    </div>
    <div *ngIf="uploadState.error">
        {{ uploadState.error.errorMessage }}
        <hr class="recognition-upload-dialog__line"/>
        <div class="recognition-upload-dialog__error-close-btn-block">
            <button mat-button (click)="closeButtonClickHandler()">{{ 'dialogs.close' | translate }}</button>
        </div>
    </div>
    <div *ngIf="uploadState.progress === 100 && !uploadState.error || uploadState.duplicateCheckingByHash">
        <mat-spinner class="recognition-upload-dialog__spinner"></mat-spinner>
    </div>
    <button
        class="recognition-upload-dialog__close"
        (click)="closeButtonClickHandler()"
        mat-icon-button
        color="primary"
    >
        <mat-icon class="mat-icon_large">close</mat-icon>
    </button>
</div>
