<div class="entera-select">
    <mat-form-field
        class="entera-select__mat-form-field"
        [hideRequiredMarker]="true"
        [class.mat-table-compact]="tableCompact"
        [class.marked-select]="marked"
    >
        <mat-select
            [formControl]="valueControl"
            [placeholder]="placeholder"
            [compareWith]="compareWith"
            [required]="required"
            [errorStateMatcher]="errorStateMatcher"
            [multiple]="multiselect"
            (selectionChange)="selectionChangeHandler($event)"
            (openedChange)="openSelectHandle($event)"
            panelClass="{{ tableCompact ? 'table-compact-select-panel' : 'entera-select-panel' }}"
        >
            <mat-select-trigger *ngIf="isSelectedValueExists">
                <div class="entera-select__selected-text">
                    <ng-content select="[prefix]"></ng-content>
                    <div class="entera-select__additional-text">
                        {{ getResultText(selected$ | async) }}
                    </div>
                    <div>{{ getAdditionalResultText(selected$ | async) }}</div>
                    <ng-content select="[postfix]"></ng-content>
                </div>
            </mat-select-trigger>
            <ngx-mat-select-search
                *ngIf="searchEnabled"
                [formControl]="searchControl"
                [placeholderLabel]="searchPlaceholder$ | async"
                [noEntriesFoundLabel]="noSearchResultsPlaceholder$ | async"
                [disableScrollToActiveOnOptionsChanged]="true"
                (keydown)="keydownHandler($event)"
            >
            </ngx-mat-select-search>
            <mat-option class="select__without-checkbox"
                *ngIf="isStartTypingPlaceholderVisible" [disabled]="true"
            >
                {{ startSearchPlaceholder$ | async }}
            </mat-option>
            <div *ngIf="isSelectedValueExists && (!!pagedSearchFn || !!searchFn || maxSelectedReached)">
                <div *ngIf="multiselect && (selected$ | async)">
                    <mat-option
                        *ngFor="let selectedValue of (selected$ | async)"
                        [value]="getOptionValue(selectedValue)"
                    >
                        <span [innerHTML]="getOptionText(selectedValue)"></span>
                    </mat-option>
                </div>
                <div *ngIf="!multiselect">
                    <mat-option [value]="getOptionValue(selected$ | async)" [disabled]="true">
                        <span [innerHTML]="getOptionText(selected$ | async)"></span>
                    </mat-option>
                </div>
            </div>
            <div *ngIf="!maxSelectedReached">
                <mat-option
                    *ngIf="isNullOptionVisible"
                    [value]="getNullOptionValue()"
                >
                    {{ nullOptionText }}
                </mat-option>
                <mat-option
                    *ngFor="let option of (searchOptionsWithoutSelected$ | async)"
                    class="select__with-category"
                    [value]="getOptionValue(option)"
                >
                    <span [innerHTML]="getOptionText(option)"></span>
                    <span *ngIf="!!optionCategoryField" class="entera-select__category">
                        <em>{{ option[optionCategoryField] }}</em>
                    </span>
                </mat-option>
                <mat-option
                    *ngFor="let option of (filteredOptions$ | async)"
                    [value]="getOptionValue(option)"
                >
                    <span [innerHTML]="getOptionText(option)"></span>
                </mat-option>
            </div>
            <mat-option
                *ngIf="maxSelectedReached"
                [disabled]="true"
                class="select__without-checkbox"
            >
                {{ "enteraSelect.overloadMaxSelected" | translate }}
            </mat-option>
            <mat-option *ngIf="loading$ | async"
                [disabled]="true"
                class="select__loading select__without-checkbox"
            >
                <mat-spinner [diameter]="25"></mat-spinner>
            </mat-option>
            <mat-option class="select__without-checkbox" *ngIf="isNoSearchResultsVisible"
                [disabled]="true"
            >
                {{ noSearchResultsPlaceholder$ | async }}
            </mat-option>
            <mat-option *ngIf="pagedSearchFn || searchFn" class="select__invisible" [disabled]="true"></mat-option>
        </mat-select>
        <mat-icon 
            *ngIf="valueControl.value && valueControl.enabled && clearBtnEnabled"
            class="entera-select__clear-btn"
            [matTooltip]="(clearBtnTitle ? clearBtnTitle : ('clear' | translate))"
            (click)="clearBtnClickHandler($event);"
        >clear</mat-icon>
        <mat-error *ngIf="valueControl.errors?.required && requiredErrorMessage">{{requiredErrorMessage}}</mat-error>
        <mat-error *ngIf="!valueControl.errors?.required && customError && customErrorMessage">
            {{customErrorMessage}}
        </mat-error>
    </mat-form-field>
</div>
