import { ClearableSelectTemplateComponent } from "src/app/common/components/templates/clearable-select-template.component";
import { InputClearButtonTemplateComponent } from "src/app/common/components/templates/input-clear-button-template.component";

/**
 * Компоненты шаблонов.
 */
export const commonTemplates = [
    InputClearButtonTemplateComponent,
    ClearableSelectTemplateComponent,
];
