import { APP_INITIALIZER } from "@angular/core";
import { RouterStateSerializer } from "@ngrx/router-store";
import { MetaReducer } from "@ngrx/store";
import * as LogRocket from "logrocket";
import createNgrxMiddleware from "logrocket-ngrx";
import { ConfigurationService } from "src/app/common/services/configuration.service";
import { CustomSerializer } from "../store";
import * as fromRoot from "../store/reducers";
import { USER_PROVIDED_META_REDUCERS } from "@ngrx/store";

/**
 * Возвращает функцию для получения конфигурации.
 */
export function getConfiguration(configService: ConfigurationService): Function {

    return () => configService.loadConfigurations().toPromise();
}

/**
 * Возвращает список MetaReducer'ов приложения.
 */
export function getMetaReducers(): MetaReducer<fromRoot.RootState>[] {

    return [createNgrxMiddleware(LogRocket, { })];
}

/**
 * Провайдеры, которые необходимо зарегистрировать в общем модуле.
 */
export const providers = [
    {
        provide: RouterStateSerializer,
        useClass: CustomSerializer,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: getConfiguration,
        deps: [ConfigurationService],
        multi: true,
    },
    {
        provide: USER_PROVIDED_META_REDUCERS,
        useFactory: getMetaReducers,
    },
];
