import { DocumentTypesGuard } from "src/app/root/guards/document-types.guard";
import { NoUserGuard } from "src/app/root/guards/no-user-guard.service";
import { AdminGuard } from "./admin.guard";
import { HideHeaderSpacesInfoGuard } from "./hide-header-spaces-info.guard";
import { HideHeaderGuard } from "./hide-header.guard";
import { NewUserGuard } from "./new-user.guard";
import { PermissionGuard } from "./permission.guard";
import { RegistrationPageGuard } from "./registration-page.guard";
import { RootPathGuard } from "./root-path.guard";
import { ShowHeaderGuard } from "./show-header.guard";
import { UserGuard } from "./user.guard";
import { HideHeaderHowToStartButtonGuard } from "src/app/root/guards/hide-header-how-to-start-button.guard";

export { AdminGuard } from "./admin.guard";
export { HideHeaderSpacesInfoGuard } from "./hide-header-spaces-info.guard";
export { HideHeaderGuard } from "./hide-header.guard";
export { NewUserGuard } from "./new-user.guard";
export { PermissionGuard } from "./permission.guard";
export { RegistrationPageGuard } from "./registration-page.guard";
export { RootPathGuard } from "./root-path.guard";
export { ShowHeaderGuard } from "./show-header.guard";
export { UserGuard } from "./user.guard";

export const guards = [
    AdminGuard,
    HideHeaderSpacesInfoGuard,
    HideHeaderHowToStartButtonGuard,
    HideHeaderGuard,
    NewUserGuard,
    PermissionGuard,
    RegistrationPageGuard,
    RootPathGuard,
    ShowHeaderGuard,
    UserGuard,
    NoUserGuard,
    DocumentTypesGuard,
];
