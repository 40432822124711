import { ActivatedRouteSnapshot } from '@angular/router';
import { Params } from '@angular/router';
import { RouterStateSnapshot } from '@angular/router';

import { RouterReducerState } from '@ngrx/router-store';
import { RouterStateSerializer } from '@ngrx/router-store';
import { Injectable } from "@angular/core";

/**
 * Представление URL'а.
 */
export interface RouteState {

    // Текущий URL, включая query.
    url: string;

    // Текущий URL без query
    path: string;

    // GET-параметры.
    queryParams: Params;

    // Параметры из URL.
    params: Params;
}

/**
 * На основе данных об URL из Angular формирует представление RouteState.
 */
@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouteState> {

    serialize(routerState: RouterStateSnapshot): RouteState {

        const { url } = routerState;
        const { queryParams } = routerState.root;
        const path = url.split('?')[0];

        let state: ActivatedRouteSnapshot = routerState.root;
        let params = {};
        while (state.firstChild) {
            params = {
                ...params,
                ...state.params
            };
            state = state.firstChild
        }
        params = {
            ...params,
            ...state.params
        };

        return { url, path, queryParams, params };
    }
}

/**
 * Возвращает представление текущего URL.
 * 
 * @param routerReducerState Состояние данных о текущем URL.
 */
export const getRouteState = (routerReducerState: RouterReducerState<RouteState>) =>
    (routerReducerState ? routerReducerState.state : null);
