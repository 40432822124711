import { Input } from "@angular/core";
import { TemplateRef } from "@angular/core";
import { ViewChild } from "@angular/core";
import { Component } from "@angular/core";
import { AbstractControl } from "@angular/forms";

/**
 * Компонента шаблонизатор значения контрола с функцией очищения.
 */
@Component({
    selector: "clearable-select-template",
    template: `
        <ng-template #template let-val let-control="control">
            {{ getFormattedKeyOrValue(val) | translate }}
            <button
                class="clearable-select-template__clear-btn"
                mat-icon-button
                (click)="clearHandler($event, control)"
            >
                <mat-icon class="clearable-select-template__icon">clear</mat-icon>
            </button>
        </ng-template>
    `,
    styles: [`
        .clearable-select-template__icon {
            font-size: 14px;
        }
        .clearable-select-template__clear-btn {
            position: absolute;
            right: 40px;
            height: 0;
            width: 0;
            line-height: 0;
        }
    `]
})
export class ClearableSelectTemplateComponent {
    //region Inputs

    /**
     * Префикс i18n-ключа для построения строки для отображения элемента.
     */
    @Input()
    keyPrefix: string = "";

    //endregion
    //region Fields

    /**
     * Ссылка на шаблон.
     */
    @ViewChild("template", {static: true})
    value: TemplateRef<any>;

    //endregion
    //region Getter

    /**
     * Возвращает отформатированное значение или ключ.
     *
     * @param value Значения для форматирования.
     *
     * @return Отформатированное значение или ключ.
     */
    getFormattedKeyOrValue(value: any): string {

        if (!value) {

            return "";
        }
        else if (typeof value === "string") {

            return (this.keyPrefix && this.keyPrefix + "." + value || value);
        }
        else {

            const nameValue = value.shortName || value.name || "";
            return (this.keyPrefix && this.keyPrefix + "." + nameValue || nameValue);
        }

    }
    //endregion
    //region Events

    /**
     * Обработчик события очистки контрола.
     *
     * @param event Событие очистки контрола.
     * @param control Контрол со значением.
     */
    clearHandler(event: MouseEvent, control: AbstractControl): void {

        control.setValue(null);
        event.stopPropagation();
    }

    //endregion
}
