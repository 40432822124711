import { HttpEventType } from "@angular/common/http";
import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Gtag } from "angular-gtag";
import { of } from "rxjs";
import { filter } from "rxjs/operators";
import { withLatestFrom } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { last } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { Space } from "src/app/common/models";
import { ApiResponse } from "src/app/common/models";
import { MultipleRecognitionTaskApiResponse } from "src/app/common/models";
import { RecognitionTaskApiResponse } from "src/app/common/models";
import { isErrorApiResponse } from "src/app/common/models/api-response";
import { FileWithMetaData } from "src/app/common/models/file-with-meta-data";
import { RecognitionTaskService } from "src/app/common/services";
import { FileService } from "src/app/common/services/file-service";
import { StaticImagesHttpClient } from "src/app/common/utils/static-images-http-client.utils";
import { QrService } from "src/app/qr/services/index";
import { UploadTaskToRecognizeProgressAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { UploadTaskToRecognizeFailAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { UploadTaskToRecognizeSuccessAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { UploadToRecognizeActionType } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { UploadTaskToRecognizeAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { GenerateHashForFileLinksFailedAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { GenerateHashForFileLinksAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { MarkFilesAsDuplicatesAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { AddFilesToTaskToRecognizeAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { GenerateHashForFilesAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { FileExistenceCheckFailedAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { RootState } from "src/app/root/store/reducers/index";
import { uploadToRecognizeSelector } from "src/app/root/store/reducers/index";
import { UploadToRecognizeState } from "src/app/root/store/reducers/recognize/upload-to-recognize.reducer";
import { SelectedSpaceForMobileSelectors } from "src/app/root/store/selectors/selected-space-for-mobile.selector";
import { spaceSelector } from "src/app/root/store/selectors/space.selector";

/**
 * Side-эффекты на события, связанные с отправкой документов на распознавание.
 */
@Injectable()
export class UploadToRecognizeEffects {
    //region Ctor

    constructor(
        private actions$: Actions,
        private store: Store<RootState>,
        private service: RecognitionTaskService,
        private _qrService: QrService,
        private _fileService: FileService,
        private _staticImagesHttpClient: StaticImagesHttpClient,
        private readonly _gtag: Gtag,
    ) { }

    //endregion
    //region Public

    /**
     * Эффект нажатия кнопки загрузить в диалоге загрузки документов на распознавание.
     * Выполняется загрузка документа на распознавание - создание задачи на распознавание.
     */
    @Effect()
    upload$ = this.actions$.pipe(
        ofType<UploadTaskToRecognizeAction>(UploadToRecognizeActionType.UPLOAD),
        filter((action: UploadTaskToRecognizeAction) =>
            action.payload.filesWithMetaData.every(file => !file.link)
        ),
        tap(() => this._gtag.event("click_recognize_documents")),
        // Загружаем файл
        map((action: UploadTaskToRecognizeAction) => action.payload),
        switchMap((payload: UploadToRecognizeState) =>
            this.service.createTasks(
                payload.filesWithMetaData.map(fileWithData => fileWithData.file),
                payload.spaceId,
                payload.forceOcrRecognition,
                payload.forceProcessing,
                payload.forceQueue,
                payload.comment,
                payload.mobile,
                payload.bankStatement,
            )
                .pipe(
                    // Прогресс.
                    tap(event => {

                        if (event.type === HttpEventType.UploadProgress) {

                            const progress = Math.floor(event.loaded * 100 / event.total);
                            this.store.dispatch(new UploadTaskToRecognizeProgressAction(progress))
                        }
                    }),

                    // Последнее событие(удача/ошибка).
                    last(),

                    // Обработка ответов.
                    map((last: HttpResponse<MultipleRecognitionTaskApiResponse | RecognitionTaskApiResponse>) => {

                        if (isRecognitionTaskApiResponse(last.body)) {

                            return new UploadTaskToRecognizeSuccessAction([ last.body.recognitionTask ]);
                        }
                        else {

                            return new UploadTaskToRecognizeSuccessAction(last.body.recognitionTasks);
                        }
                    }),

                    // Обработка ошибки.
                    catchError((response: ApiResponse) => of(new UploadTaskToRecognizeFailAction(response)))
                )
        )
    );

    /**
     * Эффект на загрузку чеков самозанятых на распознавание по ссылкам.
     */
    @Effect()
    uploadSelfEmployedReceipt$ = this.actions$.pipe(
        ofType<UploadTaskToRecognizeAction>(UploadToRecognizeActionType.UPLOAD),
        filter((action: UploadTaskToRecognizeAction) => action.payload.filesWithMetaData.every(file => !!file.link)),
        withLatestFrom(this._staticImagesHttpClient.getReceiptPlaceholderImage()),
        switchMap(([action, standardSelfEmplFile]: [UploadTaskToRecognizeAction, Blob]) => {

            const receiptMap: Map<string, Blob> = new Map();
            action.payload.filesWithMetaData.forEach(file => receiptMap.set(file.link, standardSelfEmplFile));

            return this._qrService.sendReceipt(receiptMap, action.payload.spaceId)
                .pipe(
                    map(response => new UploadTaskToRecognizeSuccessAction([ response ])),
                    catchError((response: ApiResponse) => of(new UploadTaskToRecognizeFailAction(response)))
                );
        }));

    /**
     * Эффект нажатия кнопки загрузить в диалоге загрузки документов на распознавание через мобильный интерфейс.
     * Выполняется загрузка документа на распознавание - создание задачи на распознавание.
     */
    @Effect()
    mobileUpload$ = this.actions$.pipe(
        ofType<UploadTaskToRecognizeAction>(UploadToRecognizeActionType.MOBILE_UPLOAD),
        withLatestFrom(this.store.select(uploadToRecognizeSelector)),
        map(([_, state]: [never, UploadToRecognizeState]): UploadToRecognizeState => state),
        withLatestFrom(this.store.select(SelectedSpaceForMobileSelectors.space)),
        map(([payload, space]: [UploadToRecognizeState, Space]): UploadToRecognizeState =>
            ({ ...payload, spaceId: space.id, openPostUploadDialog: false, mobile: true })
        ),
        map((state: UploadToRecognizeState) => new UploadTaskToRecognizeAction(state))
    );


    /**
     * Эффект успешной отправки файлов на распознавание.
     */
    @Effect({dispatch: false})
    uploadSuccess$ = this.actions$.pipe(
        ofType<UploadTaskToRecognizeAction>(UploadToRecognizeActionType.UPLOAD_SUCCESS),
        tap(() => this._gtag.event("send_to_recognition_successfully"))
    );

    /**
     * Генерирует хеши для файлов.
     */
    @Effect()
    generateFileHashes$ = this.actions$.pipe(
        ofType<GenerateHashForFilesAction>(UploadToRecognizeActionType.GENERATE_HASH_FOR_FILES),
        switchMap(action => this._fileService.filesToSHA256Hash(action.payload)
            .pipe(map(filesWithMetaData => new AddFilesToTaskToRecognizeAction(filesWithMetaData))),
        ),
    );

    /**
     * Генерирует хеши для ссылок на файлы.
     */
    @Effect()
    generateFileLinkHashes$ = this.actions$.pipe(
        ofType<GenerateHashForFileLinksAction>(UploadToRecognizeActionType.GENERATE_HASH_FOR_FILE_LINKS),
        switchMap(action => this._fileService.downloadSelfEmployedFilesByLinks(action.payload.value)),
        switchMap(filesWithLinks => {

            if (isErrorApiResponse(filesWithLinks)) {

                return of(new GenerateHashForFileLinksFailedAction(filesWithLinks));
            }

            return this._fileService.filesToSHA256Hash(filesWithLinks.map((fileWithLink) => fileWithLink.file))
                .pipe(
                    map(files => files.map((fileWithHash, index) => ({
                        file: fileWithHash.file,
                        link: filesWithLinks[index].link,
                        hash: fileWithHash.hash,
                    }))),
                    map(files => new AddFilesToTaskToRecognizeAction(files)),
                    catchError((error: ApiResponse) => of(new GenerateHashForFileLinksFailedAction(error))),
                );
        }),
    );

    /**
     * Осуществляет запрос проверки существования файлов.
     */
    @Effect()
    checkFilesExistence$ = this.actions$.pipe(
        ofType<AddFilesToTaskToRecognizeAction>(UploadToRecognizeActionType.ADD_FILES),
        withLatestFrom(this.store.select(spaceSelector)),
        switchMap(([action, space]) =>
            this.service.checkFilesExistence(space, action.payload.filter((file: FileWithMetaData) => !!file.file.size))
                .pipe(
                    map(response => new MarkFilesAsDuplicatesAction(response.fileMetaDataList)),
                    catchError((error: ApiResponse) => of(new FileExistenceCheckFailedAction(error))),
                ),
        ),
    );

    //endregion
}

function isRecognitionTaskApiResponse(
    val: RecognitionTaskApiResponse | MultipleRecognitionTaskApiResponse
): val is RecognitionTaskApiResponse {

    return !!val && !!val["recognitionTask"];
}
