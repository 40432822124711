import { OnInit } from "@angular/core";
import { Inject } from "@angular/core";
import { OnDestroy } from "@angular/core";
import { Component } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { RootState } from "rootStore";
import { of } from "rxjs";
import { throwError } from "rxjs";
import { Subject } from "rxjs";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { shareReplay } from "rxjs/operators";
import { startWith } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { ClientAdminInfo } from "src/app/admin/models/client-admin-info";
import { AdminService } from "src/app/admin/services/admin.service";
import { Option } from "src/app/common/components/single-select/single-select.component";
import { EnteraDocument } from "src/app/common/models/index";
import { CurrentUserInfo } from "src/app/common/models/index";
import { Space } from "src/app/common/models/index";
import { ApiResponse } from "src/app/common/models/index";
import { UserEmail } from "src/app/common/models/user-email";
import { moveDocumentsDlgActions } from "src/app/common/store/actions/move-documents-dlg.action";
import { MoveDocumentsActionProps } from "src/app/common/store/actions/props/move-documents.action-props";
import { moveDocumentsDlgSelectors } from "src/app/common/store/selectors/move-documents-dlg.selectors";
import { FilterAndSortAndSearchState } from "src/app/spaces/modules/documents-registry/models/filter-and-sort-and-search-state.model";
import { AdminPermissionItem } from "src/app/common/models/admin-permission-item";

/**
 * Компонент диалога перемещения документов в другую папку.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "move-document-to-another-space-dlg",
    templateUrl: "./move-documents-dlg.component.html",
    styleUrls: ["./move-documents-dlg.component.scss"],
})
export class MoveDocumentsDlgComponent implements OnInit, OnDestroy {
    //region Fields

    /**
     * Диалог находится в процессе отправки?
     */
    readonly movingDocs$: Observable<boolean>;

    /**
     * Состояние диалога - все хорошо.
     */
    readonly okState$: Observable<boolean>;

    /**
     * Состояние диалога - ошибка.
     */
    readonly error$: Observable<ApiResponse>;

    /**
     * Список папок.
     */
    readonly spaceList$: Observable<Space[]>;

    /**
     * Список пользователей.
     */
    readonly userList$: Observable<Option[]>;

    /**
     * Контрол элементов формы для поиска клиента.
     */
    readonly clientSelectControl: FormControl = new FormControl();

    /**
     * Контрол элементов формы для поиска папки.
     */
    readonly spaceSelectControl: FormControl = new FormControl();

    /**
     * Контрол элементов формы для поиска пользователя.
     */
    readonly userSelectControl: FormControl = new FormControl();

    /**
     * Документы, которые нужно перенести в другую папку.
     */
    selectedDocuments: EnteraDocument[];

    /**
     * Данные текущего аутентифицированного пользователя.
     */
    currentUser: CurrentUserInfo;

    /**
     * Состояние фильтров в реестре документов.
     */
    filter: FilterAndSortAndSearchState;

    /**
     * Документы, которые не нужно включать в список подходящих к фильтру.
     */
    excludedDocuments: EnteraDocument[];

    /**
     * Нужно ли показывать поля для админа?
     */
    adminView: boolean;

    /**
     * Ошибка переноса документов.
     */
    error: ApiResponse;

    /**
     * Сервис для управления и доступа к состоянию приложения.
     */
    private readonly _store: Store<RootState>;

    /**
     * Сервис для работы с i18n-сообщениями.
     */
    private readonly _translateService: TranslateService;

    /**
     * Объект глобальной отписки.
     */
    private readonly _globalUnsubscribe$: Subject<void> = new Subject();

    /**
     * Сервис для выполнения административных задач.
     */
    private readonly _adminService: AdminService;

    //endregion
    //region Ctor

    /**
     * <p>Конструктор компонента создания диалога перемещения документов в другую папку.</p>
     *
     * @param store Сервис для управления и доступа к состоянию приложения.
     * @param adminService Сервис для выполнения административных задач.
     * @param data Данные для диалога.
     * @param translateService Сервис для работы с i18n-сообщениями.
     */
    constructor(
        store: Store<RootState>,
        adminService: AdminService,
        @Inject(MAT_DIALOG_DATA) data: {
            documents: EnteraDocument[],
            filter: FilterAndSortAndSearchState,
            excludedDocuments: EnteraDocument[],
            user: CurrentUserInfo,
        },
        translateService: TranslateService,
    ) {
        this._store = store;
        this._translateService = translateService;
        this.movingDocs$ = this._store.select(moveDocumentsDlgSelectors.documentsMoveToAnotherSpaceSelector);
        this.okState$ = this._store.select(moveDocumentsDlgSelectors.documentsMoveToAnotherSpaceSuccessSelector);
        this.error$ = this._store.select(moveDocumentsDlgSelectors.documentsMoveToAnotherSpaceFailedSelector);

        this.error$.pipe(takeUntil(this._globalUnsubscribe$))
            .subscribe((error: ApiResponse): void => {

                this.error = error;
            });

        this._adminService = adminService;

        this.selectedDocuments = data.documents;

        this.currentUser = data.user;
        this.filter = data.filter;
        this.excludedDocuments = data.excludedDocuments;

        this.adminView = this.currentUser.permissions.some((perm: string) =>
            perm === AdminPermissionItem.MOVE_DOCUMENTS.value
        );

        this.spaceList$ = of([]).pipe(
            switchMap(() => {

                if (this.adminView) {

                    return this.clientSelectControl.valueChanges
                        .pipe(
                            tap(() => {

                                this.spaceSelectControl.reset();
                                this.userSelectControl.reset();
                            }),
                            map((client: ClientAdminInfo) => (client && client.spaces) || []),
                        );
                }
                else {

                    return of((this.currentUser && this.currentUser.spaces) || []);
                }
            }),
            startWith([]),
            shareReplay(1),
        );

        this.userList$ = this.spaceSelectControl.valueChanges
            .pipe(
                tap(() => {

                    this.userSelectControl.reset();
                }),
                map((space: Space): UserEmail[] => (space && space.userList) || []),
                map((userList: UserEmail[]): Option[] =>
                    userList.map((user: UserEmail) => ({ id: user.id, name: user.email})),
                ),
                startWith([]),
                shareReplay(1),
            );
    }

    //endregion
    //region Hooks

    /**
     * Логика, выполняющаяся при инициализации компоненты.
     */
    ngOnInit() {

        this._store.dispatch(moveDocumentsDlgActions.moveDocumentsDlgInitialize());
    }

    /**
     * Логика, выполняющаяся при уничтожении компоненты.
     */
    ngOnDestroy() {

        this._resetControls();
        this._globalUnsubscribe$.complete();
    }

    //endregion
    //region Getters

    /**
     * Возвращает валидность всех контролов.
     */
    get validControls(): boolean {

        return this.clientSelectControl.valid && this.spaceSelectControl.valid && this.userSelectControl.valid;
    }

    /**
     * Возвращает текст ошибки переноса документа для отображения в диалоге.
     */
    get errorText(): string {

        return this._translateService.instant(
            "spaces.moveDocumentDialog.errorText",
            {
                id: (this.error && this.error.errorId) || "-",
                code: (this.error && this.error.errorCode) || "-",
                message: (this.error && this.error.errorMessage) || "-",
            },
        );
    }

    //endregion
    //region Public

    /**
     * Поиск пользователя.
     *
     * @param search Строка поиска.
     * @param page Страница списка.
     */
    findClientsByEmailFn = (search: string, page: number): Observable<Option[]> => {

        return this._adminService.findClientsByEmail({ search, page })
            .pipe(
                map((clients: ClientAdminInfo[]): Option[] => clients as Option[]),
                catchError((error: ApiResponse) => {

                    return throwError(error);
                }),
            );
    }

    /**
     * Возвращает отформатированное предстление email-а клиента.
     *
     * Если есть email, по которому производился поиск, то возвращает его, иначе email создателя пространства
     * документов. Если и его нет, то возвращает строку "N/A".
     *
     * @param option Информация о клиенте для администратора.
     *
     * @return Отформатированное представление email-а клиента.
     */
    formatClientEmail(option: Option): string {

        const client: ClientAdminInfo = option as ClientAdminInfo;

        return client.searchEmail || client.creatorEmail || "N/A";
    }


    //endregion
    //region Events

    /**
     * Обработка события нажатия по кнопке "Перенести". Испускает событие перемещения документа.
     */
    moveDocuments(): void {

        const props: MoveDocumentsActionProps = {
            filter: this.filter,
            excludedDocuments: this.excludedDocuments,
            toSpaceId: this.spaceSelectControl.value ? this.spaceSelectControl.value.id : null,
            documents: this.selectedDocuments,
            creatorId: this.userSelectControl.value ? this.userSelectControl.value.id : this.currentUser.id,
            initiator: this.currentUser,
        };

        this._store.dispatch(moveDocumentsDlgActions.moveDocumentsToAnotherSpace(props));
    }

    //endregion
    //region Private

    /**
     * Сбрасывает состояния контролов.
     */
    private _resetControls(): void {

        this.userSelectControl.reset();
        this.spaceSelectControl.reset();
        this.clientSelectControl.reset();
    }

    //endregion
}
