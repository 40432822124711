import { ChangeDetectionStrategy } from "@angular/core";
import { ChangeDetectorRef } from "@angular/core";
import { Component } from "@angular/core";
import { ElementRef } from "@angular/core";
import { HostListener } from "@angular/core";
import { Input } from "@angular/core";
import { OnInit } from "@angular/core";
import { ViewChild } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Output } from "@angular/core";
import { SafeStyle } from "@angular/platform-browser";
import { DomSanitizer } from "@angular/platform-browser";

/**
 * Обобщённый компонент для просмотра заданного списка страниц.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'pages-viewer',
    templateUrl: './pages-viewer.component.html',
    styleUrls: ['./pages-viewer.component.scss']
})
export class PagesViewerComponent implements OnInit {
    //region Inputs

    /**
     * Входящие данные - масштаб изображения страницы.
     */
    @Input()
    set scale(value: number) {

        if (this._scale !== value) {

            this._scale = value;
            this.scaleChange.emit(this._scale);
        }
    }

    /**
     * Входящие данные - угол поворота изображения страницы.
     */
    @Input()
    set rotateAngle(value: number) {

        if (this._rotateAngle !== value) {

            this._rotateAngle = value;
            this.rotateAngleChange.emit(this._rotateAngle);
        }
    }

    /**
     * Входящие данные - смещение изображения страницы влево.
     */
    @Input()
    set pageLeft(value: number) {

        if (this._pageLeft !== value) {

            this._pageLeft = value;
            this.pageLeftChange.emit(this._pageLeft);
        }
    }

    /**
     * Входящие данные - смещение изображения страницы вверх.
     */
    @Input()
    set pageTop(value: number) {

        if (this._pageTop !== value) {

            this._pageTop = value;
            this.pageTopChange.emit(this._pageTop);
        }
    }

    /**
     * Входящие данные - список URL-ов страниц, которые нужно отобразить.
     */
    @Input()
    pageUrls: string[] = [];

    /**
     * Входящие данные - текущая отображаемая страница.
     */
    @Input()
    set currentPage(value: number) {

        if (value >= 1 && value <= this.pageUrls.length) {

            this._currentPage = value;
            this.currentPageChange.emit(this.currentPage);
        }
    }

    /**
     * Входящие данные - функция, которая даёт разрешение на переход между страницами.
     */
    @Input()
    isPageChangeValid: (page: number) => boolean = () => true;

    /**
     * Входящие данные - массив страниц, которые относятся к документу.
     */
    @Input()
    documentPages: number[] = [];

    /**
     * Входящие данные - показать навигацию по страницам в рамках документа?
     */
    @Input()
    showLocalNavigation: boolean = false;

    /**
     * Отобразить кнопки навигации наверху компонента?
     */
    @Input()
    placeNavigationButtonsTop: boolean = false;

    /**
     * Просмотрщик скана используется в режиме матчинга?
     *
     * Влияет на расположение и стили кнопок в просмотрщике.
     */
    @Input()
    matchingMode: boolean = false;

    /**
     * Входящие данные - заголовок просматриваемого документа.
     */
    @Input()
    documentHeader: string;

    //endregion
    //region Outputs

    /**
     * Исходящее событие - изменение текущей страницы.
     */
    @Output()
    currentPageChange: EventEmitter<number> = new EventEmitter();

    /**
     * Исходящее событие - изменение масштаба изображения страницы.
     */
    @Output()
    scaleChange: EventEmitter<number> = new EventEmitter();

    /**
     * Исходящее событие - изменение поворота изображения страницы.
     */
    @Output()
    rotateAngleChange: EventEmitter<number> = new EventEmitter();

    /**
     * Исходящее событие - изменение смещения изображения страницы вверх.
     */
    @Output()
    pageTopChange: EventEmitter<number> = new EventEmitter();

    /**
     * Исходящее событие - изменение смещения изображения страницы влево.
     */
    @Output()
    pageLeftChange: EventEmitter<number> = new EventEmitter();

    /**
     * Исходящее событие требования закрытия просмотрщика скана документа.
     */
    @Output()
    closeScanViewer: EventEmitter<void> = new EventEmitter();

    //endregion
    //region Constant

    /**
     * Минимальный масштаб изображения страницы.
     *
     * Добавлена 1 сотая, чтобы избежать случая, когда масштаб станет 0.1 с какой-то очень маленькой делтой и будет
     * больше, чем 0.1.
     */
    static readonly MIN_SCALE: number = 0.11;

    /**
     * Максимальный масштаб изображения страницы.
     */
    static readonly MAX_SCALE: number = 4;

    /**
     * Шаг увеличения/уменьшения масштаба изображения.
     */
    static readonly SCALE_DELTA: number = 0.1;

    /**
     * Увеличенный шаг увеличения/уменьшения масштаба изображения, когда масштаб стал большим.
     */
    static readonly BIG_SCALE_DELTA: number = 0.5;

    //endregion
    //region Fields

    //region Public

    /**
     * Флаг того, что выполняется загрузка изображения.
     */
    imageLoading: boolean = true;

    /**
     * Флаг того, что компонента отображается на весь экран.
     */
    fullscreen: boolean;

    //endregion
    //region Private

    /**
     * Текущая отображаемая страница.
     *
     * @private
     */
    private _currentPage: number = 1;

    /**
     * Смещение изображения страницы влево. Координата смещения X.
     *
     * @private
     */
    private _pageLeft: number = 0;

    /**
     * Смещение изображения страницы вверх. Координата смещения Y.
     *
     * @private
     */
    private _pageTop: number = 0;

    /**
     * Масштаб просмотра.
     *
     * @private
     */
    private _scale: number = 0.4;

    /**
     * Угол поворота изображения страницы (0, 90, 180, 270).
     *
     * @private
     */
    private _rotateAngle: number = 0;

    /**
     * Флаг выполнения перемещения изображения страницы внутри viewport'а.
     *
     * @private
     */
    private _pageMoving: boolean = false;

    /**
     * Ссылка на DOM-элемент, который является viewport'ом для изображения страницы.
     *
     * @private
     */
    @ViewChild('viewport')
    private _viewportRef: ElementRef<HTMLElement>;

    /**
     * Ссылка на DOM-элемент, который является изображением страницы.
     *
     * @private
     */
    @ViewChild('image')
    private _imageRef: ElementRef<HTMLImageElement>;

    /**
     * Ссылка на DOM-элемент, который является просмотрщиком страниц.
     */
    @ViewChild("pagesViewer")
    private _pagesViewerRef: ElementRef<HTMLElement>;

    /**
     * Сервис для управления запуском определения angular'ом изменений данных, произошедших в компоненте.
     *
     * @private
     */
    private _cd: ChangeDetectorRef;

    /**
     * Сервис для обхода механизма безопасности HTML/CSS/JS в Angular'е.
     */
    private _sanitizer: DomSanitizer;

    //endregion

    //endregion
    //region Ctor

    constructor(cd: ChangeDetectorRef, sanitizer: DomSanitizer) {

        this.fullscreen = false;
        this._cd = cd;
        this._sanitizer = sanitizer;
    }

    //endregion
    //region Hooks

    ngOnInit(): void { }

    //endregion
    //region Getters and Setters

    /**
     * Масштаб просмотра.
     */
    get scale(): number {

        return this._scale;
    }

    /**
     * Угол поворота изображения страницы.
     */
    get rotateAngle(): number {

        return this._rotateAngle;
    }

    /**
     * Смещение изображения страницы влево. Координата смещения X.
     */
    get pageLeft(): number {

        return this._pageLeft;
    }

    /**
     * Смещение изображения страницы вверх. Координата смещения Y.
     */
    get pageTop(): number {

        return this._pageTop;
    }

    /**
     * DOM-элемент, который является viewport'ом для изображения страницы.
     */
    get viewport(): HTMLElement {

        return (this._viewportRef ? this._viewportRef.nativeElement : null);
    }

    /**
     * Отступ viewport'а от левой границы страницы.
     */
    get viewportPageX(): number {

        return (this.viewport ? this.viewport.getBoundingClientRect().left : 0);
    }

    /**
     * Отступ viewport'а от верхней границы страницы.
     */
    get viewportPageY(): number {

        return (this.viewport ? this.viewport.getBoundingClientRect().top : 0);
    }

    /**
     * Ширина viewport'а страницы.
     */
    get viewportWidth(): number {

        return (this.viewport ? this.viewport.clientWidth : 0);
    }

    /**
     * Высота viewport'а страницы.
     */
    get viewportHeight(): number {

        return (this.viewport ? this.viewport.clientHeight : 0);
    }

    /***
     * DOM-элемент изображения текущей страницы.
     */
    get currentImage(): HTMLImageElement {

        return (this._imageRef ? this._imageRef.nativeElement : null);
    }

    /**
     * Ширина изображения текущей страницы с учётом масштаба.
     */
    get imageWidth(): number {

        return this.scale * (this.currentImage ? this.currentImage.clientWidth : 0);
    }

    /**
     * Реальная ширина изображения текущей страницы.
     */
    get imageRealWidth(): number {

        return (this.currentImage ? this.currentImage.clientWidth : 0);
    }

    /**
     * Высота изображения текущей страницы с учётом масштаба.
     */
    get imageHeight(): number {

        return this.scale * (this.currentImage ? this.currentImage.clientHeight : 0);
    }

    /**
     * Реальная высота изображения текущей страницы.
     */
    get imageRealHeight(): number {

        return (this.currentImage ? this.currentImage.clientHeight : 0);
    }

    /**
     * Минимальная координата смещения изображения по X (т.е. смещения влево).
     */
    get minLeft(): number {

        let minLeft: number;

        switch (this._rotateAngle) {

            case 0:
                minLeft = Math.min(this.viewportWidth - this.imageWidth, 0);
                break;

            case 90:
                minLeft = this.imageHeight
                    - Math.max(this.imageHeight - this.viewportWidth, 0);
                break;

            case 180:
                minLeft = this.imageWidth
                    - Math.max(this.imageWidth - this.viewportWidth, 0);
                break;

            case 270:
                minLeft = Math.min(this.viewportWidth - this.imageHeight, 0);
                break;
        }

        return minLeft;
    }

    /**
     * Максимальная координата смещения изображения по X (т.е. смещения вправо).
     */
    get maxLeft(): number {

        let maxLeft: number;

        switch (this._rotateAngle) {

            case 0:
                maxLeft = 0;
                break;

            case 90:
                maxLeft = this.imageHeight;
                break;

            case 180:
                maxLeft = this.imageWidth;
                break;

            case 270:
                maxLeft = 0;
                break;
        }

        return maxLeft;
    }

    /**
     * Минимальная координата смещения изображения по Y (т.е. смещения вверх).
     */
    get minTop(): number {

        let minTop: number;

        switch (this._rotateAngle) {

            case 0:
                minTop = Math.min(this.viewportHeight - this.imageHeight, 0);
                break;

            case 90:
                minTop = Math.min(this.viewportHeight - this.imageWidth, 0);
                break;

            case 180:
                minTop = this.imageHeight
                    - Math.max(this.imageHeight - this.viewportHeight, 0);
                break;

            case 270:
                minTop = this.imageWidth
                    - Math.max(this.imageWidth - this.viewportHeight, 0);
                break;
        }

        return minTop;
    }

    /**
     * Максимальная координата смещения изображения по Y (т.е. смещения вниз).
     */
    get maxTop(): number {

        let maxTop: number;

        switch (this._rotateAngle) {

            case 0:
                maxTop = 0;
                break;

            case 90:
                maxTop = 0;
                break;

            case 180:
                maxTop = this.imageHeight;
                break;

            case 270:
                maxTop = this.imageWidth;
                break;
        }

        return maxTop;
    }

    /**
     * Текущая отображаемая страница.
     */
    get currentPage(): number {

        return this._currentPage;
    }

    /**
     * Шаг увеличения/уменьшения масштаба страницы.
     */
    get scaleDelta(): number {

        return (this.scale >= 1.5
            ? PagesViewerComponent.BIG_SCALE_DELTA
            : PagesViewerComponent.SCALE_DELTA
        );
    }

    /**
     * URL изображения текущей страницы.
     */
    get currentPageUrl(): string {

        return this.pageUrls[this.currentPage - 1];
    }

    /**
     * Номер текущей страницы в рамках документа.
     */
    get documentPage(): number {

        let documentPage: number = null;

        if (this.isDocumentPage(this.currentPage)) {

            documentPage = this.documentPages.indexOf(this.currentPage) + 1;
        }

        return documentPage;
    }

    /**
     * CSS-стиль изображения текущей страницы для её масштаба и поворота.
     *
     * Подобный стиль нельзя разместить прямо в шаблоне, т.к. Angular ругается на него как на небезопасный. Поэтому
     * требуется вручную указывать, что этот стиль безопасный.
     */
    get imageTransformStyle(): SafeStyle {

        return this._sanitizer.bypassSecurityTrustStyle(
            'scale(' + this.scale + ') rotate(' + this.rotateAngle + 'deg)'
        );
    }

    //endregion
    //region Events

    /**
     * Обрабатывает событие изменения отображения просмотрщика страниц во весь экран.
     *
     * @param event Событие изменения отображения просмотрщика страниц во весь экран.
     */
    @HostListener("fullscreenchange", ["$event"])
    handleFullscreenchange(event: Event): void {

        this.fullscreen = !this.fullscreen;
    }

    /**
     * Обрабатывает нажатие на кнопку переключения отображения просмотрщика страниц во весь экран.
     */
    handleFullscreenButtonClick(): void {

        if (this.fullscreen) {

            (document as Document).exitFullscreen();
        }
        else if (!!this._pagesViewerRef && !!this._pagesViewerRef.nativeElement) {

            this._pagesViewerRef.nativeElement.requestFullscreen();
        }
    }

    /**
     * Выполняет поворот изображения страницы.
     */
    rotate(): void {

        this.rotateAngle = (this._rotateAngle + 90) % 360;

        switch (this.rotateAngle) {

            case 0:
                this.pageTop = 0;
                this.pageLeft = 0;
                break;

            case 90:
                this.pageTop = 0;
                this.pageLeft = this.imageHeight;
                break;

            case 180:
                this.pageTop = this.imageHeight;
                this.pageLeft = this.imageWidth;
                break;

            case 270:
                this.pageTop = this.imageWidth;
                this.pageLeft = 0;
                break;
        }
    }

    /**
     * Увеличивает масштаб изображения относительно заданной точки на странице.
     *
     * @param pagePointX Координата X точки.
     * @param pagePointY Координата Y точки.
     */
    increaseScale(pagePointX: number = -1, pagePointY: number = -1): void {

        if (this.scale < PagesViewerComponent.MAX_SCALE) {

            if (pagePointX !== -1) {

                const zoomPointX = pagePointX - this.viewportPageX - this.pageLeft;
                const scaledZoomPointX = zoomPointX * (1 + this.scaleDelta / this.scale);
                const viewportOffset = zoomPointX - scaledZoomPointX;
                this._changeX(viewportOffset);
            }

            if (pagePointY !== -1) {

                const zoomPointY = pagePointY - this.viewportPageY - this.pageTop;
                const scaledZoomPointY = zoomPointY * (1 + this.scaleDelta / this.scale);
                const viewportOffset = zoomPointY - scaledZoomPointY;
                this._changeY(viewportOffset);
            }

            this.scale += this.scaleDelta;
            this.correctImagePosition();
        }
    }

    /**
     * Уменьшает масштаб изображения относительно заданной точки на странице.
     *
     * @param pagePointX Координата X точки.
     * @param pagePointY Координата Y точки.
     */
    decreaseScale(pagePointX: number = -1, pagePointY: number = -1): void {

        if (this.scale > PagesViewerComponent.MIN_SCALE) {

            if (pagePointX !== -1) {

                const zoomPointX = pagePointX - this.viewportPageX - this.pageLeft;
                const scaledZoomPointX = zoomPointX * (1 - this.scaleDelta / this.scale);
                const viewportOffset = zoomPointX - scaledZoomPointX;
                this._changeX(viewportOffset);
            }

            if (pagePointY !== -1) {

                const zoomPointY = pagePointY - this.viewportPageY - this.pageTop;
                const scaledZoomPointY = zoomPointY * (1 - this.scaleDelta / this.scale);
                const viewportOffset = zoomPointY - scaledZoomPointY;
                this._changeY(viewportOffset);
            }

            this.scale -= this.scaleDelta;
            this.correctImagePosition();
        }
    }

    /**
     * Показывает изображение следующей страницы.
     */
    nextPage(): void {

        if (this.currentPage < this.pageUrls.length && this.isPageChangeValid(this.currentPage + 1)) {

            this.imageLoading = true;
            this.currentPage++;

            // При перемещении по страницам отображаем левый верхний угол.
            this.pageLeft = this.maxLeft;
            this.pageTop = this.maxTop;

            this.correctImagePosition();
        }
    }

    /**
     * Показывает изображение предыдущей страницы.
     */
    prevPage() {

        if (this.currentPage > 1 && this.isPageChangeValid(this.currentPage - 1)) {

            this.imageLoading = true;
            this.currentPage--;

            // При перемещении по страницам отображаем левый верхний угол.
            this.pageLeft = this.maxLeft;
            this.pageTop = this.maxTop;

            this.correctImagePosition();
        }
    }

    //endregion
    //region Public

    /**
     * Заданная страница относится к документу?
     *
     * @param page Страница.
     *
     * @return Да/Нет.
     */
    isDocumentPage(page: number): boolean {

        return (this.documentPages && this.documentPages.indexOf(page) !== -1);
    }

    /**
     * Защитная логика, чтобы позиция изображения страницы не вышла за границы viewport'а.
     */
    correctImagePosition(): void {

        setTimeout(() => {

            this._changeX();
            this._changeY();
            this._cd.markForCheck();
        });
    }

    //endregion
    //region Events

    /**
     * Обработчик события выполнения scroll'а колесом прокрутки.
     *
     * В ответ на это событие выполняется увеличение или уменьшение масштаба относительно точки, в которой
     * расположен курсор мыши.
     *
     * @param event Событие scroll'а колесом прокрутки.
     */
    @HostListener('wheel', ['$event'])
    onWheelScroll(event: WheelEvent): void {

        event.preventDefault();

        let delta = event.deltaY || event.deltaMode;
        if (delta < 0) {

            this.increaseScale(event.pageX, event.pageY);
        }
        else {

            this.decreaseScale(event.pageX, event.pageY);
        }
    }

    /**
     * Обработка события нажатия левой кнопкой мыши на изображение страницы для начала её перемещения.
     */
    onMousedown(): void {

        this._pageMoving = true;
    }

    /**
     * Обработка события отпускания левой кнопки мыши.
     *
     * Останавливает перемещение изображение страницы.
     */
    @HostListener('document:mouseup')
    onMouseUp() {

        this._pageMoving = false;
    }

    /**
     * Обработка события перемещения курсора мыши.
     *
     * Если левая кнопка мыши зажата, то выполняется перемещение изображения страницы внутри viewport'а.
     */
    @HostListener('document:mousemove', ['$event'])
    onMouseMove($event: MouseEvent): void {

        if (this._pageMoving) {

            this._changeX($event.movementX);
            this._changeY($event.movementY);
        }
    }

    /**
     * Обработчик события успешной загрузки изображения страницы.
     */
    imageLoadHandler(): void {

        this.imageLoading = false;

        if (this.matchingMode) {

            this.setScaleByImageWidth();
        }
    }

    //endregion
    //region Private

    /**
     * Измение координаты X изображения страницы с ограничением по отрвыу от краев viewport'а.
     *
     * @param delta Смещение.
     */
    private _changeX(delta: number = 0) {

        let newX = this.pageLeft + delta;

        if (newX < this.minLeft) {

            this.pageLeft = this.minLeft;
        }
        else if (newX > this.maxLeft) {

            this.pageLeft = this.maxLeft;
        }
        else {

            this.pageLeft = newX;
        }
    }

    /**
     * Измение координаты Y изображения страницы с ограничением по отрвыу от краев viewport'а.
     *
     * @param delta Смещение.
     */
    private _changeY(delta: number = 0) {

        let newY = this.pageTop + delta;

        if (newY < this.minTop) {

            this.pageTop = this.minTop;
        }
        else if (newY > this.maxTop) {

            this.pageTop = this.maxTop;
        }
        else {

            this.pageTop = newY;
        }
    }

    /**
     * Устанавливает просмотрщику такой масштаб, чтобы изображение полностью помещалось по ширине.
     */
    private setScaleByImageWidth(): void {

        const widthRatio = this.viewportWidth / this.imageWidth;
        this._scale = this._scale * (widthRatio * 0.95);
    }

    //endregion
}
