import { OnDestroy } from "@angular/core";
import { Component } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";

import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models/index";
import { RequestState } from "src/app/common/models/request-state";
import { RootState } from "src/app/root/store/index";
import { MatchSettings } from "src/app/spaces/modules/document-matching/models/match-settings";
import { matchSettingsActions } from "src/app/spaces/modules/document-matching/store/actions/match-settings.actions";
import { matchSettingsSelector } from "src/app/spaces/modules/document-matching/store/selectors/match-settings.selector";

/**
 * Компонент диалога настроек сопоставления.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'match-settings-dlg',
    templateUrl: './match-settings-dlg.component.html',
    styleUrls: ['./match-settings-dlg.component.scss'],
})
export class MatchSettingsDlgComponent implements OnDestroy {
    //region Fields

    /**
     * Состояние запросов настроек распознавания.
     */
    readonly requestStateType$: Observable<RequestState["type"]>;

    /**
     * Форм группа настроек сопоставления.
     */
    readonly matchSettingsGroup: FormGroup;

    /**
     * Ошибка при запросе.
     */
    error: ApiResponse;

    /**
     * ID интеграции.
     */
    private readonly _integrationId: string;

    /**
     * Настройки сопоставления.
     */
    private _matchSettings: MatchSettings;

    /**
     * Объект глобальной отписки.
     */
    private readonly _globalUnsubscribe$: Subject<void> = new Subject();

    //endregion
    //region Ctor

    /**
     * Конструктор компонента.
     *
     * @param _store Состояние приложения.
     * @param _dialogRef Компонент диалога.
     * @param data Данные компоненты.
     */
    constructor(
        private _store: Store<RootState>,
        private _dialogRef: MatDialogRef<MatchSettingsDlgComponent>,
        @Inject(MAT_DIALOG_DATA) data: { integrationId: string },
    ) {
        this._store.dispatch(matchSettingsActions.updateInit());
        this.matchSettingsGroup = new FormGroup({
            "showGoodCodeColumn": new FormControl(),
        });

        this._integrationId = data.integrationId;

        this._store.select(matchSettingsSelector.matchSettings)
            .pipe(takeUntil(this._globalUnsubscribe$))
            .subscribe((matchSettings: MatchSettings) => {

                this.matchSettingsGroup.patchValue(matchSettings || {});
                this._matchSettings = matchSettings;
            });

        this.requestStateType$ =  this._store.select(matchSettingsSelector.updateRequestState)
            .pipe(
                takeUntil(this._globalUnsubscribe$),
                map(requestState => requestState.type),
            );

        this._store.select(matchSettingsSelector.updateRequestState)
            .pipe(takeUntil(this._globalUnsubscribe$))
            .subscribe((requestState: any) => this.error = requestState.error);
    }

    //endregion
    //region Hooks

    ngOnDestroy() {

        this._globalUnsubscribe$.next();
        this._globalUnsubscribe$.complete();
    }

    //endregion
    //region Getters

    /**
     * Изменились ли настройки?
     */
    get isSettingsChanged() {

        return !this._isSettingsChanged(this.matchSettingsGroup.value);
    }

    //endregion
    //region Events

    /**
     * Обработчик события закрытия диалога.
     */
    cancelBtnHandler() {

        this._dialogRef.close();
    }

    /**
     * Обработчик события нажатия кнопки сохранения настроек.
     *
     * Если произошли изменения, то пытается их сохранить.
     *
     * При успешном сохранении, либо же если состояние инициализированное - то закрывает диалог.
     *
     * При наличии предыдущей ошибки - дает закрыть диалог при нажатии кнопки.
     */
    okBtnHandler() {

        let previousError: boolean = !!this.error;

        if (!previousError && this._isSettingsChanged(this.matchSettingsGroup.value)) {

            this._store.dispatch(matchSettingsActions.update({
                integrationId: this._integrationId,
                matchSettings: this.matchSettingsGroup.value,
            }));
        }

        this.requestStateType$.pipe(
            takeUntil(this._globalUnsubscribe$),
            filter(requestState =>
                requestState === "success"
                || requestState === "initial"
                || (requestState === "fail" && previousError)
            ),
        )
            .subscribe(() => this._dialogRef.close());
    }

    //endregion
    //region Private

    /**
     * Возвращает флаг того, отличаются ли значения настроек в хранилище с настройками из компоненты.
     *
     * @param value Значение настроек сопоставления из компоненты.
     *
     * @return Да/нет.
     */
    private _isSettingsChanged(value: any): boolean {

        if (!this._matchSettings && !value) {

            return true;
        }
        else if (this._matchSettings && value) {

            return this._matchSettings.showGoodCodeColumn !== value.showGoodCodeColumn
                ;
        }

        return this._matchSettings !== value;
    }

    //endregion
}
