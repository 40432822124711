import { environment } from "src/environments/environment";

/**
 * Утилиты для работы с URL.
 */
export class UrlUtils {
    //region Constants

    /**
     * Начало URL'ов API методов.
     */
    static readonly API_PREFIX: string = "/api";

    /**
     * Начало URL'ов API методов версии 1.
     */
    static readonly API_V1: string = `${UrlUtils.API_PREFIX}/v1`;

    //endregion
    //region Public static

    //region Common

    /**
     * Формирует и возвращает query-string на основе заданных параметров.
     *
     * @param queryParams Параметры.
     *
     * @return Query-string на основе заданных параметров.
     */
    static getQueryString(queryParams: { [key: string]: string | string[] }): string {

        let query: string = Object.keys(queryParams)
            .map((key: string): string => UrlUtils.formatParams(queryParams, key))
            .reduce((q: string, param: string): string => q + param + "&", "?")
            .replace(/\+/g, "%2B")
            .replace(/&?/, "");

        if (query === "?") {

            query = "";
        }

        return query;
    }

    /**
     * Возвращает параметр и его значение в нужном формате:
     * - Если значение параметра - массив, возвращает строку в формате "параметр=значение1&параметр=значение2...".
     * - Иначе возвращает строку в формате "параметр=значение"
     *
     * @param queryParams Параметры.
     * @param key Ключ текущего параметра.
     *
     * @return Параметр и его значение в нужном формате.
     */
    static formatParams(queryParams: { [key: string]: string | string[] }, key: string): string {

        if (queryParams[key] instanceof Array) {

            return (queryParams[key] as string[])
                .reduce((params: string, value: string, index: number): string =>
                    params + (index > 0 ? "&" : "") + key + "=" + value,
                    "",
                );
        }
        else {

            return key + "=" + queryParams[key];
        }
    }

    //endregion
    //region Spaces

    //region API

    /**
     * Возвращает URL API метода версии 1 для приглашения пользователей в пространства документов.
     *
     * @return URL API метода версии 1 для приглашения пользователей в пространства документов.
     */
    static inviteApiUrl(): string {

        return `${UrlUtils.API_V1}/invite`;
    }

    /**
     * Возвращает URL API метода версии 1 для добавления доверенных почт в пространства документов.
     *
     * @return URL API метода версии 1 для добавления доверенных почт в пространства документов.
     */
    static addTrustedEmailsApiUrl(): string {

        return `${UrlUtils.API_V1}/spaceEmails/batch`;
    }

    /**
     * Возвращает URL API метода версии 1 для отправки пользователем ответа на сообщение.
     *
     * @param messageId id сообщения, на которое отвечает пользователь.
     *
     * @return URL API метода версии 1 для отправки пользователем ответа на сообщение.
     */
    static BroadcastMessageResponseUrl(messageId: string): string {

        return `${UrlUtils.API_V1}/broadcastMessages/${messageId}/responses`;
    }

    //endregion
    //region UI

    /**
     * Возвращает URL страницы выбора пространства документов пользователя в виде пригодном для использования
     * в routing модуле.
     *
     * @return URL страницы выбора пространства документов пользователя в виде пригодном для использования в
     * routing модуле.
     */
    static spacesPagePath(): string {

        return "spaces";
    }

    /**
     * Возвращает URL страницы настроек интеграций выбранного пространства документов пользователя.
     *
     * @param spaceId ID пространства документов пользователя.
     *
     * @return URL страницы настроек интеграций выбранного пространства документов пользователя.
     */
    static spaceIntegrationSettingsPageUrl(spaceId: string): string {

        return `/${UrlUtils.spacesPagePath()}/${spaceId}/settings/integrations`;
    }

    /**
     * Возвращает URL страницы настроек уведомлений выбранного пространства документов пользователя.
     *
     * @param spaceId ID пространства документов пользователя.
     *
     * @return URL страницы настроек уведомлений выбранного пространства документов пользователя.
     */
    static spaceNotificationPageUrl(spaceId: string): string {

        return `/${UrlUtils.spacesPagePath()}/${spaceId}/settings/notifications`;
    }

    //endregion

    //endregion
    //region Documents

    //region API

    /**
     * Возвращает префикс URL API методов, связанных с документами.
     *
     * @return Префикс URL API методов, связанных с документами.
     */
    static documentsApiPrefix(): string {

        return `${UrlUtils.API_V1}/documents`;
    }

    /**
     * Возвращает префикс URL API методов, связанных с заданным документом.
     *
     * @param documentId ID документа.
     *
     * @return Префикс URL API методов, связанных с заданным документом.
     */
    static documentApiPrefix(documentId: string): string {

        return `${UrlUtils.documentsApiPrefix()}/${documentId}`;
    }

    /**
     * Возвращает URL для скачивания исходного файла документа.
     *
     * Это тот файл (или файлы), который был отправлен на распознавание.
     *
     * @param documentId ID документа.
     *
     * @return URL для скачивания исходного файла документа.
     */
    static downloadDocumentOriginalFilesUrl(documentId: string): string {

        return `${UrlUtils.documentApiPrefix(documentId)}/files`;
    }

    /**
     * Возвращает URL для скачивания печатной формы документа.
     *
     * @param documentId ID документа.
     *
     * @return URL для скачивания печатной формы документа.
     */
    static downloadDocumentPrintFormUrl(documentId: string): string {

        return `${UrlUtils.documentApiPrefix(documentId)}/printForm`;
    }

    /**
     * Возвращает URL для скачивания ФНС XML файла для документа.
     *
     * @param documentId ID документа.
     *
     * @return URL для скачивания ФНС XML файла для документа.
     */
    static downloadDocumentFnsXmlUrl(documentId: string): string {

        return `${UrlUtils.documentApiPrefix(documentId)}/fnsXml`;
    }

    //region UI

    /**
     * Возвращает URL страницы реестра всех документов выбранного пространства документов пользователя.
     *
     * @param spaceId ID пространства документов пользователя.
     *
     * @return URL страницы реестра всех документов выбранного пространства документов пользователя.
     */
    static documentsRegistryPageUrl(spaceId: string): string {

        return `/${UrlUtils.spacesPagePath()}/${spaceId}/documents`;
    }

    /**
     * Возвращает URL страницы реестра разпознаваемых документов выбранного пространства документов пользователя.
     *
     * @param spaceId ID пространства документов пользователя.
     *
     * @return URL страницы реестра разпознаваемых документов выбранного пространства документов пользователя.
     */
    static documentsRegistryRecognizingPageUrl(spaceId: string): string {

        return `/${UrlUtils.spacesPagePath()}/${spaceId}/documents/recognizing`;
    }

    /**
     * Возвращает URL страницы реестра распознанных документов выбранного пространства документов пользователя.
     *
     * @param spaceId ID пространства документов пользователя.
     *
     * @return URL страницы реестра распознанных документов выбранного пространства документов пользователя.
     */
    static completedDocumentsRegistryPageUrl(spaceId: string): string {

        return `${UrlUtils.documentsRegistryPageUrl(spaceId)}/completed`;
    }

    //endregion

    //endregion
    //region Mobile

    /**
     * Возвращает URL корневой страницы мобильного UI.
     */
    static mobilePageUrl(): string {

        return "/mobile";
    }

    //endregion
    //region Integrations

    /**
     * Возвращает префикс URL API методов, связанных с интеграциями.
     *
     * @return Префикс URL API методов, связанных с интеграциями.
     */
    static integrationsApiPrefix(): string {
        return `${UrlUtils.API_V1}/integrations`;
    }

    /**
     * Возвращает URL API метода для получения интеграции.
     *
     * @param integrationId ID интеграции.
     *
     * @return URL API метода для получения интеграции.
     */
    static integrationUrl(integrationId: string): string {

        return `${UrlUtils.integrationsApiPrefix()}/${integrationId}`;
    }

    /**
     * Возвращает URL API метода для отправки документов в стороннюю систему с сопоставлением.
     *
     * @param integrationId ID интеграции.
     *
     * @return URL API метода для отправки документов в стороннюю систему с сопоставлением.
     */
    static integrationMatchUrl(integrationId: string): string {

        return `${UrlUtils.integrationsApiPrefix()}/${integrationId}/matches/batch`;
    }

    /**
     * Возвращает URL API метода для скачивания оригинала документа из СБИС
     *
     * @param integrationId ID интеграции.
     * 
     * @param documentId ID документа.
     * 
     * @param attachmentId ID вложения.
     *
     * @return URL API метода для скачивания оригинала документа из СБИС
     */
    static sbisDownloadOriginalFileUrl(integrationId: string, documentId: string, attachmentId: string): string {
    
        return `${UrlUtils.integrationsApiPrefix()}/${integrationId}/sbis/` + 
                `documents/${documentId}/attachments/${attachmentId}/file`;
    }

    /**
     * Возвращает URL API метода для отправки документов в стороннюю систему с сопоставлением.
     *
     * @param integrationId ID интеграции.
     *
     * @return URL API метода для отправки документов в стороннюю систему с сопоставлением.
     */
    static bankStatementMatchUrl(integrationId: string): string {

        return `${UrlUtils.integrationsApiPrefix()}/${integrationId}/bankStatements/match/batch`;
    }

    /**
     * Возвращает URL API метода для отправки документа в стороннюю систему.
     *
     * @return URL API метода для отправки документа в стороннюю систему.
     */
    static sendDocumentToIntegratedServiceUrl(): string {

        return `${UrlUtils.integrationsApiPrefix()}/send`;
    }

    /**
     * Возвращает URL API метода для получения списка организаций в Zoho.
     *
     * @param integrationId Id интеграции с Zoho.
     *
     * @return URL API метода для получения списка организаций в Zoho.
     */
    static getZohoBooksOrganizationsUrl(integrationId: string): string {

        return `${UrlUtils.integrationsApiPrefix()}/${integrationId}/zohobooks/organizations`;
    }

    /**
     * Возвращает URL API метода для завершения настройки интеграции с ZohoBooks.
     *
     * @param integrationId Id интеграции, которой устанавливаются настройки.
     *
     * @return URL API метода для завершения настройки интеграции с ZohoBooks.
     */
    static setZohoSettingsURL(integrationId: string): string {

        return `${UrlUtils.integrationsApiPrefix()}/${integrationId}/zohobooks`;
    }

    /**
     * Возвращает URL вебсокета NCALayer.
     *
     * @return URL вебсокета NCALayer.
     */
    static ncaLayerWebSocketUrl(): string {

        return environment.ncaLayerUrl;
    }

    //endregion
}
