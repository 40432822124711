import { HttpClientModule } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { MetaReducer } from "@ngrx/store";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

import { TranslateModule } from "@ngx-translate/core";
import { GtagModule } from "angular-gtag";

import { environment } from "../../../environments/environment";
import { EnteraCommonModule } from "../../common/common.module";

import { RootModuleRouting } from "../root.routing";
import { effects } from "../store";
import { reducers } from "../store";

const metaReducers: MetaReducer<any>[] = !environment.production
    ? []
    : [];

export const modules = [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RootModuleRouting,

    TranslateModule.forRoot(),

    StoreModule.forRoot(
        reducers,
        {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true
            }
        }
    ),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot(),
    environment.production ? [] : StoreDevtoolsModule.instrument(),

    EnteraCommonModule,
    GtagModule.forRoot(
        {
            trackingId: environment.gtag && environment.gtag.stream || null,
            trackPageviews: false,
            debug: environment.gtag && environment.gtag.enabled && environment.gtag.debug
        }
    )
];
