import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

/**
 * HTTP-клиент статичных изображений.
 */
@Injectable({
    providedIn: "root",
})
export class StaticImagesHttpClient {
    //region Fields

    /**
     * HTTP-клиент.
     */
    private readonly _httpClient: HttpClient;

    //endregion
    //region Ctor

    /**
     * Конструктор HTTP-клиента статичных изображений.
     *
     * @param httpClient HTTP-клиент.
     */
    constructor(httpClient: HttpClient) {

        this._httpClient = httpClient;
    }

    //endregion
    //region Public

    /**
     * Возвращает изображение, замещающее отсутствующий скан QR-кода чека.
     *
     * @return Изображение.
     */
    getReceiptPlaceholderImage(): Observable<Blob> {

        return this._getImage("/assets/images/no-image.png");
    }

    //endregion
    //region Private

    /**
     * Возвращает изображение по заданной ссылке.
     *
     * @param url Ссылка на изображение.
     *
     * @return Изображение.
     */
    private _getImage(url: string): Observable<Blob> {

        return this._httpClient.get(url, { responseType: "blob" });
    }

    //endregion
}

