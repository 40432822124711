import { Component } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Inject } from "@angular/core";

import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DlgInputSettingsData } from "src/app/common/models/dlg-input-settings-data";
import { TwoInputsDlgData } from "src/app/common/models/two-inputs-dlg-data";

/**
 * Компонент диалога с двумя инпутами.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'two-inputs-dlg',
    templateUrl: './two-inputs-dlg.component.html',
    styleUrls: ['./two-inputs-dlg.component.scss'],
})
export class TwoInputsDlgComponent {
    //region Fields

    /**
     * I18n-ключ заголовка диалога.
     */
    headerKey: string;

    /**
     * Параметры для подстановки в заголовок диалога.
     */
    headerParams: Object;

    /**
     * Пользовательский ввод первого инпута в диалоге.
     */
    firstInputValue: string = "";

    /**
     * Пользовательский ввод второго инпута в диалоге.
     */
    secondInputValue: string = "";

    /**
     * Настройки первого инпута.
     */
    firstInputData: DlgInputSettingsData;

    /**
     * Настройки вторрого инпута.
     */
    secondInputData: DlgInputSettingsData;

    //region
    //region Ctor

    /**
     * Конструктор компонента.
     *
     * @param _dialogRef Компонент диалога.
     * @param _data Данные для отображения в диалоге.
     */
    constructor(
        private _dialogRef: MatDialogRef<TwoInputsDlgComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: TwoInputsDlgData,
    ) {
        this.headerKey = this._data.headerKey;
        this.headerParams = this._data.headerParams || {};
        this.firstInputData = {
            textKey: this._data.firstInputData.textKey,
            multiline: this._data.firstInputData.multiline || false,
            required: this._data.firstInputData.required || false,
            inputType: this._data.firstInputData.inputType || "text",
        };
        this.secondInputData = {
            textKey: this._data.secondInputData.textKey,
            multiline: this._data.secondInputData.multiline || false,
            required: this._data.secondInputData.required || false,
            inputType: this._data.secondInputData.inputType || "text",
        };
    }

    //endregion
    //region Events

    /**
     * Обработчик события закрытия диалога.
     */
    closeBtnHandler() {

        this._dialogRef.close();

        if (this._data.cancelCallback) {

            this._data.cancelCallback();
        }
    }

    /**
     * Обработчик события подтверждения диалога.
     */
    confirmBtnHandler() {

        this._dialogRef.close({
            value1: this.firstInputValue,
            value2: this.secondInputValue,
        });

        if (this._data.confirmCallback) {

            this._data.confirmCallback(this.firstInputValue, this.secondInputValue);
        }
    }

    //endregion
}
