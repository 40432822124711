import { Injectable } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatDialog } from "@angular/material/dialog";
import { PhoneConfirmDlgComponent } from "src/app/common/components/phone-confirm-dlg/phone-confirm-dlg.component";
import { PurchaseDlgComponent } from "src/app/common/components/purchase-dlg/purchase-dlg.component";
import { SimpleAlertDlgComponent } from "src/app/common/components/simple-alert-dlg/simple-alert-dlg.component";
import { SimplePromptDlgComponent } from "src/app/common/components/simple-prompt-dlg/simple-prompt-dlg.component";
import { TwoInputsDlgComponent } from "src/app/common/components/two-inputs-dlg/two-inputs-dlg.component";
import { WhiteRedGreenButtonsDlgComponent } from "src/app/common/components/white-red-green-buttons-dlg/white-red-green-buttons-dlg.component";
import { Constants } from "src/app/common/models";
import { CurrentUserInfo } from "src/app/common/models";
import { DlgCreationData } from "src/app/common/models/dlg-creation-data";
import { SimpleDlgData } from "src/app/common/models/simple-dlg-data";
import { TwoInputsDlgData } from "src/app/common/models/two-inputs-dlg-data";
import { WhiteRedGreenButtonsDlgData } from "src/app/common/models/white-red-green-buttons-dlg-data";
import { BroadcastMessagesDlgComponent } from "src/app/root/containers/broadcast-messages-dlg/broadcast-messages-dlg.component";
import { SendToExternalSystemDlgComponent } from "src/app/root/containers/documents-external-system-dlg/send-to-external-system-dlg.component";
import { MoveDocumentsDlgComponent } from "src/app/root/containers/move-document-to-another-space-dlg/move-documents-dlg.component";
import { UserSpacesDlgComponent } from "src/app/root/containers/users-spaces-dlg/user-spaces-dlg.component";
import { UserWithSelectedDocumentsProps } from "src/app/spaces/modules/documents-registry/store/actions/props/user-with-selected-documents-props";
import { CreateLedgerDlgComponent } from "src/app/common/components/create-ledger-dlg/create-ledger-dlg.component";
import { CreateOrEditLedgerProps } from "src/app/spaces/modules/document-matching/store/actions/props/create-or-edit-ledger.props";

/**
 * Сервис для работы с диалогами.
 */
@Injectable({
    providedIn: 'root'
})
export class DlgService {
    //region Ctor

    constructor(
        private _dialog: MatDialog
    ) {
    }

    //endregion
    //region Public

    /**
     * Открывает диалог с заданным текстом.
     *
     * @param data I18n-ключ текста для отображения в диалоге.
     */
    openSimpleDlg(data: SimpleDlgData): MatDialogRef<SimpleAlertDlgComponent, any> {

        return this._dialog.open(SimpleAlertDlgComponent, {
            data,
            autoFocus: false,
            disableClose: data.disableClose,
            panelClass: ['entera-dlg', 'entera-simple-dlg']
        });
    }

    /**
     * Открывает диалог с заданным текстом и разноцветными кнопками.
     *
     * @param data I18n-ключ текста для отображения в диалоге.
     */
    openWhiteRedGreenButtonsDlg(data: WhiteRedGreenButtonsDlgData): MatDialogRef<WhiteRedGreenButtonsDlgComponent, any> {

        return this._dialog.open(WhiteRedGreenButtonsDlgComponent, {
            data,
            autoFocus: false,
            disableClose: data.disableClose,
            panelClass: ["entera-dlg", "entera-simple-dlg"]
        });
    }

    /**
     * Открывает диалог с заданным текстом и пользовательским вводом.
     *
     * @param data I18n-ключ текста для отображения в диалоге.
     */
    openSimplePromptDialog(data: SimpleDlgData): MatDialogRef<SimplePromptDlgComponent, any> {

        return this._dialog.open(SimplePromptDlgComponent, {
            data,
            autoFocus: true,
            disableClose: data.disableClose,
            panelClass: ['entera-dlg', 'entera-simple-dlg']
        });
    }

    /**
     * Открывает диалог с заданным текстом и двумя пользовательскими вводами.
     *
     * @param data Данные для отображения в диалоге.
     */
    openTwoInputsDialog(data: TwoInputsDlgData): MatDialogRef<TwoInputsDlgComponent, void> {

        return this._dialog.open(
            TwoInputsDlgComponent,
            {
                data,
                autoFocus: true,
                disableClose: data.disableClose,
                panelClass: ['entera-dlg', 'entera-simple-dlg']
            },
        );
    }

    /**
     * Открывает диалог заявки на покупку страниц.
     *
     * @param data I18n-ключ текста для отображения в диалоге.
     */
    openPurchaseDialog(data: SimpleDlgData): MatDialogRef<PurchaseDlgComponent, any> {

        return this._dialog.open(PurchaseDlgComponent, {
            data,
            autoFocus: true,
            disableClose: data.disableClose,
        });
    }

    /**
     * Открывает диалог подтверждения телефона пользователя.
     *
     * @param user Информация о пользователе системы.
     * @param callback Коллбэк для удачного подтверждения телефона.
     */
    openPhoneConfirmDialog(user: CurrentUserInfo, callback?: Function): MatDialogRef<PhoneConfirmDlgComponent, any> {

        return this._dialog.open(PhoneConfirmDlgComponent, {
            data: {user, callback},
            autoFocus: true,
            disableClose: true,
        });
    }

    /**
     * Открывает диалог сообщений пользователям.
     */
    openBroadcastMessagesDialog(): MatDialogRef<BroadcastMessagesDlgComponent> {

        return this._dialog.open(BroadcastMessagesDlgComponent, {
            autoFocus: false,
            disableClose: true,
        });
    }

    /**
     * Открывает диалог отправки документов во внешнюю систему.
     */
    openSendDocumentToExternalSystemDialog(): MatDialogRef<SendToExternalSystemDlgComponent> {

        return this._dialog.open(SendToExternalSystemDlgComponent, {
            autoFocus: false,
            disableClose: true,
        });
    }

    /**
     * Открывает диалог перемещения документов из одной папки в другую.
     *
     * @param props Данные выбора документов конкретным пользователем.
     */
    openMoveDocumentToAnotherSpaceDialog(
        props: UserWithSelectedDocumentsProps
    ): MatDialogRef<MoveDocumentsDlgComponent> {

        return this._dialog.open(
            MoveDocumentsDlgComponent,
            {
                data: { ...props },
                autoFocus: false,
                disableClose: false,
            },
        );
    }

    /**
     * Открывает диалог для работы со списком почт и списком папок.
     *
     * @param data Данные для диалога.
     */
    openEmailsAndSpacesDlg(data: DlgCreationData): MatDialogRef<UserSpacesDlgComponent> {

        return this._dialog.open(
            UserSpacesDlgComponent,
            {
                data: data,
                panelClass: [Constants.DIALOG_PANE_CLASS, "users-invitations-dlg-panel"],
                autoFocus: false,
                disableClose: true,
            }
        );
    }

    /**
     * Открывает диалог для создния или редактирования контрагента.
     *
     * @param value Данные для диалога.
     */
    openCreateLedgerDlg(value: CreateOrEditLedgerProps): void {

        this._dialog.open(
            CreateLedgerDlgComponent,
            {
                data: value,
                autoFocus: false,
                disableClose: false,
            }
        );
    }

    /**
     * Закрыть все открытые диалоги.
     */
    closeAll() {

        this._dialog.closeAll();
    }

    //endregion
}
