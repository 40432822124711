/**
 * Налоговая ставка.
 */
export class VatRate {
    //region Constants

    public static readonly NO_VAT: VatRate = new VatRate("NO_VAT", 0, "document.item.placeholder.noVat", false, false);
    public static readonly PERCENT_0: VatRate = new VatRate("PERCENT_0", 0, "0%", false, false);
    public static readonly PERCENT_0_POINT_1: VatRate = new VatRate("PERCENT_0_POINT_1", 0.1, "0.1%", true, false);
    public static readonly PERCENT_0_POINT_25: VatRate = new VatRate("PERCENT_0_POINT_25", 0.25, "0.25%", true, false);
    public static readonly PERCENT_1_POINT_5: VatRate = new VatRate("PERCENT_1_POINT_5", 1.5, "1.5%", true, false);
    public static readonly PERCENT_3: VatRate = new VatRate("PERCENT_3", 3, "3%", true, false);
    public static readonly PERCENT_5: VatRate = new VatRate("PERCENT_5", 5, "5%", false, false);
    public static readonly PERCENT_7: VatRate = new VatRate("PERCENT_7", 7, "7%", false, false);
    public static readonly PERCENT_9: VatRate = new VatRate("PERCENT_9", 9, "9%", true, false);
    public static readonly PERCENT_10: VatRate = new VatRate("PERCENT_10", 10, "10%", false, false);
    public static readonly PERCENT_12: VatRate = new VatRate("PERCENT_12", 12, "12%", false, false);
    public static readonly PERCENT_13: VatRate = new VatRate("PERCENT_13", 13, "13%", true, false);
    public static readonly PERCENT_15: VatRate = new VatRate("PERCENT_15", 15, "15%", true, false);
    public static readonly PERCENT_18: VatRate = new VatRate("PERCENT_18", 18, "18%", false, false);
    public static readonly PERCENT_20: VatRate = new VatRate("PERCENT_20", 20, "20%", false, false);
    public static readonly PERCENT_25: VatRate = new VatRate("PERCENT_25", 25, "25%", true, false);
    public static readonly PERCENT_28: VatRate = new VatRate("PERCENT_28", 28, "28%", true, false);
    public static readonly PERCENT_PAIRED_0: VatRate = new VatRate("PERCENT_PAIRED_0", 0, "0% + 0%", true, true);
    public static readonly PERCENT_PAIRED_0_POINT_1: VatRate = new VatRate("PERCENT_PAIRED_0_POINT_1", 0.1, "0.05% + 0.05%", true, true);
    public static readonly PERCENT_PAIRED_0_POINT_25: VatRate = new VatRate("PERCENT_PAIRED_0_POINT_25", 0.25, "0.125% + 0.125%", true, true);
    public static readonly PERCENT_PAIRED_1_POINT_5: VatRate = new VatRate("PERCENT_PAIRED_1_POINT_5", 1.5, "0.75% + 0.75%", true, true);
    public static readonly PERCENT_PAIRED_3: VatRate = new VatRate("PERCENT_PAIRED_3", 3, "1.5% + 1.5%", true, true);
    public static readonly PERCENT_PAIRED_5: VatRate = new VatRate("PERCENT_PAIRED_5", 5, "2.5% + 2.5%", true, true);
    public static readonly PERCENT_PAIRED_12: VatRate = new VatRate("PERCENT_PAIRED_12", 12, "6% + 6%", true, true);
    public static readonly PERCENT_PAIRED_18: VatRate = new VatRate("PERCENT_PAIRED_18", 18, "9% + 9%", true, true);
    public static readonly PERCENT_PAIRED_28: VatRate = new VatRate("PERCENT_PAIRED_28", 28, "14% + 14%", true, true);

    //endregion
    //region Ctor

    /**
     * Конструктор налоговой ставки.
     *
     * @param value Значение на сервере.
     * @param rate Значение ставки.
     * @param i18nDescription I18n ключ описания ставки.
     * @param foreign Флаг иностранной ставки.
     * @param paired Флаг парной ставки.
     */
    private constructor(
        public readonly value: string,
        public readonly rate: number,
        public readonly i18nDescription: string,
        public readonly foreign: boolean,
        public readonly paired: boolean,
    ) { }

    //endregion
    //region Public static

    /**
     * Возвращает все доступные налоговые ставки.
     */
    public static all(): VatRate[] {
        return Object.keys(VatRate)
            .filter((field => VatRate[field] instanceof VatRate))
            .map(field => VatRate[field] as VatRate);
    }

    /**
     * Возвращает налоговую ставку с указанным значением.
     *
     * @param vatRateValue Значение налоговой ставки.
     *
     * @return Налоговая ставка.
     */
    public static get(vatRateValue: string) {

        return VatRate.all().filter(vatRate => vatRate.value === vatRateValue)[0] || null;
    }

    //endregion
}
