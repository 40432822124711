import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import { HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { Observable } from "rxjs";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { FileExistenceCheckResponse } from "src/app/common/models/api-responses/file-existence-check-response";
import { FileWithMetaData } from "src/app/common/models/file-with-meta-data";
import { SourceEnum } from "src/app/common/models/source-enum";
import { EnteraDocument } from "src/app/spaces/modules/document/models/document";
import { Space } from "../models";

import { ApiResponse } from "../models";
import { MultipleRecognitionTaskApiResponse } from "../models";
import { RecognitionTask } from "../models";
import { RecognitionTaskApiResponse } from "../models";
import { RunningRecognitionTasksApiResponse } from "../models";

/**
 * Сервис для работы с задачами на распознавание.
 */
@Injectable({
    providedIn: "root"
})
export class RecognitionTaskService {
    //region Fields

    /**
     * HTTP-клиент.
     *
     * @private
     */
    private readonly _http: HttpClient;

    /**
     * Сервис переводов.
     */
    private readonly _translateService: TranslateService;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса для работы с задачами на распознавание.
     *
     * @param http HTTP-клиент.
     * @param translateService Сервис переводов.
     */
    constructor(
        http: HttpClient,
        translateService: TranslateService,
    ) {
        this._http = http;
        this._translateService = translateService;
    }

    //endregion
    //region Public

    /**
     * Создание задачи на распознование.
     *
     * @param files Файлы для задачи.
     * @param spaceId Пространство документов, в котором создаётся задача.
     * @param forceOcrRecognition Флаг принудительного вызова к удалённому OCR-сервису.
     * @param forceProcessing Флаг принудительного выполнения обработки и выемки данных.
     * @param forceQueue Флаг принудительной отправки результата выемки данных в очередь к оператору.
     * @param comment Комментарий
     * @param mobile Флаг загрузки файлов через мобильную версию приложения.
     * @param bankStatement Флаг создания задачи для выписки из банка.
     *
     * @return Observable от всех событий связанных с загрузкой, включая отправку заголовков, прогресс
     * загрузки и финальный ответ.
     */
    public createTasks(
        files: File[],
        spaceId: string,
        forceOcrRecognition: boolean = false,
        forceProcessing: boolean = false,
        forceQueue: boolean = false,
        comment: string = null,
        mobile: boolean = false,
        bankStatement: boolean = false,
    ): Observable<HttpEvent<MultipleRecognitionTaskApiResponse | RecognitionTaskApiResponse>> {

        const formData = new FormData();

        if (files.length === 1) {

            formData.append(`file`, files[0], files[0].name);
        }
        else {

            files.forEach((file, index) => formData.append(`file${index}`, file, file.name));
        }

        let requestParam: any = { spaceId };

        if (forceOcrRecognition) {

            requestParam = {
                ...requestParam,
                forceOcrRecognition
            };
        }

        if (forceProcessing) {

            requestParam = {
                ...requestParam,
                forceProcessing
            };
        }

        if (forceQueue) {

            requestParam = {
                ...requestParam,
                forceQueue
            };
        }

        if (comment) {

            requestParam = {
                ...requestParam,
                comment
            };
        }

        requestParam = {
            ...requestParam,
            source: mobile ? SourceEnum.WEB_MOBILE.id : SourceEnum.WEB_APP.id,
        };

        return this._http
            .post<MultipleRecognitionTaskApiResponse | RecognitionTaskApiResponse>(
                bankStatement && "/api/v1/bankStatements/upload" || "/api/v1/recognitionTasks/multiple",
                formData,
                {
                    params: requestParam,
                    observe: "events",
                    reportProgress: true,
                    responseType: "json",
                }
            )
            .pipe(catchError((response: HttpErrorResponse) => this.formApiErrorResponse(response)));
    }

    /**
     * Запрашивает данные задачи на распознавание с сервера.
     * 
     * @param taskId ID задачи на распознавание.
     *
     * @return Данные задачи на распознавание.
     */
    public getTask(taskId: string): Observable<RecognitionTaskApiResponse> {
        
        return this._http.get<RecognitionTaskApiResponse>(`/api/v1/recognitionTasks/${taskId}`);
    }

    /**
     * Запрашивает выполняющиеся задачи на распознавание для заданного пространства документов
     * с сервера.
     * 
     * @param spaceId ID пространства документов.
     *
     * @return Выполняющиеся задачи на распознавание.
     */
    public getRunningTasks(spaceId: string): Observable<RecognitionTask[]> {
        
        return this._http
            .get<RunningRecognitionTasksApiResponse>("/api/v1/recognitionTasks/running", {
                params: {
                    spaceId
                }
            })
            .pipe(
                map((response: RunningRecognitionTasksApiResponse): RecognitionTask[] =>
                    response.runningRecognitionTasks
                ),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
            );
    }

    /**
     * Возвращает строку, состоящую из всех имен файлов в задаче.
     *
     * @param recognitionTask Задача на распознавание.
     *
     * @return Строка, состоящая из всех имен файлов в задаче.
     */
    public getFileNames(recognitionTask: RecognitionTask): string {

        return recognitionTask.files.reduce((str, file) => str + file.name + ", ", "").trim().slice(0, -1);
    }

    /**
     * Возвращает кол-во не обработанных страниц задачи на распознавание.
     *
     * @param recognitionTask Задача на распознавание.
     *
     * @return Кол-во необработанных страниц задачи на распознавание.
     */
    public getRemainingPageCount(recognitionTask: RecognitionTask): number {

        return recognitionTask.documents.reduce(
            (sum: number, document: EnteraDocument ) => sum - document.pageCount,
            recognitionTask.pages && recognitionTask.pages.length || 0
        );
    }

    /**
     * Возвращает примерное кол-во минут до завершения обработки задачи на распознавание.
     *
     * Алгоритм расчета:
     *  - Если в задаче было не более 5 страниц изначально, то возвращается 2 * кол-во оставшихся страниц.
     *  - Иначе возвращается кол-во оставшихся страниц.
     *
     * @param recognitionTask Задача на распознавание.
     *
     * @return Примерное кол-во минут до завершения обработки задачи на распознавание.
     */
    public getRemainingMinutes(recognitionTask: RecognitionTask): number {

        const remainingPages: number = this.getRemainingPageCount(recognitionTask);

        let result: number = remainingPages;

        if (recognitionTask.pages && recognitionTask.pages.length <= 5) {

            result = remainingPages * 2;
        }

        return result;
    }


    /**
     * Осуществляет запрос проверки, существуют ли файлы на файловом сервере.
     *
     * @param space Текущая папка.
     * @param filesWIthMetaData Файлы.
     */
    public checkFilesExistence(
        space: Space,
        filesWIthMetaData: FileWithMetaData[],
    ): Observable<FileExistenceCheckResponse> {

        let hashList = filesWIthMetaData.map(fileWithMetaData => fileWithMetaData.hash);
        let body = { spaceId: space && space.id, hashList };
        return this._http.post<FileExistenceCheckResponse>("/api/v1/checkExistence", body)
            .pipe(
                map((result: any) => result as FileExistenceCheckResponse),
                catchError((response: HttpErrorResponse) => this.formApiErrorResponse(response)),
            );
    }

    //endregion
    //region Private

    /**
     * Для сущности HttpErrorResponse возвращает функцию, которая не эмитит никаких событий наблюдателю, но эмитит
     * осмысленную ошибку в виде ApiResponse.
     *
     * Т.к. не все HttpErrorResponse являются результатом неуспешного ответа сервера на запрос (например произошла
     * ошибка на клиенте или не пропустил nginx) то здесь мы можем сформировать различные осмысленные ApiResponse,
     * которые можно отобразить в тех случаях, когда что-то пошло не так на стороне клиента.
     *
     * - Если вернулся ответ-ошибка от сервера - выбрасываем её. Иначе:
     * - Если получили 413 статус код (слишком большое тело запроса) - выкидываем соотв. ошибку.
     * - Если получили 0 статус код и ошибку в виде ProgressEvent - значит файл был удалён.
     * - На все остальные случаи формируем ошибку с общим сообщением об ошибке на стороне клиента.
     */
    private formApiErrorResponse(response: HttpErrorResponse): Observable<never> {

        const statusCode: number = response.status;
        let error: ApiResponse = response.error;

        if (!!error.errorMessage) {

            return throwError(error);
        }

        if (statusCode === 413) {

            const errorMessage: string =
                this._translateService.instant("common.upload-to-recognize-dialog.filesTooBig");

            error = {
                result: false,
                code: statusCode,
                errorMessage: errorMessage,
            };
        }
        else {

            const errorMessage: string =
                this._translateService.instant("common.upload-to-recognize-dialog.clientSideError");

            error = {
                result: false,
                code: statusCode,
                errorMessage: errorMessage,
            };
        }

        return throwError(error);
    }

    //endregion
}
