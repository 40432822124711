import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Inject } from "@angular/core";
import { Input } from "@angular/core";
import { Output } from "@angular/core";
import { ViewChild } from "@angular/core";
import { InfoMenuItem } from "src/app/common/models/info-menu-item";
import { MAT_MENU_OVERLAY_PANEL_CLASS } from "src/app/common/providers/overlay-panel-class.provider";
import { MatMenuTrigger } from "@angular/material/menu";
import { MenuPositionX } from "@angular/material/menu";
import { MenuPositionY } from "@angular/material/menu";

/**
 * Обобщённая часть компонент для отображения информации в заголовке с выпадающим меню.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "info-menu",
    styleUrls: ["info-menu.component.scss"],
    templateUrl: "info-menu.component.html"
})
export class InfoMenuComponent {
    //region Inputs

    /**
     * Входящие данные - иконка.
     */
    @Input()
    icon: string;

    /**
     * Входящие данные - позиция меню по горизонтали относительно кнопки.
     */
    @Input()
    xPosition: MenuPositionX = "before";

    /**
     * Входящие данные - позиция меню по вертикали относительно кнопки.
     */
    @Input()
    yPosition: MenuPositionY = "below";

    /**
     * Входящие данные - шрифт для иконки.
     */
    @Input()
    iconFontSet: string;

    /**
     * Входящие данные - иконка при наведении.
     */
    @Input()
    hoveredIcon: string;

    /**
     * Входящие данные - шрифт для иконки при наведении.
     */
    @Input()
    hoveredIconFontSet: string;

    /**
     * Иконка отключена?
     */
    @Input()
    iconDisabled: boolean;

    /**
     * Входящие данные - текст.
     */
    @Input()
    label: string;

    /**
     * Входящие данные - i18n-ключ текста.
     */
    @Input()
    labelKey: string;

    /**
     * Входящие данные - элементы меню.
     */
    @Input()
    menuItems: InfoMenuItem[];

    /**
     * Тема крупного размера меню?
     */
    @Input()
    large: boolean;

    //endregion
    //region Outputs

    /**
     * Исходящее событие - клик по элементу меню.
     */
    @Output()
    menuItemClick = new EventEmitter<any>();

    /**
     * Исходящее событие - клик по правой иконке элемента меню.
     */
    @Output()
    rightItemMenuItemClick = new EventEmitter<any>();

    //endregion
    //region Fields

    /**
     * CSS-класс для контейнера внутри overlay'я, при отображении mat-menu.
     */
    overlayPanelClass;

    /**
     * Кнопка управления открытия меню.
     */
    @ViewChild(MatMenuTrigger)
    menuBtn: MatMenuTrigger;

    /**
     * Флаг, что на компонент навели мышку.
     */
    hovered: boolean;

    //endregion
    //region Ctor

    /**
     * Конструктор обобщённой части компонента для отображения информации в заголовке с выпадающим меню.
     *
     * @param overlayPanelClassValue CSS-класс для контейнера внутри overlay'я, при отображении mat-menu.
     */
    constructor(
        @Inject(MAT_MENU_OVERLAY_PANEL_CLASS) overlayPanelClassValue
    ) {
        this.overlayPanelClass = overlayPanelClassValue;
    }

    //endregion
    //region Events

    /**
     * Обработчик клика по элементу меню.
     * 
     * @param menuItem Элемент меню, по которому кликнули.
     */
    menuItemClickHandler(menuItem: InfoMenuItem): void {

        this.menuItemClick.emit(menuItem.id);
    }

    /**
     * Обработчик клика по правой иконке элемента меню.
     *
     * @param menuItem Элемент меню, правую иконку которого кликнули.
     */
    menuItemRightIconClickHandler(menuItem: InfoMenuItem): void {

        this.rightItemMenuItemClick.emit(menuItem.id);
    }

    //endregion
}
