import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {OverlayContainer} from "@angular/cdk/overlay";
import {UtilsService} from "./utils.service";

@Injectable({
  providedIn: 'root'
})
export class OneActiveService {

  global: Boolean = true;
  private renderer: Renderer2;

  private lostActive: Function;
  private current: any;

  //todo Добавить сброс на переходе в роутере
  //todo Исправить логику для выделния полей и селетков на странице документов
  constructor(
    rendererFactory: RendererFactory2,
    overlayContainer: OverlayContainer,
    utilsService: UtilsService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    // Сброс активации, если клик был вне элементов активации.
    this.renderer.listen(
      "window",
      "click",
      this.dropActive
    );

    // Отменяем всплытие клика в оверлее материала.
    this.renderer.listen(
      overlayContainer.getContainerElement(),
      "click",
      utilsService.stopPropagation
    )
  }

  activate(current: any, lostActive: Function, skipPreviousLostActive: boolean = false): void {

    this.global = false;

    if (current !== this.current) {

      if (!skipPreviousLostActive && this.lostActive) {
        this.lostActive();
      }
      this.current = current;
      this.lostActive = lostActive;

    }
  }

  deactivate(current: any) {
    if (this.current === current) {
      this.activate(null, null);
    }
  }

  dropActive = () => this.activate(null, null);


}
