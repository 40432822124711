import { ApiResponse } from "src/app/common/models/api-response";

/**
 * Начальное состояние запроса.
 */
interface RequestInitialState {
    type: "initial";
}

/**
 * Состояние обработки запроса.
 */
interface RequestPendingState {
    type: "pending";
}

/**
 * Состояние успешно обработанного запроса.
 */
interface RequestSuccessState<T = never> {
    type: "success";
    result?: T;
}

/**
 * Состояние проваленного запроса.
 */
interface RequestFailState {
    type: "fail";
    error: ApiResponse;
}

/**
 * Функция, формирующая начальное состояние запроса.
 */
const initial = (): RequestInitialState => ({ type: "initial" });

/**
 * Функция, формирующая состояние запроса в обработке.
 */
const pending = (): RequestPendingState => ({ type: "pending" });

/**
 * Функция, формирующая состояние успешно обработанного запроса.
 *
 * @param result Результат обработки запроса. Опциональный параметр.
 */
const success = <T = never> (result?: T): RequestSuccessState<T> => {

    if (!!result) {

        return { type: "success", result };
    }
    else {

        return  { type: "success" };
    }
}

/**
 * Функция, формирующая состояние проваленного запроса.
 *
 * @param error Ошибка при обработке запроса.
 */
const fail = (error: ApiResponse): RequestFailState => ({ type: "fail", error });

/**
 * Тип, объединяющий все состояние запроса.
 */
export type RequestState<T = never> = RequestInitialState
    | RequestSuccessState<T>
    | RequestFailState
    | RequestPendingState;

/**
 * Объединение функций для формирования состояния запроса.
 */
export const requestState = {
    initial,
    pending,
    success,
    fail,
}

/**
 * Возвращает результат выполнения запроса, при его наличии.
 *
 * @param requestState Состояние запроса.
 */
export function getRequestResult<T>(requestState: RequestState<T>): T {

    return !!requestState && requestState.type === "success" && requestState.result;
}
