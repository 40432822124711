import { Injectable } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

import { ofType } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { of } from "rxjs";
import { filter } from "rxjs/operators";
import { withLatestFrom } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { PurchaseDlgComponent } from "src/app/common/components/purchase-dlg/purchase-dlg.component";
import { ApiResponse } from "src/app/common/models/api-response";
import { DlgService } from "src/app/common/services/dlg.service";
import { UserService } from "src/app/common/services/user.service";
import { ValueProps } from "src/app/root/store/actions/props/value-props";
import { PurchaseDialogAction } from "src/app/root/store/actions/purchase-dialog.action";
import { purchaseDialogActions } from "src/app/root/store/actions/purchase-dialog.action";
import { PurchaseDialogActionType } from "src/app/root/store/actions/purchase-dialog.action";
import { CurrentUserState } from "src/app/root/store/reducers/current-user.reducer";
import { currentUserStateSelector } from "../reducers";
import { RootState } from "../reducers";
import {PurchaseDlgData} from "src/app/common/models/purchase-dlg-data";

/**
 * Side-эффекты на события, связанные диалогом о запросе на покупку страниц.
 */
@Injectable()
export class PurchaseDialogEffect {
    //region Fields

    /**
     * Сервис с логикой для работы с пользователем.
     */
    private readonly _userService: UserService;

    /**
     * Сервис для вызова диалога для отображения сообщений пользователю.
     */
    private readonly _dlgService: DlgService;

    /**
     * Сервис для вызова диалога для отображения сообщений пользователю.
     */
    private readonly _translateService: TranslateService;

    /**
     * Экземпляр открытого в данный момент диалога.
     */
    private _openedDlg: MatDialogRef<PurchaseDlgComponent>;

    //endregion
    //region Ctor

    /**
     * Конструктор объекта обработки side-эффектов на события, связанные с диалогом о запросе на покупку страниц.
     *
     * @param _actions$ Поток событий в системе.
     * @param _store Хранилище.
     * @param userService Сервис с логикой для работы с пользователем.
     * @param dlgService Сервис для работы с диалогами.
     * @param translateService Сервис перевода сообщений.
     */
    constructor(
        private _actions$: Actions,
        private _store: Store<RootState>,
        userService: UserService,
        dlgService: DlgService,
        translateService: TranslateService,
    ) {
        this._userService = userService;
        this._dlgService = dlgService;
        this._translateService = translateService;
    }

    //endregion
    //region Effects

    /**
     * Обработка события открытия диалога покупки страниц.
     */
    @Effect({dispatch: false})
    openDialog$ = this._actions$
        .pipe(
            ofType(PurchaseDialogActionType.OPEN_DIALOG),
            withLatestFrom(this._store.select(currentUserStateSelector)),
            tap(([data, userState]: [PurchaseDlgData, CurrentUserState]) => {

                if (!userState.currentUserInfo.phoneConfirm) {

                    this._dlgService.openPhoneConfirmDialog(
                        userState.currentUserInfo,
                        () => this._store.dispatch(purchaseDialogActions.open(data))
                    );
                }
            }),
            filter(([_, userState]) => userState.currentUserInfo.phoneConfirm),
            tap(([data, _]: [PurchaseDlgData, never]): void => {

                if (this._openedDlg) {

                    this._openedDlg.close();
                }

                this._openedDlg = this._dlgService.openPurchaseDialog({
                    ...data,
                    headerKey: "dialogs.purchase.header",
                    loading: true,
                    disableClose: true,
                    confirmCallback: (contactPhoneNumber: string): any => {

                        this._store.dispatch(
                            purchaseDialogActions.sendRequestPurchase({ value: contactPhoneNumber })
                        );
                    },
                });
            }),
        );

    /**
     * Обработка событий отправки запроса о покупке страниц.
     */
    @Effect()
    sendResponse$ = this._actions$.pipe(
        ofType(PurchaseDialogActionType.REQUEST_PURCHASE),
        switchMap((props: ValueProps<string>): Observable<Action> =>
            this._userService.sendPurchaseRequest(props.value).pipe(
                map((): PurchaseDialogAction => purchaseDialogActions.sendRequestSuccess),
                catchError(
                    (error: ApiResponse): Observable<Action> =>
                        of(purchaseDialogActions.sendRequestFail({error: error})),
                ),
            ),
        ),
    );

    //endregion
}

