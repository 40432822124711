import {PermissionItem} from "src/app/common/models/permission-item";

/**
 * Право пользователя на какую-либо операцию.
 */
export class SpacePermissionItem implements PermissionItem {
    //region Permissions User

    /**
     * Права обычных пользователей.
     */
    public static readonly USER = new SpacePermissionItem("USER");

    /**
     * Права пользователя в пространстве документов.
     */
    public static readonly SPACE = new SpacePermissionItem("SPACE", SpacePermissionItem.USER);

    /**
     * Право на изменение интеграции.
     */
    public static readonly INTEGRATION = new SpacePermissionItem("INTEGRATION", SpacePermissionItem.SPACE);

    /**
     * Право на полное удаление задач распознавания.
     */
    public static readonly RECOGNITION_TASK_TOTAL_DELETE = new SpacePermissionItem(
        "RECOGNITION_TASK_TOTAL_DELETE",
        SpacePermissionItem.SPACE
    );

    /**
     * Право пригласить любого другого пользователя в пространство документов.
     *
     * Пользователь может еще не существовать в системе.
     */
    public static readonly INVITE_USERS = new SpacePermissionItem("INVITE_USERS", SpacePermissionItem.SPACE);


    /**
     * <p>Право добавлять доверенные почты, с которых можно принимать задачи на распознавание на эту папку.</p>
     */
    public static readonly MANAGE_TRUSTED_EMAILS
        = new SpacePermissionItem("MANAGE_TRUSTED_EMAILS", SpacePermissionItem.SPACE);

    /**
     * Право просмотра реестра документов.
     */
    public static readonly SEE_DOCUMENTS = new SpacePermissionItem("SEE_DOCUMENTS", SpacePermissionItem.SPACE);

    /**
     * Право управлять уведомлениями пользователей на почту о состоянии задачи на распознавание.
     */
    public static readonly MANAGE_EMAIL_NOTIFICATIONS = new SpacePermissionItem(
        "MANAGE_EMAIL_NOTIFICATIONS",
        SpacePermissionItem.SPACE,
    );

    /**
     * Право просматривать правила распределения документов.
     */
    public static readonly SEE_REDIRECT_RULES = new SpacePermissionItem(
        "SEE_REDIRECT_RULES",
        SpacePermissionItem.SPACE,
    );

    /**
     * Право редактировать правила распределения документов.
     */
    public static readonly EDIT_REDIRECT_RULES = new SpacePermissionItem(
        "EDIT_REDIRECT_RULES",
        SpacePermissionItem.SPACE,
    );

    /**
     * Редактировать документы в папке.
     */
    public static readonly EDIT_SPACE_DOCUMENTS = new SpacePermissionItem(
        "EDIT_SPACE_DOCUMENTS",
        SpacePermissionItem.SPACE
    );

    //endregion
    //region Fields

    /**
     * Ключ права.
     */
    readonly value: string;

    /**
     * Родительское право.
     */
    readonly parent: SpacePermissionItem;

    //endregion
    //region Ctor

    /**
     * Конструктор права администратора.
     *
     * @param value Ключ права.
     * @param parent Родительское право.
     */
    private constructor(value: string, parent: SpacePermissionItem = null) {
        this.value = value;
        this.parent = parent;
    }

    //endregion
    //region Getters and Setters

    /**
     * I18n-ключ названия права.
     */
    nameKey(): string {

        return "SpacePermissionItem." + this.value;
    }

    //endregion
}
