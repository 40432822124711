import { Action } from '@ngrx/store';
import { Space } from "src/app/common/models/index";
import { SendUserPhoneProps } from "src/app/root/store/actions/props/send-user-phone-props";

import { ApiResponsePayloadAction } from "../";
import { ApiResponse } from "src/app/common/models/api-response";
import { CurrentUserInfo } from "src/app/common/models/current-user-info";

/**
 * Типы событий, связанные с текущим пользователем.
 */
export enum CurrentUserActionType {
    LOAD = "[Root] Load CurrentUser",
    LOAD_SUCCESS = "[Root] Load CurrentUser Success",
    LOAD_FAIL = "[Root] Load CurrentUser Fail",
    LOGOUT = "[Root] Logout CurrentUser",
    LOGOUT_SUCCESS = "[Root] Logout CurrentUser Success",
    LOGOUT_FAIL = "[Root] Logout CurrentUser Fail",
    RECOGNITION_TASK_UPLOADED = "[Root] Recognition task successfully uploaded",
    PHONE_CONFIRM_SUCCESS = "[Root] Current user's phone confirmed",
    UPDATE_AND_REDIRECT = "[Root] Create new space for current client",
    UPDATE_AND_REDIRECT_SUCCESS = "[Root] Create new space for current client and redirect finished successfully",
    UPDATE_AND_REDIRECT_FAIL = "[Root] Create new space for current client and redirect finished fail",
    UPDATE_SPACE = "[Root] Update space for current client",
}

/**
 * Событие, требующее загрузки данных текущего пользователя.
 */
export class CurrentUserLoadAction implements Action {
    readonly type = CurrentUserActionType.LOAD;
}

/**
 * Событие успешной загрузки текущего пользователя.
 */
export class CurrentUserLoadSuccessAction implements Action {
    readonly type = CurrentUserActionType.LOAD_SUCCESS;
    constructor(public payload: CurrentUserInfo) { }
}

/**
 * Событие обновления списка пространств документов у текущего пользователя после создания пространства документов.
 */
export class CurrentUserUpdateAndRedirectAfterCreateSpaceAction implements Action {
    readonly type =  CurrentUserActionType.UPDATE_AND_REDIRECT;
    constructor(public payload: Space) { }
}

/**
 * Событие загрузки текущего пользователя после создания папки.
 */
export class CurrentUserUpdateAndRedirectAfterCreateSpaceSuccessfullyAction implements Action {
    readonly type =  CurrentUserActionType.UPDATE_AND_REDIRECT_SUCCESS;
    constructor(public space: Space, public currentUserInfo: CurrentUserInfo) { }
}

/**
 * Событие неудачной загрузки текущего пользователя после создания папки.
 */
export class CurrentUserUpdateAndRedirectAfterCreateSpaceFailAction implements ApiResponsePayloadAction {
    readonly type = CurrentUserActionType.UPDATE_AND_REDIRECT_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Событие обновления списка пространств документов у текущего пользователя после изменения пространства документов.
 */
export class CurrentUserUpdateAfterUpdateSpaceAction implements Action {
    readonly type =  CurrentUserActionType.UPDATE_SPACE;
    constructor(public payload: Space) { }
}

/**
 * Событие неудачной загрузки текущего пользователя.
 */
export class CurrentUserLoadFailAction implements ApiResponsePayloadAction {
    readonly type = CurrentUserActionType.LOAD_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Событие, требующее выполнение выхода из системы текущего пользователя.
 */
export class CurrentUserLogoutAction implements Action {
    readonly type = CurrentUserActionType.LOGOUT;
}

/**
 * Событие успешного выполнение выхода из системы текущего пользователя.
 */
export class CurrentUserLogoutSuccessAction implements Action {
    readonly type = CurrentUserActionType.LOGOUT_SUCCESS;
}

/**
 * Событие неудачного выполнение выхода из системы текущего пользователя.
 */
export class CurrentUserLogoutFailAction implements ApiResponsePayloadAction {
    readonly type = CurrentUserActionType.LOGOUT_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Событие успешной загрузки задачи на распознавание текущим пользователем.
 */
export class CurrentUserRecognitionTaskUploadedAction implements Action {
    readonly type = CurrentUserActionType.RECOGNITION_TASK_UPLOADED;
}

/**
 * Событие успешного подтверждения номера телефона пользователем.
 */
export class CurrentUserConfirmPhoneSuccessAction implements Action {

    readonly type = CurrentUserActionType.PHONE_CONFIRM_SUCCESS;
    constructor(public payload: SendUserPhoneProps) {}
}

/**
 * Тип, объединяющий все события, связанные с текущим пользователем.
 */
export type CurrentUserAction =
    | CurrentUserLoadAction
    | CurrentUserLoadFailAction
    | CurrentUserLoadSuccessAction
    | CurrentUserLogoutAction
    | CurrentUserLogoutSuccessAction
    | CurrentUserRecognitionTaskUploadedAction
    | CurrentUserConfirmPhoneSuccessAction
    | CurrentUserUpdateAndRedirectAfterCreateSpaceAction
    | CurrentUserUpdateAndRedirectAfterCreateSpaceSuccessfullyAction
    | CurrentUserUpdateAndRedirectAfterCreateSpaceFailAction
    | CurrentUserUpdateAfterUpdateSpaceAction;
