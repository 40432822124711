/**
 * Общая часть ответов от API.
 */
export interface ApiResponse {

    /**
     * Флаг успешности выполнения запроса.
     */
    result: boolean;

    /**
     * ID ошибки, в случае если запрос завершился неудачно.
     */
    errorId?: string;

    /**
     * Код ошибки, в случае если запрос завершился неудачно.
     */
    errorCode?: string;

    /**
     * Текст ошибки, в случае если запрос завершился неудачно.
     */
    errorMessage?: string;

    /**
     * Детали ошибки, в случае если запрос завершился неудачно.. 
     */
    details?: {
        
        /**
         * Код ошибки.
         */
        errorCode?: string;

        /**
         * Текст ошибки. 
         */
        errorMessage: string;
    }[];

    /**
     * Код HTTP-ответа.
     */
    code: number;
}


/**
 * Проверяет, является ли значение экземпляром интерфейса {@link ApiResponse}.
 *
 * @param value Значение.
 */
export function isErrorApiResponse(value: any): value is ApiResponse {

    return typeof value === "object" && value.hasOwnProperty("errorMessage") && value.hasOwnProperty("result");
}
