<div class="user-notification" >
    <div *ngIf="notificationType?.name === 'SUBSCRIPTION' && notificationType.needShowOnUi" class="user-notification__text">
        <span>{{"header.notification.subscriptionExpiring" | translate}}&nbsp;</span>
        <a class="user-notification__link" (click)="connectToManagerHandler()">
            {{"header.notification.manager" | translate}}
        </a>
    </div>
    <div
        *ngIf="!notificationType || (notificationType.name !== 'SUBSCRIPTION' && notificationType.needShowOnUi)"
        class="user-notification__text"
    >
        <span>{{"header.notification.balanceExpiring" | translate}}&nbsp;</span>
        <a class="user-notification__link" (click)="connectToManagerHandler()">
            {{"header.notification.manager" | translate}}
        </a>
    </div>
    <div class="user-notification__close">
        <button mat-icon-button (click)="closeHandler()">
            <mat-icon class="user-notification__close__icon">close</mat-icon>
        </button>
    </div>
</div>
