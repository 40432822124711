import { HttpErrorResponse } from "@angular/common/http";
import { ApiResponse } from "src/app/common/models/api-response";

/**
 * Утилиты для работы с ошибками.
 */
export class ErrorUtils {
    //region Public

    /**
     * Возвращает данные об ошибке на основе HTTP-ответа.
     *
     * @param httpResponse HTTP-ответ.
     *
     * @return Данные об ошибке.
     */
    static from(httpResponse: HttpErrorResponse): ApiResponse {

        let error: ApiResponse = null;

        if (httpResponse.status === 0) {

            error = {
                result: false,
                code: httpResponse.status,
            };
        }
        else if (httpResponse.error && httpResponse.error === "object") {

            error = {
                ...httpResponse.error,
                code: httpResponse.status,
            };
        }
        else {

            error = {
                errorMessage: httpResponse.message,
                result: false,
                code: httpResponse.status,
            };
        }

        return error;
    }

    //endregion
}
