import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UrlUtils } from "src/app/common/utils/url.utils";

import { Observable } from "rxjs";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { BroadcastMessageResponse } from "src/app/common/models/broadcast-message-response.model";
import { ApiResponse } from "../../common/models";

/**
 * Сервис для отправки ответов пользователя на сообщения.
 */
@Injectable({
    providedIn: "root",
})
export class BroadcastMessagesService {
    //region Fields

    /**
     * Сервис для работы с диалогами.
     */
    private readonly _dialog: MatDialog;

    /**
     * HTTP-клиент.
     */
    private readonly _http: HttpClient;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса для отправки ответов пользователя на сообщения.
     *
     * @param dialog Сервис для работы с диалогами.
     * @param httpClient HTTP-клиент.
     */
    constructor(dialog: MatDialog, httpClient: HttpClient) {

        this._dialog = dialog;
        this._http = httpClient;
    }

    //endregion
    //region Public

    /**
     * Отправляет ответ на сообщение на сервер.
     *
     * @param broadcastMessageId ID сообщения, к которому относится ответ.
     * @param broadcastMessageResponse Ответ на сообщение
     */
    sendResponse(broadcastMessageId: string, broadcastMessageResponse: BroadcastMessageResponse): Observable<boolean> {

        return this._http
            .post<ApiResponse>(UrlUtils.BroadcastMessageResponseUrl(broadcastMessageId), broadcastMessageResponse)
            .pipe(
                map((response: ApiResponse): boolean => response.result),
                catchError((response: HttpErrorResponse): Observable<never> =>
                    throwError(response.error as ApiResponse)
                )
            );
    }

    //endregion
}
