import { HttpClient } from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { throwError } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { RecognitionTask } from "src/app/common/models/index";
import { RecognitionTaskApiResponse } from "src/app/common/models/index";
import { ApiResponse } from "../../common/models";

/**
 * Сервис для отправки чеков на распознавание.
 */
@Injectable({ 
    providedIn: "root"
})
export class QrService {
    //region Fields

    /**
     * HTTP-клиент.
     */
    private readonly _http: HttpClient;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса для отправки чеков на распознавание.
     *
     * @param http HTTP-клиент.
     */
    constructor(http: HttpClient) {

        this._http = http;
    }

    //endregion
    //region Public

    /**
     * Отправка чеков на распознавание в заданное пространство документов.
     *
     * @param receipts Отображение QR-код из чека: PNG изображение.
     * @param spaceId ID пространства документов.
     *
     * @return Успех выполнения операции.
     */
    sendReceipt(receipts: Map<string, Blob>, spaceId: string): Observable<RecognitionTask> {

        const url = `/api/v1/recognitionTasks/qr?spaceId=${spaceId}`;

        const body = new FormData();
        receipts.forEach((image, qr) => body.append(qr, image, "qr.jpeg"));

        return this._http
            .post<RecognitionTaskApiResponse>(url, body)
            .pipe(
                map(response => response.recognitionTask),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
            );
    }

    //endregion
}
