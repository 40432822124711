import { Injectable } from "@angular/core";

import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";

import { of } from "rxjs";
import { filter } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { tap } from "rxjs/operators";

import { RootState } from "../../reducers";
import { UploadTaskToRecognizeSuccessAction } from "../../actions";
import { NewDocumentsCountIncrementAction } from "../../actions";
import { RecognitionTasksLoadFailAction } from "../../actions";
import { RecognitionTasksLoadSuccessAction } from "../../actions";
import { RecognitionTasksLoadAction } from "../../actions";
import { UploadToRecognizeActionType } from "../../actions";
import { AddRecognitionTaskAction } from "../../actions";
import { RecognitionTaskChangeAction } from "../../actions";
import { RecognitionTaskFinishedAction } from "../../actions";
import { RecognitionTasksActionType } from "../../actions";
import { RecognitionTaskErrorAction } from "../../actions";

import { RecognitionState } from "src/app/common/models/recognition-task";
import { RecognitionTask } from "src/app/common/models/recognition-task";
import { RecognitionTaskService } from "src/app/common/services/recognition-task.service";
import { Space } from "src/app/common/models";
import { ApiResponse } from "src/app/common/models";
import { withLatestFrom } from "rxjs/operators";
import { recognizedDocumentsSelector } from "../../selectors";
import { select } from "@ngrx/store";
import { AddRecognizedDocumentsAction } from "../../actions";
import { LoadInitialDocumentsAction } from "../../actions";
import { EnteraDocument } from "src/app/common/models";
import { LoadInitialDocumentsSuccessAction } from "../../actions";
import { recognitionTasksStateSelector } from "../../reducers";
import { initialDocumentsSelector } from "../../selectors";

/**
 * Side-эффекты на события, связанные задачами на распознавание.
 */
@Injectable()
export class RecognitionTasksEffects {
    //region Fields

    /**
     * Частота запросов на состояние выполняющихся задач на распознавание.
     */
    public readonly POLLING_FREQ_MS = 5 * 1000;

    /**
     * Хранилище индетификаторов задач поллинга.
     */
    private pollingMap: { [id: string]: number } = {};

    //endregion
    //region Ctor

    constructor(
        private actions$: Actions,
        private store: Store<RootState>,
        private recognitionTaskService: RecognitionTaskService,
    ) { }

    //endregion
    //region Public

    /**
     * Обработка события требования загрузки выполняющихся задач на распознавание.
     */
    @Effect()
    loadRunningTasks$ = this.actions$
        .pipe(
            ofType(RecognitionTasksActionType.LOAD),
            map((action: RecognitionTasksLoadAction) => action.payload),
            switchMap((space: Space) =>
                this.recognitionTaskService.getRunningTasks(space.id)
                    .pipe(
                        map(tasks => new RecognitionTasksLoadSuccessAction(tasks)),
                        catchError((response: ApiResponse) => of(new RecognitionTasksLoadFailAction(response)))
                    )
            )
        );

    /**
     * Обработка события удачного создания задачи на распознавание.
     * Добавляет созданную задачу в список задач.
     */
    @Effect()
    add$ = this.actions$
        .pipe(
            ofType<UploadTaskToRecognizeSuccessAction>(UploadToRecognizeActionType.UPLOAD_SUCCESS),
            map(action => new AddRecognitionTaskAction(action.payload))
        );

    /**
     * Обработка события успешной загрузки выполняющихся задач на распознавание.
     */
    @Effect({ dispatch: false })
    pollingMany$ = this.actions$
        .pipe(
            ofType<AddRecognitionTaskAction | RecognitionTasksLoadSuccessAction>(
                RecognitionTasksActionType.LOAD_SUCCESS, RecognitionTasksActionType.ADD_TASK
            ),
            map((action: RecognitionTasksLoadSuccessAction) => action.payload),
            tap((tasks: RecognitionTask[]) => {

                tasks.forEach(task => {

                    this.pollingMap = {
                        ...this.pollingMap,
                        [task.id]: this.makePolling(task)
                    };
                });
            })
        );

    /**
     * Обработка события изменения статуса задачи.
     * Перемещает задачу из списка tasks в поле lastRecognized, если все прошло хорошо, или в поле lastError, если
     * распознавание завершилось ошибкой.
     */
    @Effect()
    change$ = this.actions$
        .pipe(
            ofType<RecognitionTaskChangeAction>(RecognitionTasksActionType.CHANGE_TASK_STATUS),
            filter(action =>
                action.payload.state === RecognitionState.RECOGNIZED 
                || action.payload.state === RecognitionState.ERROR
            ),
            tap(action => clearInterval(this.pollingMap[action.payload.id])),
            map((action) => {

                if (action.payload.state === RecognitionState.RECOGNIZED) {

                    return new RecognitionTaskFinishedAction(action.payload);
                }
                else {
                    
                    return new RecognitionTaskErrorAction(action.payload);
                }
            })
        );

    /**
     * Добавление новых распознанных документов.
     */
    @Effect()
    addRecognizedDocuments$ = this.actions$
        .pipe(
            ofType<RecognitionTaskFinishedAction>(RecognitionTasksActionType.CHANGE_TASK_STATUS),
            filter(action => action.payload.state !== RecognitionState.ERROR),
            withLatestFrom(
                this.store.pipe(select(recognizedDocumentsSelector)),
                this.store.pipe(select(initialDocumentsSelector)),
            ),
            map(([action, recognized, initial]) => {

                let existIds = recognized.concat(...initial).map(d => d.id);

                return (action.payload.documents || [])
                    .filter(document => !existIds.includes(document.id))
                    .filter(document => document.space.id === action.payload.space.id);
            }),
            map((docs: any) => docs.filter(doc => doc.state === "RECOGNIZED")),
            filter(docs => docs.length > 0),
            map((newDocuments: any) => new AddRecognizedDocumentsAction(newDocuments))
        );

    /**
     * Изменение счетчика распознанных документов для новых распознанных документов.
     */
    @Effect()
    increaseNewDocumentCount$ = this.actions$
        .pipe(
            ofType<AddRecognizedDocumentsAction>(RecognitionTasksActionType.ADD_RECOGNIZED_DOCUMENTS),
            map(action => new NewDocumentsCountIncrementAction(action.payload.length))
        );

    /**
     * Обработка события остановки всех перидических опросов состояния задач.
     */
    @Effect({ dispatch: false })
    clearPolling$ = this.actions$
        .pipe(
            ofType(RecognitionTasksActionType.CLEAR),
            tap(() => {

                Object.keys(this.pollingMap)
                    .forEach((taskId: string) => clearInterval(this.pollingMap[taskId]));

                this.pollingMap = {};
            })
        );

    /**
     * Запрос обновления распознанных документов.
     */
    @Effect()
    updateRecognizedDocuments = this.actions$
        .pipe(
            ofType(RecognitionTasksActionType.LOAD_INITIAL_DOCUMENTS),
            map((action: LoadInitialDocumentsAction) => action.payload),
            switchMap((space: Space) =>
                this.recognitionTaskService.getRunningTasks(space.id)
                    .pipe(
                        map (this.mapToDocs),
                        map(docs => new LoadInitialDocumentsSuccessAction(docs)),
                        catchError((response: ApiResponse) => of(new RecognitionTasksLoadFailAction(response)))
                    )
            )
        );

    //endregion
    //region Private

    /**
     * Функция, запускающая периодический опрос сервера о состоянии задачи на распознавание.
     * Ответ учитывается только если статус задач на распознованеи загружен.
     * 
     * @param task Задача на распознавания, для котрой запускается опрос.
     */
    private makePolling = (task: RecognitionTask): number => {

        return setInterval(() => {
            this.recognitionTaskService.getTask(task.id)
                .pipe(
                    withLatestFrom(this.store.pipe(select(recognitionTasksStateSelector))),
                    filter(([_, state]) => state.loaded && state.initialLoaded),
                    map(([response, _]) => response.recognitionTask),
                )
                .subscribe(response => this.store.dispatch(new RecognitionTaskChangeAction(response)));
        }, this.POLLING_FREQ_MS);
    };

    /**
     * Отображенеи списка задача, в список распознанных документов.
     *
     * @param tasks Задачи для отображения.
     */
    private mapToDocs = (tasks: RecognitionTask[]) => {
        return tasks.map(task => task.documents || [])
            .map(docs => docs as any as EnteraDocument[])
            .reduce((acc, docs) => acc.concat(docs), [])
            .filter(doc => doc.state === "RECOGNIZED");
    }

    //endregion
}
