import { Component } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Input } from "@angular/core";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { ViewChild } from "@angular/core";
import { ElementRef } from "@angular/core";
import { UploadToRecognizeState } from "src/app/root/store/index";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'entera-file-upload-zone',
    templateUrl: 'file-upload-zone.component.html',
    styleUrls: ['file-upload-zone.component.scss']
})
export class FileUploadZoneComponent {
    //region Inputs

    /**
     *  Входящие данные для отображения
     */
    @Input()
    uploadState: UploadToRecognizeState;

    /**
     * Режим выбора нескольких файлов.
     */
    @Input()
    multipleFileMode: boolean = true;

    /**
     * Выбираемые типы файлов.
     */
    @Input()
    fileFormats: string[] = [
        ".jpeg",
        ".jpg",
        ".png",
        ".tiff",
        ".tif",
        ".bmp",
        ".pdf",
        ".doc",
        ".docx",
        ".odt",
        ".xls",
        ".xlsx",
        ".rtf",
        ".ods",
        ".zip",
        ".rar",
        ".7z"
    ];

    //endregion
    //region Outputs

    /**
     * Добавления файла к задаче.
     */
    @Output()
    addFiles = new EventEmitter<File[]>();

    /**
     * Удаление файла из задачи.
     */
    @Output()
    remove = new EventEmitter<File>();

    //endregion
    //region Fields

    /**
     * Кнопка диалога выбора файлов.
     */
    @ViewChild("uploadInput") input: ElementRef;

    /**
     * Дроп зона.
     */
    @ViewChild("uploadDropZone") dropZone: ElementRef;

    /**
     * Флаг, меняющий отображение, что можно сбросить файлы в область.
     */
    dropEnter: boolean;

    /**
     * Счетчик dragover события.
     */
    private _counter: number = 0;

    //endregion
    //region Getters and Setters

    /**
     * Возвращает доступные форматы файлов одной строкой.
     */
    get availableFileFormats(): string {

        return this.fileFormats.join(",");
    }

    get uploadButtonVisibility(): boolean {

        return this.multipleFileMode
            && this.uploadState.filesWithMetaData.every(file => !file.link)
            || this.uploadState.filesWithMetaData.length === 0;
    }

    //endregion
    //region Events

    /**
     * Изменение инпута файлов.
     */
    public onChange() {

        let files: File[] = Array.from(this.input.nativeElement.files);
        this._emitAddFiles(files);
        this.input.nativeElement.value = '';
    }


    public onDragOver(event: Event) {

        if (this.uploadButtonVisibility) {

            event.preventDefault();
        }

    }

    public onDragEnter(event: Event) {

        if (this.uploadButtonVisibility) {
            this._counter++;

            if (this._counter) {
                this.dropEnter = true;
            }
        }

    }

    public onDragLeave(event: Event) {

        if (this.uploadButtonVisibility) {
            this._counter--;

            if (!this._counter) {
                this.dropEnter = false;
            }
        }
    }

    public onDrop(event: any) {

        if (this.uploadButtonVisibility) {
            const files: File[] = Array.from(event.dataTransfer.files)
                .filter((file: File) => this.fileFormats.some(format => file.name.toLocaleLowerCase().endsWith(format)))
                .map((file: File) => file);
            this._emitAddFiles(files);
            event.preventDefault();
            event.stopPropagation();
            this.dropZone.nativeElement.value = '';
            this._counter = 0;
            this.dropEnter = false;
        }
    }

    //endregion
    //region Private

    /**
     * Обработчик требования добавить файлы в список для загрузки.
     *
     * - Если включена возможность выбора нескольких файлов - добавляет их.
     * - Иначе, добавляет первый файл, если еще нет файлов в списке для загрузки.
     *
     * @param files Файлы.
     */
    private _emitAddFiles(files: File[]) {

        const nonEmptyFiles = files.filter(file => !!file.size);

        if (nonEmptyFiles.length) {

            if (this.multipleFileMode) {

                this.addFiles.emit(nonEmptyFiles);
            }
            else if (!this.uploadState || !this.uploadState.filesWithMetaData.length) {

                this.addFiles.emit([nonEmptyFiles[0]]);
            }
        }
    }

    //endregion
}
