import { ApiResponse } from "src/app/common/models/api-response";
import { EnteraDocument } from "src/app/common/models/entera-document";
import { DocumentsService } from "src/app/common/services/documents.service";
import { DocumentRegistryColumnsSortState } from "src/app/spaces/modules/documents-registry/models/index";
import { DocumentRegistrySearchState } from "src/app/spaces/modules/documents-registry/models/index";
import { DocumentRegistryFiltersState } from "src/app/spaces/modules/documents-registry/models/index";
import { DocumentsActionType } from "src/app/spaces/modules/documents-registry/store/actions/index";
import { DocumentsAction } from "src/app/spaces/modules/documents-registry/store/actions/index";
import { AdminPermissionItem } from "src/app/common/models/admin-permission-item";

/**
 * Состояние документов.
 */
export interface DocumentsState {

    /**
     * Документы загружаются?
     */
    loading: boolean;

    /**
     * Документы загружены?
     */
    loaded: boolean;

    /**
     * Загрузка документов завершилась ошибкой?
     */
    failed: boolean;

    /**
     * Все документы загружены?
     */
    ended: boolean;

    /**
     * Документы.
     */
    documents: EnteraDocument[];

    /**
     * Новые документы.
     */
    newDocuments: EnteraDocument[];

    /**
     * Документы догружаются?
     */
    infinityLoading: boolean;

    /**
     * Документы догружены?
     */
    infinityLoaded: boolean;

    /**
     * Догрузка документов завершилась ошибкой?
     */
    infinityFailed: boolean;

    /**
     * Удаление документов завершилось ошибкой?
     */
    readonly deletionError: ApiResponse;

    /**
     * Последняя загруженная страница реестра документов.
     */
    readonly lastLoadPage: number;

    /**
     * Количество удаленных документов до догрузки документов.
     */
    readonly deletedDocumentsCount: number;

    /**
     * Список выбранных документов.
     */
    selectedDocuments: EnteraDocument[];

    /**
     * Были выбраны все документы?
     */
    allDocumentsSelected: boolean;

    /**
     * Список документов на исключения из общего списка при массовых действиях.
     */
    excludedDocuments: EnteraDocument[];

    /**
     * Включено ли отображение фильтра реестра документов?
     */
    showFilters: boolean;

    /**
     * Состояние фильтра.
     */
    filterState: DocumentRegistryFiltersState;

    /**
     * Состояние поиска.
     */
    searchState: DocumentRegistrySearchState;

    /**
     * Состояние сортировки.
     */
    sortState: DocumentRegistryColumnsSortState;

}

/**
 * Начальное состояние документов.
 */
const initialState: DocumentsState = {

    /**
     * Документы не загружаются.
     */
    loading: false,

    /**
     * Документы не загружены.
     */
    loaded: false,

    /**
     * Загрузка документов ошибкой не завершалась.
     */
    failed: false,

    /**
     * Все документы не загружены.
     */
    ended: false,

    /**
     * Документов нет.
     */
    documents: undefined,

    /**
     * Новых документов нет.
     */
    newDocuments: [],

    /**
     * Документы не догружаются.
     */
    infinityLoading: false,

    /**
     * Документы не догружены.
     */
    infinityLoaded: false,

    /**
     * Догрузка документов ошибкой не завершалась.
     */
    infinityFailed: false,

    /**
     * Удаление документов не завершилось ошибкой.
     */
    deletionError: null,

    /**
     * Последняя загруженная страница реестра документов.
     */
    lastLoadPage: 0,

    /**
     * Количество удаленных документов между загрузками документов.
     */
    deletedDocumentsCount: 0,

    /**
     * Список выбранных документов.
     */
    selectedDocuments: [],

    /**
     * Были выбраны все документы?
     */
    allDocumentsSelected: false,

    /**
     * Список документов на исключения из общего списка при массовых действиях.
     */
    excludedDocuments: [],

    /**
     * Отображение фильтра реестра документов отключено.
     */
    showFilters: false,

    /**
     * Фильтры отсутствуют.
     */
    filterState: null,

    /**
     * Фильтр поиска по строке отсутствует.
     */
    searchState: null,

    /**
     * Сортировка отсутствует.
     */
    sortState: null,
};

/**
 * Обработчик событий, связанных с документами.
 *
 * @param state Состояние документов.
 * @param action Событие произошедшее в системе.
 */
export function documentsReducer(state = initialState, action: DocumentsAction): DocumentsState {

    switch (action.type) {

        // Требование загрузить документы.
        case DocumentsActionType.LOAD: {

            return {
                ...state,
                documents: [],
                loading: true,
                loaded: false,
                failed: false,
                ended: false,
                infinityLoading: false,
                infinityLoaded: false,
                infinityFailed: false
            };
        }

        // Уведомление об успешной загрузке документов.
        case DocumentsActionType.LOAD_SUCCESS: {

            const documents = action.payload;
            return {
                ...state,
                lastLoadPage: 1,
                deletedDocumentsCount: 0,
                loading: false,
                loaded: true,
                documents,
                newDocuments: (state.newDocuments || []).filter(newDoc => documents.every(doc => doc.id !== newDoc.id)),
            };
        }

        // Уведомление о неудачной попытке загрузки документов.
        case DocumentsActionType.LOAD_FAIL: {

            return {
                ...state,
                loading: false,
                failed: true,
                documents: undefined
            };
        }

        // Требование догрузить документы.
        case DocumentsActionType.INFINITY_LOAD: {

            return {
                ...state,
                infinityLoading: true,
                infinityLoaded: false,
                infinityFailed: false
            };
        }

        // Уведомление об успешной догрузке документов.
        case DocumentsActionType.INFINITY_LOAD_SUCCESS: {

            const curDocuments = (state.documents || []);
            // Исключаем дублирование того, что уже загружено.
            const newDocuments = action.payload.filter(d => curDocuments.every(cd => cd.id !== d.id));

            const documents = [
                ...curDocuments,
                ...newDocuments
            ];
            let selectedDocuments = state.selectedDocuments;
            if (state.allDocumentsSelected) {

                selectedDocuments = [
                    ...(state.selectedDocuments || []),
                    ...newDocuments
                ];
            }

            return {
                ...state,
                lastLoadPage: (
                    state.lastLoadPage - Math.ceil(state.deletedDocumentsCount / DocumentsService.PAGE_SIZE) + 1
                ),
                deletedDocumentsCount: 0,
                loaded: true,
                failed: false,
                infinityLoading: false,
                infinityLoaded: true,
                documents,
                selectedDocuments
            };
        }

        // Уведомление о неудачной попытке догрузки документов.
        case DocumentsActionType.INFINITY_LOAD_FAIL: {

            return {
                ...state,
                infinityLoading: false,
                infinityFailed: true
            };
        }

        // Уведомление о полной загрузке документов.
        case DocumentsActionType.END_LOAD: {

            return {
                ...state,
                ended: true
            };
        }

        // Переключение отображения фильтра реестра документов
        case DocumentsActionType.TOGGLE_SHOW_FILTERS_FLAG: {

            return {
                ...state,
                showFilters: action.payload,
            };
        }

        // Применение фильтров
        case DocumentsActionType.APPLY_FILTER: {

            return {
                ...state,
                deletedDocumentsCount: 0,
                loading: false,
                loaded: true,
                filterState: action.payload,
                newDocuments: [],
            };
        }

        // Применение фильтров поиска
        case DocumentsActionType.APPLY_SEARCH_FILTER: {

            return {
                ...state,
                deletedDocumentsCount: 0,
                loading: false,
                loaded: true,
                searchState: action.payload,
                newDocuments: [],
            };
        }

        // Применение сортировки
        case DocumentsActionType.APPLY_SORT: {

            return {
                ...state,
                deletedDocumentsCount: 0,
                loading: false,
                loaded: true,
                sortState: action.payload,
            };
        }

        // Требование обновления определнных документов
        case DocumentsActionType.UPDATE_DOCUMENTS: {

            let mapFn = (item) => updateDocument(item, action.payload);
            let documents = state.documents.map(mapFn);

            return {
                ...state,
                documents
            }

        }

        case DocumentsActionType.ADD_NEW_DOCUMENTS: {

            const existDocuments = [...state.newDocuments, ...state.documents];
            const trulyNewDocuments = (action.documents || [])
                .filter((newDocument) => !existDocuments.some(existDocument => existDocument.id === newDocument.id));

            return {
                ...state,
                newDocuments: [...trulyNewDocuments, ...state.newDocuments],
            };
        }

        case DocumentsActionType.MARK_DOCUMENT: {

            if (state.allDocumentsSelected) {

                const excludedDocuments = state.excludedDocuments.filter(element => element.id !== action.document.id);

                return {
                    ...state,
                    selectedDocuments: [ ...state.selectedDocuments, action.document ],
                    excludedDocuments,
                };
            }

            return {
                ...state,
                selectedDocuments: [ ...state.selectedDocuments, action.document ],
            };
        }

        case DocumentsActionType.UPDATE_DOCUMENT_SEEN_FLAG: {

            const documents: EnteraDocument[] = state.documents
                .map(document => {

                    if (document.id === action.payload.value) {

                        return { ...document, isNew: false };
                    }

                    return document;
                });

            return {
                ...state,
                documents,
            };
        }

        case DocumentsActionType.MARK_ALL_DOCUMENTS: {

            return {
                ...state,
                selectedDocuments:  action.payload.documents,
                allDocumentsSelected: true,
                excludedDocuments: [],
            };
        }

        case DocumentsActionType.UNMARK_ALL_DOCUMENTS: {


            return  {
                ...state,
                selectedDocuments: [],
                allDocumentsSelected: false,
                excludedDocuments: [],
            };
        }

        case DocumentsActionType.UNMARK_DOCUMENT: {

            const selectedDocuments = state.selectedDocuments
                .filter(element => element.id !== action.document.id);

            if (state.allDocumentsSelected) {

                return {
                    ...state,
                    selectedDocuments,
                    excludedDocuments: [ ...state.excludedDocuments, action.document ]
                };
            }

            return {
                ...state,
                selectedDocuments,
            };
        }

        case DocumentsActionType.DOCUMENTS_MASS_ACTIONS_CLEAN_STATE: {

            return {
                ...state,
                selectedDocuments: [],
                excludedDocuments: [],
                allDocumentsSelected: false,
            };
        }

        // Требование удаления перемещенных документов.
        case DocumentsActionType.DELETE_MOVED_DOCUMENTS: {

            let documents: EnteraDocument[] = state.documents || [];
            let newDocuments: EnteraDocument[] = state.newDocuments || [];

            const adminView = action.payload.initiator.permissions
                .some((permission: string) => permission === AdminPermissionItem.MOVE_DOCUMENTS.value);

            if (!adminView) {

                documents = documents.filter((document: EnteraDocument) =>
                    !action.payload.documents.includes(document)
                );
                newDocuments = newDocuments
                    .map((document: EnteraDocument): EnteraDocument => setDeleting(
                        true,
                        document,
                        action.payload.documents.map(doc => doc.id),
                    ));
            }

            return {
                ...state,
                documents,
                newDocuments,
                deletionError: null,
            };
        }

        // Требование удаления определенных документов.
        case DocumentsActionType.DELETE_SELECTED_DOCUMENTS: {

            const documentsIds: string[] = getDocumentsIds(action.documents);
            let documents: EnteraDocument[] = state.documents
                .map((document: EnteraDocument): EnteraDocument => setDeleting(
                    true,
                    document,
                    documentsIds,
                ));
            let newDocuments: EnteraDocument[] = state.newDocuments
                .map((document: EnteraDocument): EnteraDocument => setDeleting(
                    true,
                    document,
                    documentsIds,
                ));
            return {
                ...state,
                documents,
                newDocuments,
                deletionError: null,
                selectedDocuments: [],
            };
        }

        // Уведомление об удачном удалении документов.
        case DocumentsActionType.DELETE_DOCUMENTS_SUCCESS: {

            let byFilter: boolean = action.props.byFilter;

            let documents: EnteraDocument[] = state.documents.filter((document: EnteraDocument): boolean =>
                (byFilter && !action.props.documentIds.find(documentId => documentId === document.id))
                || (!byFilter && !isDeleted(document, action.props.documentIds))
            );

            return {
                ...state,
                deletedDocumentsCount: state.deletedDocumentsCount + action.props.documentIds.length,
                documents: documents || [],
            };
        }

        // Уведомление о неудачном удалении документов.
        case DocumentsActionType.DELETE_DOCUMENTS_FAIL: {

            let documentsIds: string[] = getDocumentsIds(action.documents);
            let documents: EnteraDocument[] = state.documents
                .map((document: EnteraDocument): EnteraDocument => setDeleting(
                    false,
                    document,
                    documentsIds,
                ));
            let newDocuments: EnteraDocument[] = state.newDocuments
                .map((document: EnteraDocument): EnteraDocument => setDeleting(
                    false,
                    document,
                    documentsIds,
                ));

            return {
                ...state,
                documents,
                newDocuments,
                deletionError: action.apiResponse,
            };
        }
    }

    return state;
}

/**
 * Преобразовывает список документов в список их id.
 *
 * @param documents Список документов.
 *
 * @return Список id документов
 */
function getDocumentsIds(documents: EnteraDocument[]): string[] {

    return documents.map((document: EnteraDocument): string => document.id);
}

/**
 * Документ удален?
 * Документ удален, если у него deleting == true и его id есть в списке на удаление.
 *
 * @param stateDocument Документ из текущего состояния.
 * @param documentsId Список id удаляемых документов.
 *
 * @return Да/Нет.
 */
function isDeleted(stateDocument:EnteraDocument, documentsId: string[]): boolean {

    return (
        stateDocument.deleting
        && !!documentsId.find((documentId: string): boolean => documentId === stateDocument.id)
    );
}

/**
 * Документ удален?
 * Документ удален, если у него deleting == true и его id есть в списке на удаление.
 *
 * @param stateDocument Документ из текущего состояния.
 * @param documentsId Список id удаляемых документов.
 *
 * @return Да/Нет.
 */
function isFilteredDeleted(stateDocument:EnteraDocument, documentsId: string[]): boolean {

    return !!documentsId.find((documentId: string): boolean => documentId === stateDocument.id);
}

/**
 * Обновляет значение deleting документа, если id документа есть в списке.
 *
 * @param deleting Устанавливаемое значение.
 * @param stateDocument Документ из текущего состояния.
 * @param documentsId Список id удаляемых документов.
 *
 * @return Обновленный документ.
 */
function setDeleting(deleting: boolean, stateDocument: EnteraDocument, documentsId: string[]): EnteraDocument {

    const inDocumentsIds: boolean = documentsId.some((id: string): boolean => id === stateDocument.id);
    if (inDocumentsIds) {

        stateDocument = {
            ...stateDocument,
            deleting,
        };
    }

    return stateDocument;
}

/**
 * Функция обновления стейта документа. Либо документ из массива обновленных либо тот который пришел из стейта.
 *
 * @param stateDocument документ из текущего стейта.
 * @param updated обновленные документы.
 */
function updateDocument(stateDocument: EnteraDocument, updated: EnteraDocument[]) {
    let updatedDocument = updated.find(item => item.id === stateDocument.id);
    return updatedDocument ? updatedDocument : stateDocument;
}

/**
 * Возвращает документы из состояния документов.
 *
 * @param state Состояние документов.
 */
export const getDocuments = (state: DocumentsState) => state.documents;

/**
 * Возвращает новые документы из состояния документов.
 *
 * @param state Состояние новых документов.
 */
export const getNewDocuments = (state: DocumentsState) => state.newDocuments
    .filter((_) => state.searchState.isEmpty())
    .filter((newDocument) => !state.filterState || state.filterState.matchFilterState(newDocument));

/**
 * Возвращает скрытые фильтром новые документы из состояния документов.
 *
 * @param state Состояние новых документов.
 */
export const getHiddenNewDocuments = (state: DocumentsState) => state.newDocuments
    .filter((_) => state.searchState.isEmpty())
    .some(newDocument => state.filterState && !state.filterState.matchFilterState(newDocument))

/**
 * Возвращает флаг выполняющейся загрузки документов из состояния документов.
 *
 * @param state Состояние документов.
 */
export const getDocumentsLoading = (state: DocumentsState) => state.loading;

/**
 * Возвращает флаг успешно выполненной загрузки документов из состояния документов.
 *
 * @param state Состояние документов.
 */
export const getDocumentsLoaded = (state: DocumentsState) => state.loaded;

/**
 * Возвращает флаг неудачной попытки загрузки документов из состояния документов.
 *
 * @param state Состояние документов.
 */
export const getDocumentsFailed = (state: DocumentsState) => state.failed;

/**
 * Возвращает флаг выполняющейся догрузки документов из состояния документов.
 *
 * @param state Состояние документов.
 */
export const getDocumentsInfinityLoading = (state: DocumentsState) => state.infinityLoading;

/**
 * Возвращает флаг успешно выполненной догрузки документов из состояния документов.
 *
 * @param state Состояние документов.
 */
export const getDocumentsInfinityLoaded = (state: DocumentsState) => state.infinityLoaded;

/**
 * Возвращает флаг неудачной попытки догрузки документов из состояния документов.
 *
 * @param state Состояние документов.
 */
export const getDocumentsInfinityFailed = (state: DocumentsState) => state.infinityFailed;

/**
 * Возвращает флаг полной загрузки документов из состояния документов.
 *
 * @param state Состояние документов.
 */
export const getDocumentsEnded = (state: DocumentsState) => state.ended;

/**
 * Возвращает общую часть ответа от API при неудачной попытке удаления документов из состояния документов.
 *
 * @param state Состояние документов.
 */
export const getDeletionError = (state: DocumentsState) => state.deletionError;
