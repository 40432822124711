import { ElementRef } from "@angular/core";
import { ViewChild } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Output } from "@angular/core";
import { Input } from "@angular/core";
import { Component } from "@angular/core";
import { ApiResponse } from "src/app/common/models/api-response";
import { UploadToRecognizeState } from "src/app/root/store/reducers/index";

/**
 * Компонент кнопки загрузки файла для отправки на распознавание.
 */
@Component({
    selector: "mobile-document-upload-file-button",
    styleUrls: ["./mobile-document-upload-file-button.component.scss"],
    templateUrl: "./mobile-document-upload-file-button.component.html"
})
export class MobileDocumentUploadFileButtonComponent {
    //region Inputs

    /**
     * Флаг загрузки файлов в массив файлов для отправки на распознавание.
     */
    @Input()
    loading: boolean;

    /**
     * Есть ли файлы на загрузку на распознавание?
     */
    @Input()
    hasOnlyFiles: boolean;

    /**
     * Ошибка, возникшая при отправке файлов на распознавание.
     */
    @Input()
    error: ApiResponse;

    //endregion
    //region Outputs

    /**
     * Добавление файла в массив файлов для отправки на распознавание.
     */
    @Output()
    addFiles = new EventEmitter<File[]>();

    //endregion
    //region Fields

    /**
     * Контрол для добавления файлов.
     */
    @ViewChild("uploadInput")
    input: ElementRef;

    //endregion
    //region Events

    /**
     * Обработчик изменения инпута файлов.
     */
    onChange() {

        let files: File[] = Array.from(this.input.nativeElement.files);
        this.emitAddFiles(files);
        this.input.nativeElement.value = "";
    }

    /**
     * Обработчик загрузки файлов в массив файлов для отправки на распознавание.
     *
     * @param files Файлы для добавления в массив файлов для отправки на распознавание.
     */
    private emitAddFiles(files: File[]) {

        this.addFiles.emit(files);
    }

    //endregion
}
