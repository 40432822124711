<div class="user-info-menu"
>
        <mat-icon
            class="user-info-menu__icon"
            (mouseenter)="hovered = true"
            (mouseleave)="hovered = false"
            [matMenuTriggerFor]="infoMenu"
        >
            {{hovered ? "person" : "person_outline"}}
        </mat-icon>
</div>
<mat-menu
    #infoMenu
    xPosition="before"
>
        <span
            mat-menu-item
            class="user-info-menu__title"
            (click)="$event.stopPropagation();"
        >
            {{userName}}
        </span>
    <button *ngFor="let menuItem of menuItems; i as index;"
            mat-menu-item
            [class.mat-menu-item_right-icon]="menuItem.rightIcon"
            [class.mat-menu-item_selected]="menuItem.selected"
            [class.mat-menu-item_green]="menuItem.green"
            (click)="menuItemClickHandler(menuItem.id)"
            [disabled]="menuItem.disabled"
    >
        <mat-icon *ngIf="menuItem.icon"
                  [class.mat-icon_green]="menuItem.green"
        >{{menuItem.icon}}</mat-icon>
        <span
            [innerHtml]="menuItem.text ? menuItem.text : (menuItem.messageKey | translate)"
        ></span>
    </button>
</mat-menu>
