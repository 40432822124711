import { Directive } from '@angular/core';
import { Input } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { NG_VALIDATORS } from "@angular/forms";
import { ValidationErrors } from "@angular/forms";
import { Validator } from "@angular/forms";
import { ValidatorFn } from '@angular/forms';
import { AbstractControl } from '@angular/forms';

/**
 * Валидатор для проверки, что заданные поля имеют одинаковое значение.
 *
 * Данный валидатор устанавливается на всю форму или на FormGroup при её создании.
 *
 * Пример использования, когда при задании пароля нужно ввести его повторно.
 */
@Directive({
    selector: '[same-values]',
    providers: [ { provide: NG_VALIDATORS, useExisting: SameValuesValidator, multi: true } ]
})
export class SameValuesValidator implements Validator {
    //region Inputs

    /**
     * Входящие данные - поля формы, значения в которых должны быть идентичны.
     */
    @Input()
    sameValueFields: string[];

    /**
     * Входящие данные - поля формы, которые нужно пометить как невалдиные, если значения в заданных полях формы
     * не совпадают.
     */
    @Input()
    sameValueErrorFields: string[] = [];

    //endregion
    //region Public static

    /**
     * Возвращает функцию валидации, что заданные поля имеют одинаковое значение.
     *
     * @param sameValueFields Поля формы, значения в которых должны быть идентичны.
     * @param sameValueErrorFields Поля формы, которые нужно пометить как невалдиные, если значения в заданных
     * полях формы не совпадают.
     *
     * @return Функция валидации, что заданные поля имеют одинаковое значение.
     */
    static get(sameValueFields: string[] = [], sameValueErrorFields: string[] = []): ValidatorFn {

        return (group: FormGroup) => {

            let result = null;

            if (group && sameValueFields) {

                // Определяем все ли заданные поля имеют одинаковое значение.
                const hasSameValue = sameValueFields
                    .map((fieldName: string): AbstractControl => group.get(fieldName))
                    .filter(Boolean)
                    .map((control: AbstractControl): string => control.value)
                    .every((value: string, index: number, array: string[]): boolean =>
                        index < 1 || value === array[index - 1]
                    );

                // Да, все заданные поля имеют одинаковое значение, снимаем ошибки валидации с нужных полей.
                if (hasSameValue) {

                    sameValueErrorFields
                        .map((fieldName: string): AbstractControl => group.get(fieldName))
                        .filter(Boolean)
                        .forEach((control: AbstractControl): void => {

                            let errors = { ...control.errors };
                            delete errors.sameValues;

                            if (!Object.keys(errors).length) {

                                errors = null;
                            }

                            control.setErrors(errors, { emitEvent: true })
                        });
                }
                // Нет, не заданные поля имеют одинаковое значение, выставляем ошибки нужным полям.
                else {

                    result = {
                        "sameValues": true
                    };

                    sameValueErrorFields
                        .map((fieldName: string): AbstractControl => group.get(fieldName))
                        .filter(Boolean)
                        .forEach((control: AbstractControl): void => control.setErrors(
                            {
                                ...control.errors,
                                "sameValues": true
                            },
                            { emitEvent: true }
                        ));
                }
            }

            return result;
        };
    }

    //endregion
    //region Public

    /**
     * Выполняет валидацию, что заданные поля имеют одинаковое значение.
     *
     * @param group Группа полей формы.
     *
     * @return Результат валидации.
     */
    validate(group: FormGroup): ValidationErrors | null {

       return SameValuesValidator.get(this.sameValueFields, this.sameValueErrorFields)(group);
    }

    //endregion
}
