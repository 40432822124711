import { Action } from "@ngrx/store";
import { ApiResponse } from "src/app/common/models/api-response";
import { EnteraDocument } from "src/app/common/models/entera-document";
import { CurrentUserInfo } from "src/app/common/models/index";
import { MoveDocumentsActionProps } from "src/app/common/store/actions/props/move-documents.action-props";

import { ApiResponsePayloadAction } from "src/app/root/store";
import { ValueProps } from "src/app/root/store/actions/props/value-props";
import { DocumentsRegistryExportType } from "src/app/spaces/modules/documents-registry/models/documents-registry-export-type";
import { DocumentRegistryColumnsSortState } from "src/app/spaces/modules/documents-registry/models/index";
import { DocumentRegistrySearchState } from "src/app/spaces/modules/documents-registry/models/index";
import { DocumentRegistryFiltersState } from "src/app/spaces/modules/documents-registry/models/index";
import { DeletedDocumentsProps } from "src/app/spaces/modules/documents-registry/store/actions/props/deleted-documents-props";

/**
 * Типы событий, связанные с документами.
 */
export enum DocumentsActionType {
    LOAD = '[Documents registry] Load documents',
    LOAD_SUCCESS = '[Documents registry] Load documents Success',
    LOAD_FAIL = '[Documents registry] Load documents Fail',
    INFINITY_LOAD = '[Documents registry] Infinity load documents',
    INFINITY_LOAD_SUCCESS = '[Documents registry] Infinity load documents Success',
    INFINITY_LOAD_FAIL = '[Documents registry] Infinity load documents Fail',
    END_LOAD = '[Documents registry] End load documents',
    TOGGLE_SHOW_FILTERS_FLAG = '[Documents registry] Toggle show filters flag',
    APPLY_FILTER = '[Documents registry] Apply filter',
    APPLY_SEARCH_FILTER = '[Documents registry] Apply search filter',
    APPLY_SORT = '[Documents registry] Apply sort',
    UPDATE_DOCUMENTS = '[Documents registry] Update documents',
    ADD_NEW_DOCUMENTS = '[Document registry] Add new documents',
    MARK_DOCUMENT = "[Documents registry] Mark document",
    UNMARK_DOCUMENT = "[Documents registry] Unmark document",
    MARK_ALL_DOCUMENTS = "[Documents registry] Mark all documents",
    UNMARK_ALL_DOCUMENTS = "[Document registry] Unmark all documents",
    DOCUMENTS_MASS_ACTIONS_FAIL = "[Document registry] Documents mass actions fail",
    DOCUMENTS_MASS_ACTIONS_CLEAN_STATE = "[Document registry] Documents mass actions clean state",
    UPDATE_DOCUMENT_SEEN_FLAG = "[Document registry] Update document seen flag",
    DELETE_DOCUMENTS = '[Documents registry] Delete documents',
    DELETE_SELECTED_DOCUMENTS = '[Documents registry] Delete selected documents',
    DELETE_MOVED_DOCUMENTS = '[Documents registry] Delete moved documents',
    DELETE_DOCUMENTS_SUCCESS = '[Documents registry] Delete documents Success',
    DELETE_DOCUMENTS_FAIL = '[Documents registry] Delete documents Fail',
    EXPORT_TO_EXCEL = '[Document registry] Export to excel',
    EXPORT_DOCUMENT_TO_EXCEL = '[Document registry] Export one document to excel',
    OPEN_MOVE_DOCUMENTS_DLG = '[Documents registry] Open move documents dialog',
    OPEN_ARCHIVE_DLG = '[Documents registry] Open archive dialog',
}

/**
 * Событие, требующее загрузки документов.
 */
export class DocumentsLoadAction implements Action {
    readonly type = DocumentsActionType.LOAD;
    // TODO Определить интерфейс запроса документов.
    constructor() { }
}

/**
 * Событие успешной загрузки документов.
 */
export class DocumentsLoadSuccessAction implements Action {
    readonly type = DocumentsActionType.LOAD_SUCCESS;
    constructor(public payload: EnteraDocument[]) { }
}

/**
 * Событие неудачной загрузки документов.
 */
export class DocumentsLoadFailAction implements ApiResponsePayloadAction {
    readonly type = DocumentsActionType.LOAD_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Событие, требующее догрузки документов.
 */
export class DocumentsInfinityLoadAction implements Action {
    readonly type = DocumentsActionType.INFINITY_LOAD;
    // TODO Определить интерфейс запроса документов.
    constructor() { }
}

/**
 * Событие успешной догрузки документов.
 */
export class DocumentsInfinityLoadSuccessAction implements Action {
    readonly type = DocumentsActionType.INFINITY_LOAD_SUCCESS;
    constructor(public payload: EnteraDocument[]) { }
}

/**
 * Событие неудачной догрузки документов.
 */
export class DocumentsInfinityLoadFailAction implements ApiResponsePayloadAction {
    readonly type = DocumentsActionType.INFINITY_LOAD_FAIL;
    constructor(public payload: ApiResponse) { }
}

/**
 * Событие, обозначающее, что все документы загружены с сервера.
 */
export class DocumentsEndLoadAction implements Action {
    readonly type = DocumentsActionType.END_LOAD;
    constructor() { }
}

/**
 * Событие переключения отображения фильтра реестра документов.
 */
export class ToggleShowFiltersFlag implements Action {
    readonly type = DocumentsActionType.TOGGLE_SHOW_FILTERS_FLAG;
    constructor(public payload: boolean) { }
}

/**
 * Событие применения фильтра к реестру документов.
 */
export class ApplyDocumentsFilter implements Action {
    readonly type = DocumentsActionType.APPLY_FILTER;
    constructor(public payload: DocumentRegistryFiltersState) { }
}

/**
 * Событие применения фильтра поиска по строке к реестру документов.
 */
export class ApplyDocumentsSearchFilter implements Action {
    readonly type = DocumentsActionType.APPLY_SEARCH_FILTER;
    constructor(public payload: DocumentRegistrySearchState) { }
}

/**
 * Событие применения сортировки к реестру документов.
 */
export class ApplyDocumentsSort implements Action {
    readonly type = DocumentsActionType.APPLY_SORT;
    constructor(public payload: DocumentRegistryColumnsSortState) { }
}

/**
 * Событие обновление конкртеных документов в списке.
 */
export class DocumentsUpdateAction implements Action {
    readonly type = DocumentsActionType.UPDATE_DOCUMENTS;
    constructor(public payload: EnteraDocument[]) { }
}

/**
 * Событие удаления документов.
 */
export class DeleteDocumentsAction implements Action {
    readonly type = DocumentsActionType.DELETE_DOCUMENTS;
    constructor() { }
}

/**
 * Событие успешного удаления документов.
 */
export class DeleteDocumentsSuccessAction implements Action {
    readonly type = DocumentsActionType.DELETE_DOCUMENTS_SUCCESS;
    constructor(public props: DeletedDocumentsProps) { }
}

/**
 * Событие неудачного удаления документов.
 */
export class DeleteDocumentsFailAction implements Action {
    readonly type = DocumentsActionType.DELETE_DOCUMENTS_FAIL;
    constructor(
        public documents: EnteraDocument[],
        public apiResponse: ApiResponse,
    ) { }
}

/**
 * Событие удаления выбранных документов.
 */
export class DeleteSelectedDocumentsAction implements Action {
    readonly type = DocumentsActionType.DELETE_SELECTED_DOCUMENTS;
    constructor(public documents: EnteraDocument[]) { }
}

/**
 * Событие удаления перемещенных документов из папки.
 */
export class MovedDocumentsDeleteAction implements Action {
    readonly type = DocumentsActionType.DELETE_MOVED_DOCUMENTS;
    constructor(public payload: MoveDocumentsActionProps) { }
}

/**
 * Событие скачивания документов в реестр Excel.
 */
export class ExportToExcelAction implements Action {
    readonly type = DocumentsActionType.EXPORT_TO_EXCEL;
    constructor(public payload: { registryType: DocumentsRegistryExportType }) { }
}

/**
 * Событие скачивания документа в реестр Excel.
 */
export class ExportDocumentToExcelAction implements Action {
    readonly type = DocumentsActionType.EXPORT_DOCUMENT_TO_EXCEL;
    constructor(public payload: { registryType: DocumentsRegistryExportType, documentId: string }) { }
}

/**
 * Событие открытия диалога переноса документов между папками.
 */
export class OpenMoveDocumentsDlgAction implements Action {
    readonly type = DocumentsActionType.OPEN_MOVE_DOCUMENTS_DLG;
    constructor(public payload: { user: CurrentUserInfo }) { }
}

/**
 * Событие открытия диалога архива реестра документов.
 */
export class OpenArchiveDlg implements Action {
    readonly type = DocumentsActionType.OPEN_ARCHIVE_DLG;
    constructor() { }
}

/**
 * Событие добавления новых документов после распознавания в список новых документов.
 */
export class AddNewDocumentsAction implements Action {
    readonly type = DocumentsActionType.ADD_NEW_DOCUMENTS;
    constructor(public documents: EnteraDocument[]) { }
}

/**
 * Событие добавления документа в список документов с заполненным чекбоксом.
 */
export class DocumentMarkAction implements Action {
    readonly type = DocumentsActionType.MARK_DOCUMENT;
    constructor(public document: EnteraDocument) { }
}

/**
 * Событие добавления всех документов в список документов с заполненным чекбоксом.
 */
export class DocumentsMarkAction implements Action {
    readonly type = DocumentsActionType.MARK_ALL_DOCUMENTS;
    constructor(public payload: any) { }
}

/**
 * Событие очистки стейта документов после массовых действий.
 */
export class DocumentsMassActionsCleanStateAction implements Action {
    readonly type = DocumentsActionType.DOCUMENTS_MASS_ACTIONS_CLEAN_STATE;
    constructor() { }
}

/**
 * Событие неудачных массовых действий с документами.
 */
export class DocumentsMassActionsFailAction implements Action {
    readonly type = DocumentsActionType.DOCUMENTS_MASS_ACTIONS_FAIL;
    constructor(public apiResponse: ApiResponse) { }
}

/**
 * Событие обновления флага того, просмотрен ли документ.
 */
export class UpdateDocumentSeenFlagAction implements Action {
    readonly type = DocumentsActionType.UPDATE_DOCUMENT_SEEN_FLAG;
    constructor(public payload: ValueProps<string>) { }
}

/**
 * Событие удаления всех документов из списка документов с заполненным чекбоксом.
 */
export class DocumentsUnmarkAction implements Action {
    readonly type = DocumentsActionType.UNMARK_ALL_DOCUMENTS;
    constructor() { }
}

/**
 * Событие удаления документа из списка документов с заполненным чекбоксом.
 */
export class DocumentUnmarkAction implements Action {
    readonly type = DocumentsActionType.UNMARK_DOCUMENT;
    constructor(public document: EnteraDocument) { }
}

/**
 * Тип, объединяющий все события, связанные с документами.
 */
export type DocumentsAction =
    | DocumentsLoadAction
    | DocumentsLoadFailAction
    | DocumentsLoadSuccessAction
    | DocumentsInfinityLoadAction
    | DocumentsInfinityLoadFailAction
    | DocumentsInfinityLoadSuccessAction
    | DocumentsEndLoadAction
    | ToggleShowFiltersFlag
    | ApplyDocumentsFilter
    | ApplyDocumentsSearchFilter
    | ApplyDocumentsSort
    | DocumentsUpdateAction
    | MovedDocumentsDeleteAction
    | ExportToExcelAction
    | ExportDocumentToExcelAction
    | AddNewDocumentsAction
    | DocumentsMarkAction
    | DocumentsUnmarkAction
    | DocumentUnmarkAction
    | DocumentMarkAction
    | DocumentsMassActionsCleanStateAction
    | DocumentsMassActionsFailAction
    | UpdateDocumentSeenFlagAction
    | DeleteSelectedDocumentsAction
    | DeleteDocumentsSuccessAction
    | DeleteDocumentsFailAction
    | DeleteDocumentsAction
    | OpenMoveDocumentsDlgAction
    | OpenArchiveDlg;
