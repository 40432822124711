import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { tap } from 'rxjs/operators';

import { BankDTO } from '../models';
import { ApiResponse } from '../models';
import { BanksResponse } from '../models';

/**
 * Сервис для работы с банками.
 */
@Injectable({
    providedIn: "root"
})
export class BankService {
    //region Private fields

    /**
     * HTTP-клиент.
     *
     * @private
     */
    private _http: HttpClient;

    //endregion
    //region Ctor

    constructor(
        http: HttpClient
    ) {
        this._http = http;
    }

    //endregion
    //region Public

    /**
     * Выполняет поиск банков по заданному поисковому запросу.
     *
     * @param search Поисковой запрос.
     *
     * @return Список банков, которые подошли под поисковой запрос.
     */
    search(search: string): Observable<BankDTO[]> {

        return this._http
            .get<BanksResponse>('/api/v1/banks', { params: { search } })
            .pipe(
                map((response: BanksResponse) => response.banks),
                tap((banks: BankDTO[]) => banks.forEach((bank: BankDTO) => bank.id = bank.bic)),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
            )
    }

    //endregion
}
