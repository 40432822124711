import { ViewChild } from "@angular/core";
import { OnDestroy } from "@angular/core";
import { Input } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { ValidationErrorSeverityType } from "src/app/common/models/validation-error-severity-type";
import { MatTooltip } from "@angular/material/tooltip";

/**
 * Компонент блока с ошибкой валидации.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "notification-with-validation",
    templateUrl: "./notification-with-validation.component.html",
    styleUrls: ["./notification-with-validation.component.scss"],
})
export class NotificationWithValidationComponent implements OnDestroy {
    //region Inputs

    /**
     * Наименование.
     */
    @Input()
    name: string;

    /**
     * Уровень критичности.
     */
    @Input()
    severity: ValidationErrorSeverityType;

    /**
     * Текст тултипа
     */
    @Input()
    tooltipText: string;

    //endregion
    //region Fields

    /**
     * DOM-элемент тултипа.
     */
    @ViewChild("notificationTooltip", { read: MatTooltip })
    notificationTooltip: MatTooltip;

    //endregion
    //region Hooks

    ngOnDestroy() {

        this.notificationTooltip.hide();
    }

    //endregion
    //region Events

    /**
     * Обработчик события нажатия по иконке открытия тултипа.
     */
    openTooltipHandler() {

        this.notificationTooltip.show();
    }
    //endregion
}
