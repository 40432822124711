import {PermissionItem} from "src/app/common/models/permission-item";

/**
 * Право клиента на какую-либо операцию.
 */
export class ClientPermissionItem implements PermissionItem {
    //region Permissions Client

    /**
     * Права пользователей клиента.
     */
    public static readonly CLIENT = new ClientPermissionItem("CLIENT");

    /**
     * Право на создание нового пространство документов.
     */
    public static readonly CREATE_SPACE = new ClientPermissionItem("CREATE_SPACE", ClientPermissionItem.CLIENT);

    /**
     * Право изменять пространства документов.
     */
    public static readonly EDIT_SPACE = new ClientPermissionItem("EDIT_SPACE", ClientPermissionItem.CLIENT);

    //endregion
    //region Fields

    /**
     * Ключ права.
     */
    readonly value: string;

    /**
     * Родительское право.
     */
    readonly parent: ClientPermissionItem;

    //endregion
    //region Ctor

    /**
     * Конструктор права клиента.
     *
     * @param value Ключ права.
     * @param parent Родительское право.
     */
    private constructor(value: string, parent: ClientPermissionItem = null) {
        this.value = value;
        this.parent = parent;
    }

    //endregion
    //region Getters and Setters

    /**
     * I18n-ключ названия права.
     */
    nameKey(): string {

        return "ClientPermissionItem." + this.value;
    }

    //endregion
}
