import { Component } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Inject } from "@angular/core";
import { Input } from "@angular/core";
import { Output } from "@angular/core";
import { OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { ValidationErrors } from "@angular/forms";
import { AbstractControl } from "@angular/forms";
import { FormBuilder } from "@angular/forms";

import { FormControl } from '@angular/forms';

import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";

import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { spaceSelector } from "rootStore";

import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UploadToRecognizeState } from "rootStore";
import { RootState } from 'rootStore';
import { currentUserPermissionsSelector } from 'rootStore';
import { Space } from "src/app/common/models/index";
import { UploadDialogData } from "src/app/common/models/upload-dialog-data";

import { UtilsService } from "../../services";

/**
 * Компонент диалога для загрузки файлов на распознавание.
 */
@Component({
    styleUrls: ['upload-to-recognize-dialog.component.scss'],
    templateUrl: 'upload-to-recognize-dialog.component.html'
})
export class UploadToRecognizeDialogComponent implements OnInit, OnDestroy {
    //region Constants

    /**
     * Список допустимых расширений файлов для распознавания.
     */
    public static readonly RECOGNITION_ALLOWED_EXTENSIONS = [
        ".jpeg",
        ".jpg",
        ".png",
        ".tiff",
        ".tif",
        ".bmp",
        ".pdf",
        ".doc",
        ".docx",
        ".odt",
        ".xls",
        ".xlsx",
        ".rtf",
        ".ods",
        ".zip",
        ".rar",
        ".7z"
    ];

    /**
     * Список допустимых расширений файлов для распознавания выписок из банков.
     */
    public static readonly BANK_STATEMENT_ALLOWED_EXTENSIONS = [".pdf", ".xls", ".xlsx"];

    /**
     * Регекс ссылки чека самозанятых с условием, что после /receipt/ идет ровно 12 символов и после / и затем 10
     * символов.
     */
    private static readonly SELF_EMPLOYED_PATTERN: RegExp =
        /(?:http[s]?:\/\/)?lknpd\.nalog\.ru\/api\/v1\/receipt\/\d{12}\/[a-zA-Z0-9]{10}(\/print\/?|\/?)?/g;

    //endregion
    //region Inputs

    /**
     *  Входящие данные - состояние данных для создания задач на распознавание.
     */
    @Input()
    set uploadState(value: UploadToRecognizeState) {

        this._uploadState = value;

        if (this.data.dlgType === "RECOGNITION" || this.data.dlgType === "SELF_EMPLOYED") {

            this.comment.setValue(value.comment);
        }
        if (this.data.dlgType === "RECOGNITION") {

            this.forceOcr.setValue(value.forceOcrRecognition);
            this.forceProcessing.setValue(value.forceProcessing);
            this.forceQueue.setValue(value.forceQueue);
        }
        if (this.data.dlgType === "SELF_EMPLOYED") {

            if (value && value.filesWithMetaData.length && value.filesWithMetaData.some(file => !file.link)) {

                this.linkInputFormCtrl.setValue(null);
                this.linkInputFormCtrl.disable({ emitEvent: false });
            }
            else {

                this.linkInputFormCtrl.enable({ emitEvent: false });
            }
        }
    }

    //endregion
    //region Outputs

    /**
     * Исходящее событие - создание задач на распознавание.
     */
    @Output()
    upload = new EventEmitter<UploadToRecognizeState>();

    /**
     * Исходящее событие - добвление файла на распознавание.
     */
    @Output()
    add = new EventEmitter<File[]>();

    /**
     * Исходящее событие - добавление ссылок на скачивание файлов.
     */
    @Output()
    addLinks = new EventEmitter<string[]>();

    /**
     * Исходящее событие - удаление файла из задачи.
     */
    @Output()
    remove = new EventEmitter<File>();

    /**
     * Исходящее событие - изменение флага необходимости выполнения OCR.
     */
    @Output()
    toggleForceOcr = new EventEmitter<boolean>();

    /**
     * Исходящее событие - изменение флага необходимости выполнения парсинга.
     */
    @Output()
    toggleForceProcessing = new EventEmitter<boolean>();

    /**
     * Исходящее событие - изменение флага необходимости отправки в очередь.
     */
    @Output()
    toggleForceQueue = new EventEmitter<boolean>();

    /**
     * Исходящее событие - изменение комментария к документам на распознования.
     */
    @Output()
    commentEmitter = new EventEmitter<string>();

    //endregion
    //region Public fields

    /**
     * Чек-бокс принудительного выполнения OCR.
     */
    forceOcr: FormControl = new FormControl(false);

    /**
     * Чек-бокс принудительного выполнения парсинга.
     */
    forceProcessing: FormControl = new FormControl(false);

    /**
     * Чек-бокс принудительной отправки в очередь.
     */
    forceQueue: FormControl = new FormControl(false);

    /**
     * Комментарий к документам на распознавания.
     */
    comment: FormControl;

    /**
     * Реактивный контрол ссылки чека самозанятых на распознавание.
     */
    linkInputFormCtrl: FormControl;

    /**
     * Права текущего пользователя досутпные ему независимо от пространства документов.
     */
    permissions$: Observable<{ [key: string]: boolean }>;

    /**
     * Текущая папка.
     */
    currentSpace$: Observable<Space>;

    //endregion
    //region Private fields

    /**
     * Состояние приложения.
     */
    private _store: Store<RootState>;

    /**
     * Состояние данных для создания задач на распознавание.
     *
     * @private
     */
    private _uploadState: UploadToRecognizeState;

    /**
     * Переключатель, чтобы при выполнении onDestroy произошли отписки от всех подписок.
     *
     * @private
     */
    private _destroyed: Subject<void> = new Subject();

    //endregion
    //region Ctor

    constructor(
        store: Store<RootState>,
        public dialogRef: MatDialogRef<UploadToRecognizeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UploadDialogData,
        public utils: UtilsService,
        private _fb: FormBuilder
    ) {
        this._store = store;

        this.comment = new FormControl("");
        this.linkInputFormCtrl = this._fb.control("", ctrl => this._linkHasIncorrectPartsValidator(ctrl));
    }

    //endregion
    //region Hooks

    ngOnInit(): void {

        // Следим за правами пользователя.
        this.permissions$ = this._store.pipe(
            select(currentUserPermissionsSelector)
        );

        // Подписываемся на изменение флага принудительного выполнения OCR.
        this.forceOcr.valueChanges
            .pipe(
                takeUntil(this._destroyed)
            )
            .subscribe((value: boolean) => {

                if (this.uploadState.forceOcrRecognition !== value) {

                    this.toggleForceOcr.emit(value);
                }
            });

        // Подписываемся на изменение флага принудительного выполнения парсинга.
        this.forceProcessing.valueChanges
            .pipe(
                takeUntil(this._destroyed)
            )
            .subscribe((value: boolean) => {

                if (this.uploadState.forceProcessing !== value) {

                    this.toggleForceProcessing.emit(value);
                }
            });

        // Подписываемся на изменение флага принудительной отправки в очередь.
        this.forceQueue.valueChanges
            .pipe(
                takeUntil(this._destroyed)
            )
            .subscribe((value: boolean) => {

                if (this.uploadState.forceQueue !== value) {

                    this.toggleForceQueue.emit(value);
                }
            });

        // Подписываемся на изменение комментария.
        this.comment.valueChanges
            .pipe(takeUntil(this._destroyed))
            .subscribe((value: string) => {

                if (this.uploadState.comment !== value) {

                    this.commentEmitter.emit(value);
                }
            });

        this.linkInputFormCtrl.valueChanges
            .pipe(takeUntil(this._destroyed))
            .subscribe((value: string) => {

                this.addLinksHandler(value);
            });

        this.currentSpace$ = this._store.pipe(
            select(spaceSelector),
        );
    }

    ngOnDestroy(): void {

        this._destroyed.next();
        this._destroyed.complete();
    }

    //endregion
    //region Getters and Setters

    /**
     * Состояние данных для создания задач на распознавание.
     */
    get uploadState(): UploadToRecognizeState {

        return this._uploadState;
    }

    /**
     * Состояние данных позволяет отправить файлы на распознавание?
     */
    get canUploadFiles(): boolean {

        return this.uploadState
            && !this.uploadState.error
            && this.uploadState.progress !== 100
            && !this.uploadState.duplicateCheckingByHash
            && (this.data.dlgType === "SELF_EMPLOYED" || !!this.uploadState.filesWithMetaData.length);
    }

    /**
     * Есть ли документы, которые были загружены как файлы.
     */
    get hasFiles(): boolean {

        return this.uploadState && (this.uploadState.filesWithMetaData || []).some(file => !file.link);
    }

    //endregion
    //region Events

    /**
     * Обработчик добавления файлов. Фильтрует файлы которые уже есть в состоянии.
     *
     * @param event Файлы, которые нужно добавить.
     */
    addFilesHandler(event: File[]) {

        const newFiles = event.filter(fileToAdd => this._uploadState.filesWithMetaData.every(file =>
            file.file.name !== fileToAdd.name
            || file.file.size !== fileToAdd.size
            || file.file.lastModified !== fileToAdd.lastModified
        ));

        if (newFiles.length) {

            this.add.emit(newFiles);
        }
    }

    /**
     * Обрабатывает событие изменения ссылок в инпуте.
     *
     * @param value Строка для разбития на ссылки.
     */
    addLinksHandler(value: string) {

        const trimmedValue: string = value && value.replace(/\s+/g, "") || "";
        let links: string[] = trimmedValue.match(UploadToRecognizeDialogComponent.SELF_EMPLOYED_PATTERN) || [];

        links = links
            .map(link => link.replace(/\/+$/g, ""))
            .map(link => link.replace(/^\/+/g, ""))
            .map(link => link.endsWith("/print") ? link : `${link}/print`)
            .map(link => link.startsWith("http") ? link : `https://${link}`);

        if (links.length) {

            this.addLinks.emit(links);
        }
        const remainingText = trimmedValue.replace(UploadToRecognizeDialogComponent.SELF_EMPLOYED_PATTERN, "");

        this.linkInputFormCtrl.setValue(remainingText, { emitEvent: false });
        this.linkInputFormCtrl.markAsTouched();
    }

    /**
     * Очищает инпут ссылок чеков самозанятых.
     */
    clearLinksHandler() {

        this.linkInputFormCtrl.setValue(null);
    }

    /**
     * Закрытие диалога.
     */
    closeButtonClickHandler(): void {

        if (!this.uploadState.loading) {

            this.dialogRef.close();
        }
    }

    /**
     * Обрабатывает событие нажатия кнопки отправки на сервер.
     */
    sendButtonClickHandler() {

        this.upload.emit({...this.uploadState, bankStatement: this.data.dlgType === "BANK_STATEMENT" });
    }

    //endregion
    //region Public

    /**
     * Возвращает список допустимых расширений файлов для распознавания в зависимости от типа загрузки файлов на
     * распознавание.
     *
     * @return Список допустимых расширений файлов для распознавания в зависимости от типа загрузки файлов на
     * распознавание.
     */
    getAllowedFileExtensions(): string[] {

        if (this.data.dlgType === "BANK_STATEMENT") {

            return UploadToRecognizeDialogComponent.BANK_STATEMENT_ALLOWED_EXTENSIONS;
        }
        else if (this.data.dlgType === "RECOGNITION" || this.data.dlgType === "SELF_EMPLOYED") {

            return UploadToRecognizeDialogComponent.RECOGNITION_ALLOWED_EXTENSIONS;
        }

        return [];
    }

    //endregion
    //region Private

    /**
     * Валидатор корректности всех частей строки с ссылками на чеки самозанятых.
     *
     * @param control Контрол с данными.
     */
    private _linkHasIncorrectPartsValidator(control: AbstractControl): ValidationErrors | null {

        if (control && control.value) {

            return control.value.replace(UploadToRecognizeDialogComponent.SELF_EMPLOYED_PATTERN, "").length
                ? { hasIncorrectParts: true }
                : null;
        }

        return null;
    }

    //endregion
}
