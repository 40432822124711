import { Injectable } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { AsyncValidatorFn } from '@angular/forms';
import { ValidatorFn } from '@angular/forms';

import { AvailableLoginValidator } from '../validators';
import { SecurePasswordValidator } from '../validators';
import { SameValuesValidator } from '../validators';

import { UserService } from './user.service';

/**
 * Фабрика валидаций, которые можно использовать в Reactive Forms.
 */
@Injectable({
    providedIn: 'root'
})
export class ValidatorFactory {
    //region Private fields

    /**
     * Сервис с логикой для работы с пользователем.
     *
     * @private
     */
    private readonly _userService: UserService;

    //endregion
    //region Ctor

    constructor(userService: UserService) {
        this._userService = userService;
    }

    //endregion
    //region Public

    /**
     * Возвращает функцию асинхронной валидации занят логин или нет.
     *
     * @param debounce Время откладывания запроса на сервер между последовательными вызовами функции валидации.
     * @param cd Сервис для управления запуском определения angular'ом изменений данных, произошедших в компоненте.
     *
     * @return Функция асинхронной валидации занят логин или нет.
     */
    availableLogin(debounce: number = 300, cd: ChangeDetectorRef): AsyncValidatorFn {

        return AvailableLoginValidator.get(this._userService, debounce, cd);
    }

    /**
     * Возвращает функцию валидации надёжности пароля.
     *
     * @return Функция валидации надёжности пароля.
     */
    securePassword(): ValidatorFn {

        return SecurePasswordValidator.get();
    }

    /**
     * Возвращает функцию валидации, что заданные поля имеют одинаковое значение.
     *
     * @param sameValueFields Поля формы, значения в которых должны быть идентичны.
     * @param sameValueErrorFields Поля формы, которые нужно пометить как невалдиные, если значения в заданных
     * полях формы не совпадают.
     *
     * @return Функция валидации, что заданные поля имеют одинаковое значение.
     */
    sameValues(sameValueFields: string[] = [], sameValueErrorFields: string[] = []): ValidatorFn {

        return SameValuesValidator.get(sameValueFields, sameValueErrorFields);
    }

    //endregion
}