import { Component } from "@angular/core";
import { ViewChild } from "@angular/core";
import { TemplateRef } from "@angular/core";
import { AbstractControl } from "@angular/forms";

/**
 * Шаблон для отображения кнопки очистки в инпуте.
 */
@Component({
    selector: "input-clear-button-template",
    template: `
        <ng-template #template let-control="control">
            <button
                class="input-clear-button-template__button"
                mat-icon-button
                (click)="clearHandler($event, control)"
            >
                <mat-icon>clear</mat-icon>
            </button>
        </ng-template>
    `,
    styles: [`
        .input-clear-button-template__button {
            width: 18px;
            height: 18px;
            line-height: 18px;
        }
    `],
})
export class InputClearButtonTemplateComponent {
    //region Fields

    /**
     * Ссылка на шаблон.
     */
    @ViewChild("template", {static: true})
    value: TemplateRef<any>;

    //endregion
    //region Events

    /**
     * Обработчик события очистки контрола.
     *
     * @param event Событие очистки контрола.
     * @param control Контрол со значением.
     */
    clearHandler(event: MouseEvent, control: AbstractControl): void {

        control.setValue(null);
        event.stopPropagation();
    }

    //endregion
}
