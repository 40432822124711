import { PositionStrategy } from "@angular/cdk/overlay";
import { Overlay } from "@angular/cdk/overlay";
import { DoCheck } from "@angular/core";
import { TemplateRef } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { AfterViewInit } from "@angular/core";
import { ViewChild } from "@angular/core";
import { OnDestroy } from "@angular/core";
import { Injector } from "@angular/core";
import { Inject } from "@angular/core";
import { forwardRef } from "@angular/core";
import { Input } from "@angular/core";
import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FormControlDirective } from "@angular/forms";
import { FormGroupDirective } from "@angular/forms";
import { FormControlName } from "@angular/forms";
import { NgModel } from "@angular/forms";
import { NgControl } from "@angular/forms";
import { ControlValueAccessor } from "@angular/forms";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatFormFieldControl } from "@angular/material/form-field";
import { MatSelect } from "@angular/material/select";
import { CanDisableRipple } from "@angular/material/core";
import { CanUpdateErrorState } from "@angular/material/core";
import { HasTabIndex } from "@angular/material/core";
import { CanDisable } from "@angular/material/core";
import { timer } from "rxjs";
import { combineLatest } from "rxjs";
import { merge } from "rxjs";
import { of } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { Subject } from "rxjs";
import { Observable } from "rxjs";
import { fromEvent } from "rxjs";
import { catchError } from "rxjs/operators";
import { delayWhen } from "rxjs/operators";
import { startWith } from "rxjs/operators";
import { map } from "rxjs/operators";
import { withLatestFrom } from "rxjs/operators";
import { debounceTime } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { filter } from "rxjs/operators";
import { finalize } from "rxjs/operators";
import { shareReplay } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";
import { scan } from "rxjs/operators";
import { ErrorStateMatcher } from "@angular/material/core";
import { ApiResponse } from "src/app/common/models/index";
import { DlgService } from "src/app/common/services/index";

/**
 * Функция для загрузки начальных значений в селекте.
 */
export type SelectLoadFunction<T> = () => Observable<T[]>;

/**
 * Функция для поиска в селекте.
 */
export type SelectSearchFunction<T> = (search: string) => Observable<T[]>;

/**
 * Функция для постраничного поиска в селекте.
 */
export type SelectPagedSearchFunction<T> = (search: string, page: number, pageSize: number) => Observable<T[]>;

/**
 * Type guard для проверки типа функции поиска.
 *
 * @param value Функция поиска.
 */
function isPagedSearchFunction<T>(
    value: SelectSearchFunction<T> | SelectPagedSearchFunction<T>
): value is SelectPagedSearchFunction<T> {

    return !!value && value.length === 3;
}

/**
 * Компонента обложка для MatSelect
 */
@Component({
    selector: "entera-mat-select-wrapper",
    styleUrls: ["./entera-mat-select-wrapper.component.scss"],
    templateUrl: "./entera-mat-select-wrapper.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EnteraMatSelectWrapperComponent),
            multi: true,
        },
        {
            provide: MatFormFieldControl,
            useExisting: forwardRef(() => EnteraMatSelectWrapperComponent),
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnteraMatSelectWrapperComponent implements DoCheck, AfterViewInit, OnDestroy, ControlValueAccessor, CanDisable, HasTabIndex, CanUpdateErrorState, CanDisableRipple, MatFormFieldControl<any> {
    //region Inputs
    //region Wrapper

    /**
     * Функция отображения начального списка.
     */
    @Input()
    loadFn: SelectLoadFunction<any> = ( () => of([]) );

    /**
     * Функция поиска.
     */
    @Input()
    searchFn: SelectSearchFunction<any> | SelectPagedSearchFunction<any> = null;

    /**
     * Размер страницы для постраничного поиска.
     *
     * Минимальное значение должно быть 6, иначе не будет работать событие подгрузки следующей страницы при скролле.
     */
    @Input()
    searchPageSize: number = 10;

    /**
     * Минимальная длинна для поиска
     */
    @Input()
    searchMinLength: number = 1;

    /**
     * Время откладывание запроса поиска между вводом строки для поиска.
     */
    @Input()
    searchDebounce: number = 300;

    /**
     * Максимальное количество элементов списка, которое можно выбрать.
     */
    @Input()
    maxSelectCount: number;

    /**
     * Темплейт отображения информации над строкой поиска.
     */
    @Input()
    aboveInfoTemplate: TemplateRef<any>;

    /**
     * Темплейт отображения выбранной опции в контроле.
     */
    @Input()
    selectedTemplate: TemplateRef<any>;

    /**
     * Темплейт отображения выбранной опции в контроле.
     */
    @ViewChild("defaultOptionView", {static: true})
    defaultOptionView: TemplateRef<any>;

    /**
     * Темплейт отображения опции в выпадающем меню.
     */
    @Input()
    set optionTemplate(template: TemplateRef<any>) {

        this.optionTemplateView = template;
    }

    //endregion
    //region MatSelectProxy

    @Input()
    panelClass: string | string[] | Set<string> | { [key: string]: any; };

    @Input()
    placeholder: string;

    @Input()
    required: boolean;

    @Input()
    disableRipple: boolean;

    @Input()
    disabled: boolean;

    /**
     * Включена возможность выбора нескольких элементов?
     */
    @Input()
    multiple: boolean;

    /**
     * Показывать выбранный элемент в списке?
     */
    @Input()
    showSelectedOption: boolean = true;

    @Input()
    errorStateMatcher: ErrorStateMatcher;

    @Input()
    tabIndex: number;

    @Input()
    fixPositionAfterLoad: boolean = true;

    /**
     * Автоматическое заполнение?
     */
    @Input()
    autofilled: boolean = false;

    /**
     * Индекс к которому следует вернуться.
     */
    @Input()
    defaultTabIndex: number;

    /**
     * Значение, которое должно быть объединено с идентификаторами, которые задаются в поле формы.
     */
    @Input()
    userAriaDescribedBy: string;


    @Input()
    compareFn: (o1: any, o2: any) => boolean = (o1, o2) => (o1 && o1.id || o1) === (o2 && o2.id || o2);

    get stateChanges(): Subject<void> {

        return this.matSelect.stateChanges;
    }

    get errorState(): boolean {

        return this.matSelect.errorState || !!this.formControl.errors;
    }

    set errorState(value) {

        this.matSelect.errorState = value;
    }

    get controlType(): string {
        return this.matSelect.controlType;
    }

    get empty(): boolean {
        return this.matSelect.empty;
    }

    get focused(): boolean {

        return this.matSelect.focused;
    }

    get id(): string {

        return this.matSelect.id;
    }

    get ngControl(): NgControl | null {

        return this.matSelect.ngControl;
    }

    get shouldLabelFloat(): boolean {

        return this.matSelect.shouldLabelFloat;
    }

    get value(): any | null {

        return this.matSelect.value;
    }

    //endregion
    //endregion
    //region Fields

    /**
     * Оригинальный форм контрол.
     *
     * Дефолтное значение для избегания накладок с поздней инициализацией целевого контрола.
     */
    formControl: FormControl = new FormControl();

    /**
     * Компонент MatSelect.
     */
    @ViewChild(MatSelect, {static: true})
    matSelect: MatSelect;

    /**
     * Темплейт отображения опции в выпадающем меню.
     */
    @ViewChild("defaultOptionView", {static: true})
    optionTemplateView: TemplateRef<any>;

    /**
     * Контрол поиска.
     */
    searchFormControl = new FormControl();

    /**
     * Массив всех опций.
     */
    optionList$: Observable<any[]>;

    /**
     * Длина текста, введённого в поле поиска.
     */
    searchLength$: Observable<number>;

    /**
     * Номер страницы поиска.
     */
    searchPage$: BehaviorSubject<number> = new BehaviorSubject(1);

    /**
     * Сабж для отображения процесса загрузки.
     */
    loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /**
     * Сабж для отображения процесса загрузки.
     */
    loadingPage$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /**
     * Значение при отсутствии опций для выбора.
     */
    noResult$: Observable<boolean>;

    /**
     * Достигнуто максимальное количество выбранных элементов.
     */
    maxSelectCountReached$: Observable<boolean>;

    /**
     * Фейковое выбранное значение.
     * 
     * Необходимо для того чтобы отображать значение в поле контрола при отсутствии элемента в списке.
     */
    readonly fakeSelectedValue = {};

    /**
     * Загружаемое значение в селекте при поиске.
     */
    readonly loadingValue = {};

    /**
     * Информационное сообщение.
     */
    readonly infoOptionValue = {};

    /**
     * Информационное сообщение отсутствия результата при поиске.
     */
    readonly noResultValue = {};

    /**
     * Subject для глобальной отписки.
     */
    private _unsubscribe$: Subject<never> = new Subject();

    //endregion
    //region Ctor

    /**
     * Конструктор врапера.
     *
     * @param _injector Инжектор.
     * @param _overlay Сервис по созданию Overlay.
     * @param _dlgService Сервис для работы с диалогами.
     */
    constructor(
        @Inject(Injector) private _injector: Injector,
        private _overlay: Overlay,
        private _dlgService: DlgService,
    ) {

        this.loading$.pipe(
            takeUntil(this._unsubscribe$),
            filter(() =>
                this.matSelect
                && this.matSelect.overlayDir != null
                && this.matSelect.overlayDir.overlayRef != null
            ),
            filter(() => this.fixPositionAfterLoad)
        ).subscribe(_ =>
            setTimeout(() =>
                this.matSelect.overlayDir.overlayRef.updatePositionStrategy(this.reCreatePosition()),
                100
            )
        );
    }

    //endregion
    //region LifeCycle hooks

    ngDoCheck() {

        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    ngAfterViewInit(): void {

        this.matSelect.ngControl = this.findNgControl();
        this.setupWrapper();
    }

    ngOnDestroy() {

        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    //endregion
    //region ControlValueAccessor impl

    registerOnChange(fn: any): void {

        this.matSelect.registerOnChange(fn);
    }

    registerOnTouched(fn: any): void {

        this.matSelect.registerOnTouched(fn);
    }

    setDisabledState(isDisabled: boolean): void {
        
        this.matSelect.setDisabledState(isDisabled);
    }

    writeValue(obj: any): void {

        this.matSelect.writeValue(obj);
    }

    //endregion
    //region MatSelect proxy methods

    updateErrorState(): void {

        this.matSelect.updateErrorState();
    }

    onContainerClick(event: MouseEvent): void {

        this.matSelect.onContainerClick();
    }

    setDescribedByIds(ids: string[]): void {

        this.matSelect.setDescribedByIds(ids);
    }

    //endregion
    //region Public

    /**
     * Обработчик нажатия кнопок в поле поиска.
     *
     * Останавливает кнопки HOME и END от всплытия, так как иначе mat-select сделает event.preventDefault() для них,
     * что не даст в поле поиска перемещаться в начало или конец поля.
     *
     * @param event Событие нажатия кнопок в поле поиска.
     */
    keydownHandler(event: KeyboardEvent): void {

        if (event.code === "Home" || event.code === "End") {

            event.stopPropagation();
        }
    }

    /**
     * Обработчик скролла окошка результатов.
     *
     * Если поиск делается постранично, в данный момент не происходит загрузка и пользователь доскролил список до дна,
     * то переключает номер страницы на следующий.
     */
    scrollHandler() {

        const loading = this.loadingPage$.value || this.loading$.value;

        if (isPagedSearchFunction(this.searchFn) && !loading) {

            this.searchPage$.next(this.searchPage$.getValue() + 1);
        }
    }

    /**
     * Функция для сравнения значений параметра с выбранными значениями.
     *
     * @param o1 Аргумент — это значение из опции.
     * @param o2 Второй - это значение из выбора.
     */
    compareWith = (o1: any, o2: any) => {

        return (o1 === this.fakeSelectedValue && !!o2) || this.compareFn && this.compareFn(o1, o2);
    }

    //endregion
    //region Private

    /**
     * Выполняет поиск NgControl на домашнем элементе компоненты.
     *
     * Присваивает внутреннее значение FormControl исходя из реализации NgControl.
     *
     * @return найденный NgControl.
     */
    private findNgControl(): NgControl {

        const ngControl: NgControl = this._injector.get(NgControl);

        switch (ngControl.constructor) {
            case NgModel: {

                const {control, update} = ngControl as NgModel;

                this.formControl = control;

                this.formControl.valueChanges
                    .pipe(
                        tap((value: any) => update.emit(value)),
                        takeUntil(this._unsubscribe$),
                    )
                    .subscribe();
                break;
            }
            case FormControlName: {

                const formGroupDirective = this._injector.get(FormGroupDirective);
                this.formControl = formGroupDirective.getControl(ngControl as FormControlName);
                break;
            }
            case FormControlDirective: {

                this.formControl = (ngControl as FormControlDirective).form;
                break;
            }
            default: {

                throw new Error("Can't find NgControl implementation");
            }
        }

        if (!this.formControl) {

            throw new Error("FormControl not present for " + ngControl);
        }

        return ngControl;
    }

    /**
     * Выполняет инициализацию логики враппера.
     */
    private setupWrapper(): void {

        this.searchLength$ = this.searchFormControl.valueChanges.pipe(
            map((search: string) => search.length),
        );

        this.maxSelectCountReached$ = this.formControl.valueChanges.pipe(
            startWith(this.formControl.value || []),
            map((selectedOptions: any[]) => this.multiple
                && isFinite(this.maxSelectCount)
                && selectedOptions.length >= this.maxSelectCount
            ),
        );

        const searchErrorHandler = (err: ApiResponse): Observable<any[]> => {

            this._dlgService.openSimpleDlg({
                headerKey: "common.fail",
                text: of(err.errorMessage || "Unknown error"),
                closeBtnKey: "button.close",
            });

            return of([]);
        };

        const getPreload = (open: boolean = true): Observable<any[]> => {

            if (open) {

                return this.loadFn().pipe(
                    takeUntil(this.searchLength$.pipe(filter(Boolean))),
                    catchError(searchErrorHandler),
                    delayWhen((value: unknown[]) => value.length > 10 && timer(50) || timer(0)),
                    finalize(() => {
                        this.loading$.next(false);
                        this.loadingPage$.next(false);
                    }),
                );
            }

            return of([]);
        };

        const getSearch = (search: string, page: number, pageSize: number = this.searchPageSize): Observable<any[]> => {

            const searchResult = isPagedSearchFunction(this.searchFn)
                ? (this.searchFn as SelectPagedSearchFunction<any>)(search, page, pageSize)
                : (this.searchFn as SelectSearchFunction<any>)(search);

            return searchResult.pipe(
                takeUntil(this.searchFormControl.valueChanges),
                catchError(searchErrorHandler),
                finalize(() => {
                    this.loading$.next(false);
                    this.loadingPage$.next(false);
                }),
            );
        };

        const preloaded$: Observable<any[]> = this.matSelect.openedChange.pipe(
            startWith(false),
            tap((open) => this.loading$.next(open)),
            switchMap(getPreload),
            tap((open) => open && this.loading$.next(false)),
            tap((open) => open && this.loadingPage$.next(false)),
        );

        const search$: Observable<any[]> = this.searchFormControl.valueChanges.pipe(
            filter((search: string) => search.length >= this.searchMinLength),
            tap(() => this.loading$.next(true)),
            debounceTime(this.searchDebounce),
            withLatestFrom(this.searchLength$),
            filter(([_, length]) => length >= this.searchMinLength),
            map(([search, _]) => search)
        ).pipe(
            switchMap(search => getSearch(search, 0)),
            tap(() => this.loading$.next(false)),
            tap(() => this.loadingPage$.next(false)),
            shareReplay(1),
        );

        const cleanSearch$: Observable<any[]> = this.searchFormControl.valueChanges.pipe(
            filter((search: string) => !search),
            withLatestFrom(this.matSelect.openedChange),
            filter(([_, open]: [never, boolean]) => open),
            tap(() => this.loading$.next(true)),
            switchMap(() => getPreload()),
            tap(() => this.loading$.next(false)),
            tap(() => this.loadingPage$.next(false)),
        );

        const intermediateSearch$: Observable<any[]> = this.searchLength$.pipe(
            filter((length: number) => length < this.searchMinLength && length > 0),
            switchMap(() => of([])),
        );

        // если страницы закончились, то больше не надо делать запрос
        const pagesEnded = new BehaviorSubject(false);

        const optionWithPagedSearch$ = merge(preloaded$, search$, cleanSearch$, intermediateSearch$).pipe(
            startWith([]),
            shareReplay(1)
        );

        // постраничный поиск накапливает результаты всех запросов. при эмите любого другого обзервабла надо
        // сбрасывать накопленное. поэтому такая сложная херня тут
        const pagedSearch$: Observable<any[]> =
            merge(
                optionWithPagedSearch$.pipe(map(() => null)),
                this.searchPage$
                    .pipe(
                        filter(_ => !pagesEnded.value),
                        filter(page => page > 1),
                        withLatestFrom(this.searchLength$),
                        filter(([_, length]) => length >= this.searchMinLength),
                        map(([page, _]) => page),
                        tap(() => this.loadingPage$.next(true)),
                        switchMap(
                            page => page === 2
                                ? merge(
                                    getSearch(this.searchFormControl.value, 1),
                                    getSearch(this.searchFormControl.value, 2)
                                )
                                : getSearch(this.searchFormControl.value, page)
                        ),
                        tap(val => pagesEnded.next(val.length < this.searchPageSize)),
                        tap(() => this.loading$.next(false)),
                        tap(() => this.loadingPage$.next(false)),
                    ),
            )
            .pipe(
                scan((accum, newVal) => newVal ? [ ...accum, ...newVal ] : [], []),
                filter(val => val.length > 0)
            );

        // обнуляем номер страницы и флаг того, что страницы кончились, если изменилось значение поиска
        optionWithPagedSearch$.pipe(takeUntil(this._unsubscribe$))
            .subscribe(() => {
                this.searchPage$.next(1);
                pagesEnded.next(false);
            });

        // при открытии селекта подписываемся на скролл, чтобы менять номер страницы при постраничном запросе
        this.matSelect.openedChange
            .pipe(
                takeUntil(this._unsubscribe$),
                filter(Boolean),
                switchMap(() => fromEvent(this.matSelect.panel.nativeElement, "scroll")),
                debounceTime(50),
                map((event: Event) => event.target as Element),
                filter(panel => panel.scrollTop + panel.clientHeight === panel.scrollHeight),
                withLatestFrom(this.maxSelectCountReached$),
                filter(([_, maxCount]) => !maxCount),
                filter(() => this.matSelect.panel && this.matSelect.panel.nativeElement)
            ).subscribe(() => this.scrollHandler());

        const notEqual = (o1: any) => (o2: any) => !this.compareWith(o1, o2);
        const filterSelected = (options: any[]) => this.multiple
            ? options.filter(option => (this.formControl.value || []).every(notEqual(option)))
            : options.filter(notEqual(this.formControl.value));

        const allOptions$ = merge(optionWithPagedSearch$, pagedSearch$).pipe(
            startWith([]),
            shareReplay(1),
        );

        // formControl.valueChanges добавлен, чтобы при мультиселекте убирались строки после выбора
        const allValuesAndMaxCount = combineLatest([
            allOptions$,
            this.maxSelectCountReached$,
            this.formControl.valueChanges.pipe(startWith(null)),
        ]);
        this.optionList$ = allValuesAndMaxCount.pipe(
            map(([unselectedOptions, _, __]: [any[], boolean, never]) => [filterSelected(unselectedOptions), _]),
            map(([unselectedOptions, maxCountReached, _]: [any[], boolean, never]) =>
                maxCountReached ? [] : unselectedOptions
            ),
        );

        this.noResult$ = combineLatest([
            this.loading$,
            this.loadingPage$,
            this.optionList$.pipe(map(arr => !arr.length)),
            this.maxSelectCountReached$,
        ]).pipe(
            map(([loading, loadingPage, empty, maxCount]) => !loading && !loadingPage && empty && !maxCount),
        );
    }

    /**
     * Пересоздает и возвращает стратегию позиции mat-select.
     */
    private reCreatePosition(): PositionStrategy {

        return this._overlay.position()
            .flexibleConnectedTo(this.matSelect._elementRef)
            .withPositions([{
                originX: "start",
                originY: "bottom",
                overlayX: "start",
                overlayY: "top",
            }]);
    }

    //endregion
}
