import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class FormatService {

    readonly MONEY_FORMAT_DIGIT_BASE = 1000;

    constructor() {
    }

    //region Public

    /**
     * Фортматирование денежного ввода.
     *
     * @param value Значение ввода.
     * @param negativeAllowed Может ли быть отрицательным?
     *
     * @return Отформатированное значение денежного ввода в виде строки.
     */
    public formatMoney(value: string | number, negativeAllowed: boolean = false): string {

        let negative: boolean = false;
        if (typeof value === "number") {

            negative = value < 0;
            value = value.toString();
        }
        else if (value && value.length > 0) {

            negative = value[0] === "-";
        }

        if (value) {
            value = this.fixDigitInput(value);
            let fractionPart = this.createMoneyFractionalPart(value);
            let integralPart = this.createMoneyIntegralPart(value);
            value = integralPart + fractionPart;
        }

        if (value && value.startsWith(".")) {
            value = "0" + value;
        }

        if (negativeAllowed && negative) {

            value = "-" + value;
        }

        return value
    }

    /**
     * Возврат форматированного числового воода, к стандартному числу.
     * @param value
     */
    public unformatMoney(value) {

        if (value) {
            value = value.replace(/\./, "d").replace(/\W+/, "").replace(/d/, ".");
            return Number.parseFloat(value).toString();
        }
        else if (isNaN(value)) {
            return null;
        }
        else {
            return value;
        }
    }

    //endregion

    //region Private

    /**
     * Исправление возможного неправльного чилового ввода.
     * @param value
     */
    private fixDigitInput(value: string) {
        value = String(value).replace(/[^.0-9]+/g, "");
        return value;
    }

    /**
     * Создание форматированной дробной части валютного ввода.
     * @param value значение
     * @param fractions  количетво занков дробной части
     */
    private createMoneyFractionalPart(value, fractions = 2) {
        return (Number(value) % 1).toFixed(fractions).substring(1);
    }

    /**
     * Создание форматированной целой части валютного ввода.
     * @param value значяение.
     * @param delimiter разделитель разрядов
     */
    private createMoneyIntegralPart(value: string, delimiter: string = " "): string {
        let number = Math.floor(Number(value));
        let result = '';
        while (number > 0) {
            let fraction: any = number % this.MONEY_FORMAT_DIGIT_BASE;
            fraction = String(fraction);
            while (fraction.length < 3) {
                fraction = 0 + fraction;
            }
            result = delimiter + fraction + result;
            number = Math.floor((number / this.MONEY_FORMAT_DIGIT_BASE));
        }

        return result.substring(delimiter.length).replace(/^0+/g, "");
    }

    //endregion
}
