import { Injectable } from "@angular/core";

import { Actions } from "@ngrx/effects";
import { Effect } from "@ngrx/effects";
import { ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { withLatestFrom } from "rxjs/operators";
import { debounceTime } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models";
import { Space } from "src/app/common/models";
import { SpaceService } from "src/app/common/services";
import { EsfKzManualUploadActionType } from "src/app/spaces/modules/esfkz-manual-upload/store/actions/esfkz-manual-upload.action";

import { BalanceActionType } from "../actions"; /* circular dependency break */
import { BalanceLoadAction } from "../actions"; /* circular dependency break */
import { BalanceLoadFailAction } from "../actions"; /* circular dependency break */
import { BalanceLoadSuccessAction } from "../actions"; /* circular dependency break */
import { RecognitionTasksActionType } from "../actions"; /* circular dependency break */
import { RootState } from "../reducers"; /* circular dependency break */
import { spaceSelector } from "../selectors"; /* circular dependency break */

/**
 * Side-эффекты на события, связанные с балансом пространства документов.
 */
@Injectable()
export class BalanceEffects {
    //region Fields

    /**
     * Сервис для работы с пространствами документов.
     */
    private readonly _spaceService: SpaceService;

    //endregion
    //region Ctor

    /**
     * Конструктор класса с side-эффектами на события, связанные с балансом пространства документов.
     *
     * @param _actions$ Поток событий, происходящих в приложении.
     * @param _store Сервис для доступа и управления состоянием приложения.
     * @param spaceService Сервис для работы с пространствами документов.
     */
    constructor(
        private readonly _actions$: Actions,
        private readonly _store: Store<RootState>,
        spaceService: SpaceService,
    ) {
        this._spaceService = spaceService;
    }

    //endregion
    //region Public

    /**
     * Обработка события требования загрузки данных баланса.
     */
    @Effect()
    loadBalance$ = this._actions$
        .pipe(
            ofType(BalanceActionType.LOAD),
            map((action: BalanceLoadAction): Space => action.payload),
            switchMap((space: Space) =>

                this._spaceService.getBalance(space)
                    .pipe(
                        map((restCreditCount: number) => {

                            if (space && space.balanceExpirationDate === null) {

                                restCreditCount = 0;
                            }

                            return new BalanceLoadSuccessAction({restCreditCount, space});
                        }),
                        catchError((response: ApiResponse) => of(new BalanceLoadFailAction(response)))
                    )
            )
        );

    /**
     * Обработка события после успешного завершения задачи на распознавание.
     *
     * Инициируется событие для запроса баланса с сервера, чтобы обновить значение баланса. В потоке используется
     * задержка, чтобы группировать события завершения задачи, если они происходят быстро друг за другом. Это
     * поможет избежать множества запросов к серверу.
     */
    @Effect()
    updateBalance$ = this._actions$
        .pipe(
            ofType(
                RecognitionTasksActionType.TASK_FINISHED,
                EsfKzManualUploadActionType.UPLOAD_DOCUMENTS_SUCCESS
            ),
            withLatestFrom(
                this._store.select(spaceSelector),
            ),
            debounceTime(250),
            map(([action, space]) => new BalanceLoadAction(space)),
        );

    //endregion
}