import { createAction } from "@ngrx/store";
import { props } from "@ngrx/store";
import { StoreActions } from "src/app/common/models/entera-common-types";
import { RecognitionTask } from "src/app/common/models/index";
import { ErrorResponseProps } from "src/app/root/store/actions/props/error-response.action-props";
import { ValueProps } from "src/app/root/store/actions/props/value-props";
import { IntegrationProps } from "src/app/spaces/modules/documents-registry/store/actions/props/integration.props";
import { EsfKzDocument } from "src/app/spaces/modules/esfkz-manual-upload/models/esfkz-document";
import { EsfKzDocumentsFilter } from "src/app/spaces/modules/esfkz-manual-upload/models/esfkz-documents-filter";
import { InvoicePage } from "src/app/spaces/modules/esfkz-manual-upload/models/invoice-page";


/**
 * Типы событий связанные с выгрузкой документов из ИС ЭСФ KZ.
 */
export enum EsfKzManualUploadActionType {
    INIT = "[IS ESF KZ manual upload] Init",
    LOAD_DOCUMENTS_PAGE = "[IS ESF KZ manual upload] Load documents page",
    LOAD_DOCUMENTS_PAGE_SUCCESS = "[IS ESF KZ manual upload] Documents page was successfully loaded",
    LOAD_DOCUMENTS_PAGE_FAIL = "[IS ESF KZ manual upload] Failed while loading documents page",
    AUTH_TICKED_EXPIRED_ON_REQUEST = "[IS ESF KZ manual upload] Auth ticket expired when making request",
    AUTH_TICKED_EXPIRED = "[IS ESF KZ manual upload] Auth ticket expired",
    APPLY_FILTER = "[IS ESF KZ manual upload] Apply filter",
    GO_TO_PAGE = "[IS ESF KZ manual upload] Go to another page",
    TOGGLE_DOCUMENT_SELECTION = "[IS ESF KZ manual upload] Toggle selected document",
    TOGGLE_ALL_DOCUMENT_SELECTIONS = "[IS ESF KZ manual upload] Toggle all document selections",
    UPLOAD_DOCUMENTS = "[IS ESF KZ manual upload] Upload documents",
    UPLOAD_DOCUMENTS_SUCCESS = "[IS ESF KZ manual upload] Documents were successfully loaded",
    UPLOAD_DOCUMENTS_FAIL = "[IS ESF KZ manual upload] Failed while uploading documents",
}

/**
 * Событие инициализации начального состояния данных по выгрузке из ИС ЭСФ KZ.
 */
const init = createAction(EsfKzManualUploadActionType.INIT, props<IntegrationProps>());

/**
 * Событие загрузки страницы документов из ИС ЭСФ KZ.
 */
const loadDocumentsPage = createAction(EsfKzManualUploadActionType.LOAD_DOCUMENTS_PAGE);

/**
 * Событие успешной загрузки страницы документов из ИС ЭСФ KZ.
 */
const loadDocumentsPageSuccess = createAction(
    EsfKzManualUploadActionType.LOAD_DOCUMENTS_PAGE_SUCCESS,
    props<InvoicePage>(),
);

/**
 * Событие ошибки при загрузке страницы документов из ИС ЭСФ KZ.
 */
const loadDocumentsPageFail = createAction(
    EsfKzManualUploadActionType.LOAD_DOCUMENTS_PAGE_FAIL,
    props<ErrorResponseProps<void>>(),
);

/**
 * Событие завершения срока действия тикета аутентификации при поиске ЭСФ из ИС ЭСФ KZ.
 */
const authTicketExpiredOnRequest = createAction(
    EsfKzManualUploadActionType.AUTH_TICKED_EXPIRED_ON_REQUEST,
    props<ErrorResponseProps<void>>(),
);

/**
 * Событие завершения срока действия тикета аутентификации при поиске ЭСФ из ИС ЭСФ KZ.
 */
const authTicketExpired = createAction(
    EsfKzManualUploadActionType.AUTH_TICKED_EXPIRED,
);

/**
 * Событие применения фильтра к документам из ИС ЭСФ KZ.
 */
const updateFilter = createAction(
    EsfKzManualUploadActionType.APPLY_FILTER,
    props<{ filter: EsfKzDocumentsFilter }>()
);

/**
 * Событие перехода на страницу.
 */
const goToPage = createAction(EsfKzManualUploadActionType.GO_TO_PAGE, props<ValueProps<number>>());

/**
 * Событие изменения выделения документа чекбоксом.
 */
const toggleDocumentSelection = createAction(
    EsfKzManualUploadActionType.TOGGLE_DOCUMENT_SELECTION,
    props<{ doc: EsfKzDocument, selected: boolean }>(),
);

/**
 * Событие изменения выделения строк чекбоксом.
 */
const updateSelectedRows = createAction(
    EsfKzManualUploadActionType.TOGGLE_ALL_DOCUMENT_SELECTIONS,
    props<ValueProps<EsfKzDocument[]>>(),
);

/**
 * Событие выгрузки документов из ИС ЭСФ KZ.
 */
const uploadDocuments = createAction(
    EsfKzManualUploadActionType.UPLOAD_DOCUMENTS,
    props<ValueProps<EsfKzDocument[]>>(),
);

/**
 * Событие успешной выгрузки документов из ИС ЭСФ KZ.
 */
const uploadDocumentsSuccess = createAction(
    EsfKzManualUploadActionType.UPLOAD_DOCUMENTS_SUCCESS,
    props<ValueProps<RecognitionTask[]>>(),
);

/**
 * Событие ошибки при выгрузке документов из ИС ЭСФ KZ.
 */
const uploadDocumentsFail = createAction(
    EsfKzManualUploadActionType.UPLOAD_DOCUMENTS_FAIL,
    props<ErrorResponseProps<void>>(),
);

/**
 * Тип, объединяющий все события, связанные с выгрузкой документов из ИС ЭСФ KZ.
 */
export type EsfKzManualUploadAction = StoreActions<typeof esfKzManualUploadAction>;

/**
 * Все события, связанные с выгрузкой документов из ИС ЭСФ KZ.
 */
export const esfKzManualUploadAction = {
    init: init,
    loadDocumentsPage: loadDocumentsPage,
    loadDocumentsPageSuccess: loadDocumentsPageSuccess,
    loadDocumentsPageFail: loadDocumentsPageFail,
    authTicketExpiredOnRequest: authTicketExpiredOnRequest,
    authTicketExpired: authTicketExpired,
    updateFilter: updateFilter,
    goToPage: goToPage,
    toggleDocumentSelection: toggleDocumentSelection,
    updateSelectedRows: updateSelectedRows,
    uploadDocuments: uploadDocuments,
    uploadDocumentsSuccess: uploadDocumentsSuccess,
    uploadDocumentsFail: uploadDocumentsFail,
};
