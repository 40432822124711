<div class="notification notification__{{severity?.toLowerCase()}}">
    <mat-icon class="notification__icon">error</mat-icon>
    <span>{{name}}</span>
    <mat-icon
        class="notification__icon notification__icon_clickable"
        (click)="openTooltipHandler()"
    >
        arrow_forward_ios
    </mat-icon>
    <span
        #notificationTooltip
        matTooltipClass="white-custom-mat-tooltip"
        [matTooltip]="tooltipText"
        [matTooltipPosition]="'right'"
    >
    </span>
</div>
