import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models/api-response";
import { DeletedDocumentsResponse } from "src/app/common/models/api-responses/deleted-documents-response";
import { EnteraDocument } from "src/app/common/models/entera-document";
import { MoveDocumentsActionProps } from "src/app/common/store/actions/props/move-documents.action-props";
import { ErrorUtils } from "src/app/common/utils/error.utils";
import { UrlUtils } from "src/app/common/utils/url.utils";
import { DocumentRegistryFiltersState } from "src/app/spaces/modules/documents-registry/models/index";
import { DeletedDocumentsProps } from "src/app/spaces/modules/documents-registry/store/actions/props/deleted-documents-props";
import { MoveDocumentsRequestProps } from "src/app/spaces/modules/documents-registry/store/actions/props/move-documents-request-props";
import { DocumentsFilterUtils } from "src/app/spaces/modules/documents-registry/utils/documents-filter.utils";
import { FilterAndSortAndSearchState } from "src/app/spaces/modules/documents-registry/models/filter-and-sort-and-search-state.model";
import { RootState } from "rootStore";
import { Store } from "@ngrx/store";
import { take } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { hasPermission } from "src/app/root/store/selectors/current-user.selector";
import {AdminPermissionItem} from "src/app/common/models/admin-permission-item";

/**
 * Ответ от метода API по загрузке документов.
 */
interface DocumentsResponse extends ApiResponse {

    /**
     * Документы.
     */
    documents: EnteraDocument[];
}

/**
 * Ответ от метода API по загрузке кол-ва документов, подходящих под фильтры реестра.
 */
interface DocumentsCountResponse extends ApiResponse {

    /**
     * Кол-во документов, подходящих под фильтры реестра.
     */
    documentsCount: number;
}

/**
 * Запрос на пометку документов как прочитанных.
 */
class MarkAsSeenRequest {

    /**
     * Документы для пометки как прочитанных.
     */
    documents: { id: string }[] = [];
}

/**
 * Сервис для работы с документами.
 */
@Injectable({
    providedIn: 'root'
})
export class DocumentsService {
    //region Fields

    /**
     * Кол-во запрашиваемых документов.
     */
    public static readonly PAGE_SIZE: number = 10;

    //endregion
    //region Ctor

    constructor(
        private httpClient: HttpClient,
        private _store: Store<RootState>,
    ) {}

    //endregion
    //region Public

    /**
     * Загрузка документов пространства с сервера.
     *
     * @param request Фильтры запроса.
     */
    // TODO Определить интерфейс запроса документов.
    get(request: any): Observable<EnteraDocument[]> {

        const queryParams: any = {
            ...request,
        };

        return this.getDocuments(queryParams);
    }

    /**
     * Загрузка документов пространства с сервера с учетом фильтров массовых действий.
     *
     * @param request Фильтры запроса.
     */
    getFilteredDocuments(request: any): Observable<EnteraDocument[]> {

        const excludedDocumentIds = (request.excludedDocuments || []).map(doc => doc.id);
        const allDocumentsSelected = request.allDocumentsSelected;

        const queryParams: any = {
            ...request.props.filter,
            excludedDocumentIds,
            allDocumentsSelected,
        };

        return this.getDocuments(queryParams);
    }

    /**
     * Загрузка документов пространства с сервера.
     *
     * @param queryParams Параметры запроса.
     */
    getDocuments(queryParams: any): Observable<EnteraDocument[]> {

        return this._store.select(hasPermission, { permission: AdminPermissionItem.SEE_ALL_DOCUMENTS }).pipe(
            take(1),
            switchMap((hasAccess: boolean) => {

                if (hasAccess) {

                    queryParams = {
                        ...queryParams,
                        spaceId: null
                    };
                }

                return this.httpClient.get(`/api/v1/documents${UrlUtils.getQueryString(queryParams)}`).pipe(
                    map((response: DocumentsResponse) => response.documents),
                    catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
                );
            })
        );

    }

    /**
     * Загрузка кол-ва документов, подходящих под фильтры реестра документов.
     *
     * @param request Фильтры запроса.
     */
    // TODO Определить интерфейс запроса документов.
    getCount(request: any): Observable<number> {

        return this._store.select(
            hasPermission,
            {permission: AdminPermissionItem.SEE_ALL_DOCUMENTS})
            .pipe(
                take(1),
                switchMap((hasAccess: boolean) => {

                    let queryParams: any = {...request};

                    if (hasAccess) {

                        queryParams = {...request, spaceId: null};
                    }

                    if (!hasAccess || request.isForcedCounting || request.allDocumentsSelected) {

                        return this.httpClient.get(`/api/v1/documents/count${UrlUtils.getQueryString(queryParams)}`)
                            .pipe(
                                map((response: DocumentsCountResponse) => response.documentsCount),
                                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
                            );
                    }

                    return of(-1);
                })
            );
    }

    /**
     * Помечает документ как просмотренный.
     *
     * @param document Документ.
     */
    markAsSeen(document: EnteraDocument): Observable<boolean> {

        const request = new MarkAsSeenRequest();
        request.documents.push({ id: document.id });

        return this.httpClient
            .post<ApiResponse>('/api/v1/documents/markAsSeen', request)
            .pipe(
                map(response => response.result),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse))
            );
    }

    /**
     * Перемещает документы в другую папку.
     *
     * @param props Идентификаторы документов и папки, в которую их нужно переместить.
     */
    moveToAnotherSpace(props: MoveDocumentsActionProps): Observable<string[]> {

        let filter: FilterAndSortAndSearchState = DocumentsFilterUtils.prepareFilter(props.filter);
        const excludedDocumentIds: string[] = (props.excludedDocuments || []).map(doc => doc.id);
        filter = Object.assign(new DocumentRegistryFiltersState(), { ...filter, excludedDocumentIds });

        const requestBody: MoveDocumentsRequestProps = {
            filter,
            toSpaceId: props.toSpaceId,
            documentIdList: (props.documents || []).map(doc => doc.id),
            creatorId: props.creatorId,
        };

        return this.httpClient
            .post<ApiResponse>("/api/v1/documents/move", requestBody)
            .pipe(
                map((response: ApiResponse & {movedDocumentIdList: string[]}) => response.movedDocumentIdList),
                catchError((response: HttpErrorResponse) => throwError(ErrorUtils.from(response)))
            );
    }

    /**
     * Отправляет запрос на удаление документов по списку id документов.
     *
     * @param documents Список документов.
     */
    deleteDocuments(documents: EnteraDocument[]): Observable<boolean> {

        let ids: string[] = documents.map((document: EnteraDocument): string => document.id);

        return this.httpClient
            .request<ApiResponse>('delete', `/api/v1/documents`, {
                body: { ids }
            })
            .pipe(
                map((response: ApiResponse): boolean => response.result),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
            );
    }

    /**
     * Отправляет запрос на удаление всех документов, подходящих под фильтр.
     *
     * @param request Фильтр.
     */
    deleteDocumentsByFilter(request: any): Observable<DeletedDocumentsProps> {

        const excludedDocumentIds = (request.excludedDocuments || []).map(doc => doc.id);
        const preparedFilter: FilterAndSortAndSearchState = DocumentsFilterUtils.prepareFilter(request.props.filter);
        const filter: DocumentRegistryFiltersState = Object.assign(
            new DocumentRegistryFiltersState(),
            { ...preparedFilter, excludedDocumentIds },
        );

        return this.httpClient
            .request<DeletedDocumentsResponse>('delete', `/api/v2/documents`, { body: { filter }})
            .pipe(
                map((response: DeletedDocumentsResponse) => ({documentIds: response.documentIds, byFilter: true})),
                catchError((response: HttpErrorResponse) => throwError(response.error as ApiResponse)),
            );
    }

    //endregion
}
