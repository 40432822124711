import { Action } from "@ngrx/store";
import { FileWithMetaData } from "src/app/common/models/file-with-meta-data";
import { ValueProps } from "src/app/root/store/actions/props/value-props";
import { ApiResponse } from "../../../../common/models";
import { RecognitionTask } from "../../../../common/models";

import { UploadToRecognizeState } from "../../reducers";

/**
 * Типы событий возможные при отправке задач на распознование.
 */
export enum UploadToRecognizeActionType {
    TASK_CHANGE = "[Upload to recognize] Change task almost",
    ADD_FILES = "[Upload to recognize] Add task to upload list",
    MARK_FILES_AS_DUPLICATES = "[Upload to recognize] Mark files as duplicates",
    FILE_EXISTENCE_CHECK_FAILED = "[Upload to recognize] File existence check failed",
    REMOVE_FILE = "[Upload to recognize] Remove task from upload list",
    REMOVE_ALL_FILES = "[Remove all files] Remove all files from upload list",
    TOGGLE_FORCE_OCR = "[Upload to recognize] Toggle force ocr",
    TOGGLE_FORCE_PROCESSING = "[Upload to recognize] Toggle force processing",
    TOGGLE_FORCE_QUEUE = "[Upload to recognize] Toggle force queue",
    COMMENT = "[Upload to recognize] Comment",
    UPLOAD = "[Upload to recognize] Upload task files to server",
    MOBILE_UPLOAD = "[Upload to recognize] Upload task files by mobile interface to server",
    UPLOAD_FAIL = "[Upload to recognize] Upload task to recognize fail",
    UPLOAD_SUCCESS = "[Upload to recognize] Upload task to recognize success",
    UPLOAD_PROGRESS = "[Upload to recognize] Upload task progress change",
    GENERATE_HASH_FOR_FILES = "[Upload to recognize] Generate hash for files",
    GENERATE_HASH_FOR_FILE_LINKS = "[Upload to recognize] Generate hash for files by links",
    GENERATE_HASH_FOR_FILE_LINKS_FAILED = "[Upload to recognize] Failed generating hash for files by links",
}

/**
 * Событие генерации хеша для файлов.
 */
export class GenerateHashForFilesAction implements Action {
    readonly type = UploadToRecognizeActionType.GENERATE_HASH_FOR_FILES;
    constructor(public payload: File[]) { };
}

/**
 * Событие генерации хеша для файлов по ссылкам.
 */
export class GenerateHashForFileLinksAction implements Action {
    readonly type = UploadToRecognizeActionType.GENERATE_HASH_FOR_FILE_LINKS;
    constructor(public payload: ValueProps<string[]>) {}
}

/**
 * Событие неуспешной генерации хеша для файлов по ссылкам.
 */
export class GenerateHashForFileLinksFailedAction implements Action {
    readonly type = UploadToRecognizeActionType.GENERATE_HASH_FOR_FILE_LINKS_FAILED;
    constructor(public payload: ApiResponse) {}
}

/**
 * Событие добавления файлов к задаче на распознование.
 */
export class AddFilesToTaskToRecognizeAction implements Action {
    readonly type = UploadToRecognizeActionType.ADD_FILES;
    constructor(public payload: FileWithMetaData[]) {}
}

/**
 * Событие обозначения файлов как дубликатов.
 */
export class MarkFilesAsDuplicatesAction implements Action {
    readonly type = UploadToRecognizeActionType.MARK_FILES_AS_DUPLICATES;
    constructor(public payload: FileWithMetaData[]) {}
}

/**
 * Событие проверки, были ли некоторые файлы загружены ранее.
 */
export class FileExistenceCheckFailedAction implements Action {
    readonly type = UploadToRecognizeActionType.FILE_EXISTENCE_CHECK_FAILED;
    constructor(public payload: ApiResponse) { };
}

/**
 * Событие удаления задачи на распознование.
 */
export class RemoveTaskToRecognizeAction implements Action {
    readonly type = UploadToRecognizeActionType.REMOVE_FILE;
    constructor(public payload: File) { };
}

/**
 * Событие удаления всех файлов из задачи на распознование.
 */
export class RemoveAllFilesFromTaskAction implements Action {
    readonly type = UploadToRecognizeActionType.REMOVE_ALL_FILES;
    constructor() {}
}

/**
 * Событие изменения флага принудительного выполнения OCR.
 */
export class ToggleForceOcrFlagAction implements Action {
    readonly type = UploadToRecognizeActionType.TOGGLE_FORCE_OCR;
    constructor(public payload: boolean) { };
}

/**
 * Событие изменения флага принудительного выполнения парсинга.
 */
export class ToggleForceProcessingFlagAction implements Action {
    readonly type = UploadToRecognizeActionType.TOGGLE_FORCE_PROCESSING;
    constructor(public payload: boolean) { };
}

/**
 * Событие изменения флага принудительной отправки в очередь.
 */
export class ToggleForceQueueFlagAction implements Action {
    readonly type = UploadToRecognizeActionType.TOGGLE_FORCE_QUEUE;
    constructor(public payload: boolean) { };
}

/**
 * Событие изменения поля комментария для отправки.
 */
export class CommentAction implements Action {
    readonly type = UploadToRecognizeActionType.COMMENT;
    constructor(public payload: string) { };
}

/**
 * Событие отправки неактивных задач на распознование.
 */
export class UploadTaskToRecognizeAction implements Action {
    readonly type = UploadToRecognizeActionType.UPLOAD;
    constructor(public payload: UploadToRecognizeState) { };
}

/**
 * Событие отправки неактивных задач на распознование с мобильного интерфейса.
 */
export class UploadTaskByMobileToRecognizeAction implements Action {
    readonly type = UploadToRecognizeActionType.MOBILE_UPLOAD;
    constructor(public payload: string) {};
}

/**
 * Событие прогресса загрузки файлов у задачи на распознование.
 */
export class UploadTaskToRecognizeProgressAction implements Action {
    readonly type = UploadToRecognizeActionType.UPLOAD_PROGRESS;
    constructor(public payload: number) { };
}

/**
 * Событие удачной загрузки задачи на распознование.
 */
export class UploadTaskToRecognizeSuccessAction implements Action {
    readonly type = UploadToRecognizeActionType.UPLOAD_SUCCESS;
    constructor(public payload: RecognitionTask[]) { };
}

/**
 * Событие неудачной загрузки текущего пользователя.
 */
export class UploadTaskToRecognizeFailAction implements Action {
    readonly type = UploadToRecognizeActionType.UPLOAD_FAIL;
    constructor(public payload: ApiResponse) { };
}

/**
 * Собтытие полной замены задачи загрузки файлов на распозннование.
 */
export class ChangeTaskToRecognizeAction implements Action {
    readonly type = UploadToRecognizeActionType.TASK_CHANGE;
    constructor(public payload: UploadToRecognizeState) { };
}

/**
 * Обобщенный тип событий, связанных с отправкой файлов на распознавание.
 */
export type UploadToRecognizeAction =
    | AddFilesToTaskToRecognizeAction
    | MarkFilesAsDuplicatesAction
    | FileExistenceCheckFailedAction
    | RemoveTaskToRecognizeAction
    | RemoveAllFilesFromTaskAction
    | UploadTaskToRecognizeAction
    | UploadTaskByMobileToRecognizeAction
    | ChangeTaskToRecognizeAction
    | UploadTaskToRecognizeSuccessAction
    | UploadTaskToRecognizeFailAction
    | CommentAction
    | UploadTaskToRecognizeProgressAction
    | ToggleForceOcrFlagAction
    | ToggleForceProcessingFlagAction
    | ToggleForceQueueFlagAction
    | GenerateHashForFilesAction
    | GenerateHashForFileLinksAction
    | GenerateHashForFileLinksFailedAction;
