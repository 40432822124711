import { OnDestroy } from "@angular/core";
import { Component } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { Validators } from "@angular/forms";

import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { v4 } from "uuid";
import { RootState } from "src/app/root/store/index";
import { ExternalLedgerType } from "src/app/common/models/external-ledger-type";
import { matchingActions } from "src/app/spaces/modules/document-matching/store/actions/matching.actions";
import {
    CreateOrEditLedgerProps
} from "src/app/spaces/modules/document-matching/store/actions/props/create-or-edit-ledger.props";
import { CrossEntity } from "src/app/spaces/modules/document-matching/models/cross-entity-search-fn";
import { CrossEntityType } from "src/app/spaces/modules/document-matching/models/cross-entity-search-fn";
import { environment } from "src/environments/environment";
import { IntegrationItem } from "src/app/common/models/integration/integration-item.model";

/**
 * Компонент диалога создания или редактирования контрагентов.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "create-ledger-dlg",
    templateUrl: "./create-ledger-dlg.component.html",
    styleUrls: ["./create-ledger-dlg.component.scss"],
})
export class CreateLedgerDlgComponent implements OnDestroy {
    //region Constants

    /**
     * Шаблон для создания контрагента.
     */
    public static readonly NEW_LEDGER_TEMPLATE = {
        id: "New ledger ",
        amount: 0,
        description: "NEW",
    };

    //endregion
    //region Fields

    /**
     * Форм группа нового контрагента.
     */
    readonly ledgerGroup: FormGroup;

    /**
     * Типы контрагентов
     */
    ledgerTypes: ExternalLedgerType[];

    /**
     * Новый контрагент для редактирования
     */
    ledgerForEdit: CrossEntity;

    /**
     * Мапа связывающая base type и meta type.
     */
    private _typeMetaTypeMap: object;

    /**
     * Объект глобальной отписки.
     */
    private readonly _globalUnsubscribe$: Subject<void> = new Subject();


    //endregion
    //region Ctor

    /**
     * Конструктор компонента.
     *
     * @param _store Состояние приложения.
     * @param _dialogRef Компонент диалога.
     * @param data Данные компоненты.
     */
    constructor(
        private _store: Store<RootState>,
        private _dialogRef: MatDialogRef<CreateLedgerDlgComponent>,
        @Inject(MAT_DIALOG_DATA) data: CreateOrEditLedgerProps,
    ) {
        this.ledgerTypes = data.typeList;
        this.ledgerForEdit = data.ledger;

        const foundLedgerType = this.ledgerTypes.find(t =>
            t.underName === (this.ledgerForEdit && this.ledgerForEdit.underNameType)
        );
        this.ledgerGroup = new FormGroup({
            "ledgerName": new FormControl(this.ledgerForEdit ? this.ledgerForEdit.name : null, Validators.required),
            "under": new FormControl(
                this.ledgerForEdit ? (foundLedgerType && foundLedgerType.id) : null,
                Validators.required
            ),
            "gstin": new FormControl(this.ledgerForEdit ? this.ledgerForEdit.gstin : null),
            "address": new FormControl(this.ledgerForEdit ? this.ledgerForEdit.address : null),
            "state": new FormControl(this.ledgerForEdit ? this.ledgerForEdit.state : null),
        });

        const foundIntegration = environment.integrations.find((item: IntegrationItem) =>
            item.serverType === (data.integration && data.integration.type)
        );
        this._typeMetaTypeMap = foundIntegration && foundIntegration.typeMetaTypeMap || {};
    }

    //endregion
    //region Hooks

    ngOnDestroy() {

        this._globalUnsubscribe$.next();
        this._globalUnsubscribe$.complete();
    }

    //endregion
    //region Events

    /**
     * Показывать gstin для создания/редактирования контрагента?
     *
     * Возвращает true только для определенных типов контрагентов.
     */
    showGstinHandler() {

        const selectedTypeId = this.ledgerGroup.get("under").value;
        const selectedType = this.ledgerTypes && this.ledgerTypes.find(type => type.id === selectedTypeId);
        return selectedType
            && (selectedType.name === "Sundry debtors"
                || selectedType.name === "Sundry creditors"
                || (selectedType.meta && selectedType.meta.displayFields && selectedType.meta.displayFields.gstin)
            );
    }

    /**
     * Показывать штат для создания/редактирования контрагента?
     *
     * Возвращает true только для определенных типов контрагентов.
     */
    showStateHandler() {

        const selectedTypeId = this.ledgerGroup.get("under").value;
        const selectedType = this.ledgerTypes && this.ledgerTypes.find(type => type.id === selectedTypeId);
        return selectedType
            && (selectedType.name === "Sundry debtors"
                || selectedType.name === "Sundry creditors"
                || (selectedType.meta && selectedType.meta.displayFields && selectedType.meta.displayFields.state)
            );
    }

    /**
     * Показывать адрес для создания/редактирования контрагента?
     *
     * Возвращает true только для определенных типов контрагентов.
     */
    showAddressHandler() {

        const selectedTypeId = this.ledgerGroup.get("under").value;
        const selectedType = this.ledgerTypes && this.ledgerTypes.find(type => type.id === selectedTypeId);
        return selectedType
            && (selectedType.name === "Sundry debtors"
                || selectedType.name === "Sundry creditors"
                || (selectedType.meta && selectedType.meta.displayFields && selectedType.meta.displayFields.address)
            );
    }

    /**
     * Обработчик события закрытия диалога.
     */
    cancelBtnHandler() {

        this._store.dispatch(matchingActions.cancelLedgerDialog());
        this._dialogRef.close();
    }

    /**
     * Обработчик события нажатия кнопки создания контрагента.
     *
     * Если режим редактирования изменения, то пересоздает их.
     */
    okBtnHandler() {
        const selectedTypeId = this.ledgerGroup.get("under").value;
        const selectedType = this.ledgerTypes.find(type => type.id === selectedTypeId);

        let newObject: CrossEntity;

        let ledgerId = CreateLedgerDlgComponent.NEW_LEDGER_TEMPLATE.id + v4();
        if (this.ledgerForEdit && this.ledgerForEdit.id) {

            ledgerId = this.ledgerForEdit.id;
        }

        newObject = this._createCrossEntity(ledgerId, selectedType.type, selectedType);

        if (this.ledgerForEdit && this.ledgerForEdit.id) {

            this._store.dispatch(matchingActions.editLedger({ value: newObject }));
        }
        else {

            this._store.dispatch(matchingActions.createLedger({ value: newObject }));
        }
        this._dialogRef.close();
    }

    //endregion
    //region Private

    /**
     * Создает сущность нового контрагента.
     *
     * @param selectedType Выбранный тип контрагента
     * @param type Тип контрагента.
     * @param ledgerId id нового контрагента
     */
    private _createCrossEntity(ledgerId: string, type: string, selectedType: ExternalLedgerType): CrossEntity {

        return {
            id: ledgerId,
            name: this.ledgerGroup.get("ledgerName").value,
            type: selectedType.name,
            gstin: this.ledgerGroup.get("gstin").value,
            address: this.ledgerGroup.get("address").value,
            state: this.ledgerGroup.get("state").value,
            metaType: this._typeMetaTypeMap[selectedType.underName] || "unknown",
            entityType: type as CrossEntityType,
        };
    }

    //endregion
}
