import { overlayPanelClassProvider } from "./overlay-panel-class.provider";
import { matDateFormats, matMomentDateAdapterOptionsProvider } from "./mat-datepicker.provider";
import { Title } from "@angular/platform-browser";
import { enteraHttpInterceptorProvider } from "./global-http-interceptor.provider";

export const providers = [
    overlayPanelClassProvider,
    matMomentDateAdapterOptionsProvider,
    matDateFormats,
    Title,
    enteraHttpInterceptorProvider
];
