import { createSelector } from "@ngrx/store";
import { spaceSelector } from "rootStore";
import { BatchDocumentsActionProps } from "src/app/spaces/modules/documents-registry/store/actions/props/batch-documents-action.props";
import { BatchRegistryFilterActionProps } from "src/app/spaces/modules/documents-registry/store/actions/props/batch-registry-filter-action.props";
import { DocumentsRegistryState, documentsRegistryStateSelector } from "../reducers";
import { getHiddenNewDocuments } from "../reducers/documents.reducers";
import { getNewDocuments } from "../reducers/documents.reducers";
import { getDocuments, getDocumentsEnded } from "../reducers/documents.reducers";
import { getDocumentsLoading } from "../reducers/documents.reducers";
import { getDocumentsLoaded } from "../reducers/documents.reducers";
import { getDocumentsFailed } from "../reducers/documents.reducers";
import { getDocumentsInfinityLoading } from "../reducers/documents.reducers";
import { getDocumentsInfinityLoaded } from "../reducers/documents.reducers";
import { getDocumentsInfinityFailed } from "../reducers/documents.reducers";
import { getDeletionError } from "../reducers/documents.reducers";
import { DocumentsState } from "../reducers/documents.reducers";
import { documentsCountSelector } from "src/app/spaces/modules/documents-registry/store/selectors/documents-count.selector";
import { recognizedDocumentsSelector } from "rootStore";

/**
 * Селектор состояния документов.
 */
export const documentsStateSelector = createSelector(
    documentsRegistryStateSelector,
    (state: DocumentsRegistryState) => state.documentsState
);

/**
 * Селектор документов.
 */
export const documentsSelector = createSelector(documentsStateSelector, getDocuments);

/**
 * Селектор новых документов.
 */
export const newDocumentsSelector = createSelector(documentsStateSelector, getNewDocuments);

/**
 * Селектор флага наличия скрытых фильтром новых документов.
 */
export const hiddenNewDocumentsSelector = createSelector(documentsStateSelector, getHiddenNewDocuments);

/**
 * Селектор флага выполняющейся загрузки документов.
 */
export const documentsLoadingSelector = createSelector(documentsStateSelector, getDocumentsLoading);

/**
 * Селектор флага успешной загрузки документов.
 */
export const documentsLoadedSelector = createSelector(documentsStateSelector, getDocumentsLoaded);

/**
 * Селектор флага неудачной загрузки документов.
 */
export const documentsFailedSelector = createSelector(documentsStateSelector, getDocumentsFailed);

/**
 * Селектор флага выполняющейся догрузки документов.
 */
export const documentsInfinityLoadingSelector = createSelector(documentsStateSelector, getDocumentsInfinityLoading);

/**
 * Селектор флага успешной догрузки документов.
 */
export const documentsInfinityLoadedSelector = createSelector(documentsStateSelector, getDocumentsInfinityLoaded);

/**
 * Селектор флага неудачной догрузки документов.
 */
export const documentsInfinityFailedSelector = createSelector(documentsStateSelector, getDocumentsInfinityFailed);

/**
 * Селектор флага полной загрузки документов из состояния документов.
 */
export const documentsEndedSelector = createSelector(documentsStateSelector, getDocumentsEnded);

/**
 * Селектор ответа от API об ошибке при удалении.
 */
export const documentsDeletionErrorSelector = createSelector(documentsStateSelector, getDeletionError);

/**
 * Выбранные документы в рееестре документов.
 */
export const selectedDocuments = createSelector(
    documentsStateSelector,
    recognizedDocumentsSelector,
    (state: DocumentsState, recDocs ) => {

        if (state.allDocumentsSelected && recDocs.length > 0) {

            return [...state.selectedDocuments, ...recDocs];
        }
        else {

            return state.selectedDocuments;
        }
    }
);
/**
 * Колличество выбранных документов в рееестре документов.
 */
export const selectedDocumentsCount = createSelector(
    documentsStateSelector,
    documentsCountSelector,
    (state: DocumentsState, count: number) => {

        if (!state.allDocumentsSelected) {

            return state.selectedDocuments.length;
        }
        else {

            return count - state.excludedDocuments.length;
        }
    }
);

/**
 * Были выбраны все документы?
 */
export const allDocumentsSelected = createSelector(documentsStateSelector, (state) => state.allDocumentsSelected);

/**
 * Документы на исключения из массовых действий.
 */
export const excludedDocuments = createSelector(documentsStateSelector, (state) => state.excludedDocuments);

/**
 * Флаг отображения фильтра реестра документов.
 */
export const showFiltersSelector = createSelector(documentsStateSelector, (state) => state.showFilters);

/**
 * Возвращает данные массовых действий с документами.
 */
export const batchDocumentsProps = createSelector(
    allDocumentsSelected,
    selectedDocuments,
    excludedDocuments,
    (allSelected, selected, excluded): BatchDocumentsActionProps => ({
        allDocumentsSelected: allSelected,
        selectedDocuments: selected,
        excludedDocuments: excluded,
    }),
);

/**
 * Возвращает данные массовых действий для документов.
 */
export const batchRegistryFilterProps = createSelector(
    batchDocumentsProps,
    spaceSelector,
    documentsStateSelector,
    (props, space, documentsState): BatchRegistryFilterActionProps => ({ ...props, space, documentsState }),
);
