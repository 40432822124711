import { Directive } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { AbstractControl } from "@angular/forms";
import { NG_ASYNC_VALIDATORS } from '@angular/forms';
import { ValidationErrors } from '@angular/forms';
import { AsyncValidator } from '@angular/forms';

import { of } from "rxjs";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { switchMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { delay } from 'rxjs/operators';
import { tap } from 'rxjs/operators';

import { UserService } from '../services/user.service'; /* circular dependency break */
import { ApiResponse } from '../models';

/**
 * Асинхронная валидация занят логин или нет.
 */
@Directive({
    selector: '[available-login]',
    providers: [ { provide: NG_ASYNC_VALIDATORS, useExisting: AvailableLoginValidator, multi: true } ]
})
export class AvailableLoginValidator implements AsyncValidator {
    //region Private fields

    /**
     * Функция асинхронной валидации занят логин или нет.
     *
     * @private
     */
    private readonly _validate: (control: AbstractControl) => Observable<ValidationErrors | null>;

    //endregion
    //region Ctor

    constructor(userService: UserService) {
        this._validate = AvailableLoginValidator.get(userService);
    }

    //endregion
    //region Public static

    /**
     * Возвращает функцию асинхронной валидации занят логин или нет.
     *
     * @param userService Сервис с логикой для работы с пользователем.
     * @param debounce Время откладывания запроса на сервер между последовательными вызовами функции валидации.
     * @param cd Сервис для управления запуском определения angular'ом изменений данных, произошедших в компоненте.
     *
     * @return Функция асинхронной валидации занят логин или нет.
     */
    static get(
        userService: UserService,
        debounce: number = 300,
        cd: ChangeDetectorRef = null,
    ): (control: AbstractControl) => Observable<ValidationErrors | null> {

        return (control: AbstractControl) => {

            return of({})
                .pipe(
                    // Используется функция delay, т.к. именно она даст логику debounce, т.к. последовательные вызовы
                    // функции валидации будут отменять предыдущие вызовы, если между вызовами прошло меньше, чем
                    // заданное значение.
                    delay(debounce),
                    switchMap((): Observable<ValidationErrors | null> => userService.isLoginAvailable(control.value)
                        .pipe(
                            map((isLoginAvailable: boolean): ValidationErrors | null => {

                                return (isLoginAvailable
                                    ? null
                                    : { availableLogin: true }
                                );
                            }),
                            catchError((apiResponse: ApiResponse): Observable<ValidationErrors | null> => of({
                                availableLoginFailed: true,
                                availableLoginError: apiResponse,
                            })),

                            // Хак, т.к. асинхронный валидатор не отрабатывает (т.е. поле не подсвечивается красным
                            // цветом), если асинхронная валидация ещё не выполнилась, а фокус с поля был убран.
                            tap((): void => cd && cd.markForCheck()),
                        )
                    ),
                );
        }
    }

    //endregion
    //region Public

    /**
     * Выполняет асинхронную валидацию занятости логина для заданного поля формы.
     *
     * @param control Поле формы.
     *
     * @return Результат валидации.
     */
    validate(control: AbstractControl): Observable<ValidationErrors | null> {

        return this._validate(control);
    }

    //endregion
}
