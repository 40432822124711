<div
    #pagesViewer
    class="pages-viewer"
    [class.pages-viewer_top-navigation]="placeNavigationButtonsTop"
    [class.pages-viewer_bottom-navigation]="!placeNavigationButtonsTop"
    [class.pages-viewer_matching-mode]="matchingMode"
>
    <div #viewport
        class="pages-viewer__viewport"
        (mousedown)="onMousedown()"
        (dragstart)="$event.preventDefault()"
    >
        <div class="pages-viewer__page-image-wrapper"
            [style.top]="pageTop + 'px'"
            [style.left]="pageLeft + 'px'"
            [style.transform]="imageTransformStyle"
            [style.visibility]="imageLoading ? 'hidden' : 'visible'"
        >
            <img #image
                class="pages-viewer__page-image"
                [src]="currentPageUrl"
                (load)="imageLoadHandler()"
            />
        </div>
        <mat-spinner *ngIf="imageLoading"></mat-spinner>
    </div>
    <button
        class="pages-viewer__button pages-viewer__fullscreen"
        mat-icon-button
        (click)="handleFullscreenButtonClick()"
    >
        <mat-icon *ngIf="!fullscreen" class="mat-icon_large">fullscreen</mat-icon>
        <mat-icon *ngIf="fullscreen" class="mat-icon_large">fullscreen_exit</mat-icon>
    </button>
    <button
        class="pages-viewer__button pages-viewer__rotate"
        mat-icon-button
        (click)="rotate()"
    >
        <mat-icon class="mat-icon_large">rotate_right</mat-icon>
    </button>
    <button
        class="pages-viewer__button pages-viewer__zoom-in"
        mat-icon-button
        (click)="increaseScale()"
    >
        <mat-icon class="mat-icon_large">zoom_in</mat-icon>
    </button>
    <button
        class="pages-viewer__button pages-viewer__zoom-out"
        mat-icon-button
        (click)="decreaseScale()"
    >
        <mat-icon class="mat-icon_large">zoom_out</mat-icon>
    </button>
    <button
        *ngIf="matchingMode"
        class="pages-viewer__button pages-viewer__close-button"
        mat-icon-button
        (click)="closeScanViewer.emit()"
    >
        <mat-icon class="mat-icon_large">close</mat-icon>
    </button>
    <div
        *ngIf="!matchingMode"
        class="pages-viewer-navigation pages-viewer__navigation"
        [class.pages-viewer__navigation_top]="placeNavigationButtonsTop"
        [class.pages-viewer__navigation_bottom]="!placeNavigationButtonsTop"
    >
        <div class="pages-viewer-navigation__page-number"
            [class.pages-viewer-navigation__page-number_selected]="isDocumentPage(currentPage)"
        >
            {{currentPage}} {{ 'common.pageOf' | translate }} {{pageUrls.length}}
            <span *ngIf="showLocalNavigation && documentPage">
                ({{documentPage}} {{ 'common.of' | translate }} {{documentPages.length}})
            </span>
        </div>
        <button
            class="pages-viewer-navigation__back"
            mat-icon-button
            (click)="prevPage()"
        >
            <mat-icon>chevron_left</mat-icon>
        </button>
        <button
            class="pages-viewer-navigation__forward"
            mat-icon-button
            (click)="nextPage()"
        >
            <mat-icon>chevron_right</mat-icon>
        </button>
    </div>
    <div *ngIf="matchingMode" class="pages-viewer-navigation_matching-mode">
        <div class="pages-viewer-navigation__document-title">{{ documentHeader }}</div>
        <button
            class="pages-viewer-navigation__back"
            mat-icon-button
            (click)="prevPage()"
        >
            <mat-icon>chevron_left</mat-icon>
        </button>
        <div class="pages-viewer-navigation__page-number"
             [class.pages-viewer-navigation__page-number_selected]="isDocumentPage(currentPage)"
        >
            {{currentPage}} {{ 'common.pageOf' | translate }} {{pageUrls.length}}
            <span *ngIf="showLocalNavigation && documentPage">
                ({{documentPage}} {{ 'common.of' | translate }} {{documentPages.length}})
            </span>
        </div>
        <button
            class="pages-viewer-navigation__forward"
            mat-icon-button
            (click)="nextPage()"
        >
            <mat-icon>chevron_right</mat-icon>
        </button>
    </div>
</div>
