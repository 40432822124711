import { OnDestroy } from "@angular/core";
import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ValidationErrors } from "@angular/forms";
import { AbstractControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { Validators } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { select } from "@ngrx/store";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { map } from "rxjs/operators";
import { ApiResponse } from "src/app/common/models/api-response";
import { Space } from "src/app/common/models/space";
import { GenerateHashForFileLinksAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { GenerateHashForFilesAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { UploadTaskByMobileToRecognizeAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { ChangeTaskToRecognizeAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { RemoveTaskToRecognizeAction } from "src/app/root/store/actions/recognize/upload-to-recognize.action";
import { RouterGoAction } from "src/app/root/store/actions/router.action";
import { uploadToRecognizeSelector } from "src/app/root/store/reducers/index";
import { UploadToRecognizeState } from "src/app/root/store/reducers/index";
import { RootState } from "src/app/root/store/reducers/index";
import { SelectedSpaceForMobileSelectors } from "src/app/root/store/selectors/selected-space-for-mobile.selector";

/**
 * Компонент мобильного интерфейса для загрузки документов на распознавание.
 */
@Component({
    selector: "mobile-upload-document",
    styleUrls: ["./mobile-document-upload.component.scss"],
    templateUrl: "./mobile-document-upload.component.html"
})
export class MobileDocumentUploadComponent implements OnInit, OnDestroy {
    //region Constants

    /**
     * Регекс ссылки чека самозанятых с условием, что после /receipt/ идет ровно 12 символов и после / и затем 10
     * символов.
     */
    private static readonly SELF_EMPLOYED_PATTERN: RegExp =
        /(?:http[s]?:\/\/)?lknpd\.nalog\.ru\/api\/v1\/receipt\/\d{12}\/[a-zA-Z0-9]{10}(\/print\/?|\/?)?/g;

    //endregion
    //region Public Fields

    /**
     * Состояние приложения.
     */
    readonly store: Store<RootState>;

    /**
     * Переключатель, чтобы при выполнении onDestroy произошли отписки от всех подписок.
     *
     */
    readonly destroyed$: Subject<void> = new Subject();

    /**
     * Состояние данных для отправки файлов на распознавание.
     */
    readonly uploadToRecognizeState$: Observable<UploadToRecognizeState>;

    /**
     * ID пространства выбранного документа.
     */
    readonly space$: Observable<Space>;

    /**
     * Необходимо отображать процесс загрузки в интерфейсе?
     */
    readonly loading$: Observable<boolean>;

    /**
     * Ошибка в процессе отправки документов на распознавание?
     */
    readonly error$: Observable<ApiResponse>;

    /**
     * Процесс отправки документов успешно завершен?
     */
    readonly loaded$: Observable<boolean>;

    /**
     * Есть ли файлы для отправки на распознавание?
     */
    readonly hasOnlyFiles$: Observable<boolean>;

    /**
     * Группа контролов для отправки документа.
     */
    readonly mobileFormGroup: FormGroup;

    /**
     * Реактивный контрол ссылки чека самозанятых на распознавание.
     */
    readonly linkInputFormCtrl: FormControl;

    //endregion
    //region Ctor

    /**
     * Конструктор мобильного интерфейса для загрузки документов на распознавание.
     *
     * @param store Сервис для доступа и управления состоянием приложения.
     * @param formBuilder Сервис для создания реактивных форм.
     */
    constructor (store: Store<RootState>, formBuilder: FormBuilder) {

        this.store = store;

        this.uploadToRecognizeState$ = this.store.select(uploadToRecognizeSelector)
            .pipe(
                takeUntil(this.destroyed$),
            );
        this.loading$ = this.uploadToRecognizeState$
            .pipe(
                map((state: UploadToRecognizeState): boolean => state.loading || state.duplicateCheckingByHash),
            );
        this.error$ = this.uploadToRecognizeState$
            .pipe(
                map((state: UploadToRecognizeState): ApiResponse => state.error),
            );
        this.loaded$ = this.uploadToRecognizeState$
            .pipe(
                map((state: UploadToRecognizeState): boolean => state.loaded),
            );
        this.hasOnlyFiles$ = this.uploadToRecognizeState$
            .pipe(
                map((state: UploadToRecognizeState) => (state.filesWithMetaData || []).every(file => !file.link))
            );
        this.space$ = this.store
            .pipe(
                takeUntil(this.destroyed$),
                select(SelectedSpaceForMobileSelectors.space),
            );

        this.linkInputFormCtrl = formBuilder.control(null, ctrl => this._linkHasIncorrectPartsValidator(ctrl));
        this.mobileFormGroup = formBuilder.group({
            comment: formBuilder.control(null, [Validators.required]),
            link: this.linkInputFormCtrl,
        });
    }

    //endregion
    //region Hooks

    ngOnInit(): void {

        this.uploadToRecognizeState$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(state => {

            if (state && state.filesWithMetaData.length && state.filesWithMetaData.some(file => !file.link)) {

                this.linkInputFormCtrl.setValue(null);
                this.linkInputFormCtrl.disable();
            }
            else {

                this.linkInputFormCtrl.enable();
            }
        });
        this.linkInputFormCtrl.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((value: string) => this.addLinksHandler(value));
    }

    ngOnDestroy(): void {

        this.destroyed$.next();
        this.destroyed$.complete();
    }

    //endregion
    //region Events

    /**
     * Переход на корневую страницу мобильного интерфейса приложения.
     */
    cancelDocumentUpload(): void {

        this.store.dispatch(new RouterGoAction({path: ["mobile"]}));
    }

    /**
     * Обработчик добавления файлов в массив файлов в состоянии для их отправки на распознавание. Фильтрует файлы,
     * которые уже есть в состоянии.
     *
     * @param files Файлы, которые нужно добавить.
     */
    addFilesHandler(files: File[]) {

        this.store.dispatch(new GenerateHashForFilesAction(files.filter(file => !!file.size)));
    }

    /**
     * Обработчик удаления файлов из массива файлов для отправки на распознавание.
     *
     * @param file Файл, который нужно удалить.
     */
    removeFileHandler(file: File) {

        this.store.dispatch(new RemoveTaskToRecognizeAction(file));
    }

    /**
     * Обработчик возвращения состояния данных при загрузке и отправке файлов на распознавание к первоначальному
     * состоянию.
     */
    clearState() {

        this.store.dispatch(
            new ChangeTaskToRecognizeAction({...new UploadToRecognizeState(), openPostUploadDialog: false})
        );
    }

    /**
     * Обработчик отправки файлов на распознавание.
     */
    uploadToRecognize() {

        this.store.dispatch(
            new UploadTaskByMobileToRecognizeAction(this.mobileFormGroup.get("comment").value)
        );
    }

    /**
     * Обрабатывает событие изменения ссылок в инпуте.
     *
     * @param value Строка для разбития на ссылки.
     */
    addLinksHandler(value: string) {

        const trimmedValue: string = value && value.replace(/\s+/g, "") || "";
        let links: string[] = trimmedValue.match(MobileDocumentUploadComponent.SELF_EMPLOYED_PATTERN) || [];

        links = links
            .map(link => link.replace(/\/+$/g, ""))
            .map(link => link.replace(/^\/+/g, ""))
            .map(link => link.endsWith("/print") ? link : `${link}/print`)
            .map(link => link.startsWith("http") ? link : `https://${link}`);

        if (links && links.length) {

            this.store.dispatch(new GenerateHashForFileLinksAction({ value: links}));
        }
        const remainingText = (value || "").replace(MobileDocumentUploadComponent.SELF_EMPLOYED_PATTERN, "");

        this.linkInputFormCtrl.setValue(remainingText, { emitEvent: false });
        this.linkInputFormCtrl.markAsTouched();
    }

    /**
     * Очищает инпут ссылок чеков самозанятых.
     */
    clearLinksHandler() {

        this.linkInputFormCtrl.setValue(null);
    }

    //endregion
    //region Private

    /**
     * Валидатор корректности всех частей строки с ссылками на чеки самозанятых.
     *
     * @param control Контрол с данными.
     */
    private _linkHasIncorrectPartsValidator(control: AbstractControl): ValidationErrors | null {

        if (control && control.value) {

            return control.value.replace(MobileDocumentUploadComponent.SELF_EMPLOYED_PATTERN, "").length
                ? { hasIncorrectParts: true }
                : null;
        }

        return null;
    }

    //endregion
}
