import { Injectable } from "@angular/core";
import { NavigationStart } from "@angular/router";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { startWith } from "rxjs/operators";
import { pairwise } from "rxjs/operators";
import { map } from "rxjs/operators";
import { filter } from "rxjs/operators";

/**
 * Сервис для работы с историей навигации по страницам приложения.
 */
@Injectable({
    providedIn: "root",
})
export class RouterNavigationHistoryService {
    //region Constants

    /**
     * Максимальное количество записей в истории навигации.
     */
    private static readonly HISTORY_SIZE = 10;

    //endregion
    //region Private

    /**
     * Массив с историей навигации по страницам приложения.
     */
    private _routerNavigations: string[] = [];

    /**
     * Активная подписка на изменение навигации по страницам.
     */
    private _activeSubscription: Subscription;

    //endregion
    //region Ctor

    /**
     * Конструктор сервиса.
     *
     * @param _router Сервис для работы с навигацией по приложению.
     */
    constructor(private _router: Router) {}

    //endregion
    //region Public

    /**
     * Инициализирует логику отслеживания навигации и сохранения ее в историю.
     */
    public init() {

        if (this._activeSubscription) {

            this._activeSubscription.unsubscribe();
        }

        this._activeSubscription = this._router.events
            .pipe(
                filter(event => event instanceof NavigationStart),
                map((event: NavigationStart) => event.url),
                startWith(""),
                pairwise(),
            )
            .subscribe(([prevPath, currPath]) => {

                if (currPath !== prevPath) {

                    this._addNavigationToHistory(currPath);
                }
            });
    }

    /**
     * Возвращает предыдущий путь навигации. Если его нет, возвращает null.
     */
    public getPreviousPath(): string {

        if (this._routerNavigations.length > 1) {

            return this._routerNavigations[this._routerNavigations.length - 2].split("?")[0];
        }

        return null;
    }

    //endregion
    //region Private

    /**
     * Записывает путь в историю навигации.
     *
     * @param path Путь.
     */
    private _addNavigationToHistory(path: string) {

        if (this._routerNavigations.length >= RouterNavigationHistoryService.HISTORY_SIZE) {

            this._routerNavigations.splice(
                0,
                this._routerNavigations.length - (RouterNavigationHistoryService.HISTORY_SIZE - 1)
            );
        }

        this._routerNavigations.push(path);
    }

    //endregion
}
