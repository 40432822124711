<mat-select
    #selectComponent
    [compareWith]="compareWith"
    [placeholder]="placeholder"
    [panelClass]="panelClass"
    [required]="required"
    [multiple]="multiple"
    [disableRipple]="disableRipple"
    [errorStateMatcher]="errorStateMatcher"
    [tabIndex]="tabIndex"
    class="entera-select-wrapper"
>
    <mat-select-trigger>
        <ng-template
            [ngTemplateOutlet]="selectedTemplate"
            [ngTemplateOutletContext]="{$implicit: formControl.value, control: formControl}"
        >
        </ng-template>
    </mat-select-trigger>

    <!--TODO virtual scroll - надо динамически вычислять высоту опций. Надо сделать как отдельную опцию. Включить все что ниже.-->
    <div class="search-wrap">
        <div class="search-wrap__info"><ng-template [ngTemplateOutlet]="aboveInfoTemplate"></ng-template></div>
        <ngx-mat-select-search
            *ngIf="searchFn"
            [formControl]="searchFormControl"
            [placeholderLabel]="'search.startTyping' | translate"
            [noEntriesFoundLabel]="null"
            [disableScrollToActiveOnOptionsChanged]="true"
            (keydown)="keydownHandler($event)"
        >
        </ngx-mat-select-search>
    </div>
    <mat-option
        *ngIf="formControl.value && showSelectedOption && !multiple"
        [value]="formControl.value"
    >
        <ng-template
            [ngTemplateOutlet]="optionTemplateView"
            [ngTemplateOutletContext]="{$implicit: formControl.value, search: searchFormControl?.value || ''}"
        >
        </ng-template>
    </mat-option>
    <ng-container *ngIf="formControl.value && multiple">
        <mat-option
            *ngFor="let value of formControl.value"
            [value]="value"
        >
            <ng-template
                [ngTemplateOutlet]="optionTemplateView"
                [ngTemplateOutletContext]="{$implicit: value, search: searchFormControl?.value || ''}"
            >
            </ng-template>
        </mat-option>
    </ng-container>

    <mat-option
        *ngFor="let option of (optionList$ | async)"
        [value]="option"
        [ngClass]="{'entera-select-wrapper__option_hidden': (loading$ | async)}"
    >
        <ng-template
            [ngTemplateOutlet]="optionTemplateView"
            [ngTemplateOutletContext]="{$implicit: option, search: searchFormControl?.value || ''}"
        >
        </ng-template>
    </mat-option>

    <mat-option
        [value]="loadingValue"
        [disabled]="true"
        [ngClass]="{'entera-select-wrapper__option_hidden': !((loading$ | async) || (loadingPage$ | async))}"
    >
        <ng-template [ngTemplateOutlet]="loadingSpinner"></ng-template>
    </mat-option>

    <mat-option
        class="select__without-checkbox"
        [value]="noResultValue"
        [disabled]="true"
        [ngClass]="{'entera-select-wrapper__option_hidden': !(noResult$ | async) || !(searchLength$ | async)}"
    >
        <ng-container *ngIf="(searchLength$ | async) < searchMinLength && searchFn">
            <ng-template [ngTemplateOutlet]="lessSearchOption"></ng-template>
        </ng-container>

        <ng-container *ngIf="(searchLength$ | async) >= searchMinLength || !searchFn">
            <ng-template [ngTemplateOutlet]="noResultOption"></ng-template>
        </ng-container>
    </mat-option>

    <mat-option
        class="select__without-checkbox"
        *ngIf="maxSelectCountReached$ | async"
        [value]="infoOptionValue"
        [disabled]="true"
    >
        <ng-template [ngTemplateOutlet]="infoOption"></ng-template>
    </mat-option>

    <mat-option
        *ngIf="!(optionList$ | async)?.length"
        class="entera-select-wrapper__option_hidden"
        [value]="fakeSelectedValue"
        [disabled]="true"
    ></mat-option>

</mat-select>

<ng-template #defaultOptionView let-value>
    {{value?.name || value}}
</ng-template>

<ng-template #loadingSpinner>
    <mat-spinner class="entera-select-wrapper__option_spinner" [diameter]="25"></mat-spinner>
</ng-template>

<ng-template #noResultOption>
    {{ 'search.empty.result' | translate }}
</ng-template>

<ng-template #lessSearchOption>
    {{'search.continueTyping' | translate}}
</ng-template>

<ng-template #infoOption>
    <span>{{ "enteraSelect.overloadMaxSelected" | translate }}</span>
</ng-template>
