import { createSelector } from "@ngrx/store";
import { PermissionItem } from "src/app/common/models";
import { Space } from "src/app/common/models";
import { ExpiryNotificationType } from "src/app/common/models/expiry-notification-type";
import { SpacePermission } from "src/app/common/models/space-permission-type.model";
import { CurrentUserInfo } from "../../../common/models";

import { currentUserStateSelector } from "../reducers";
import { getCurrentUserInfo } from "../reducers/current-user.reducer";
import { getCurrentUserLang } from "../reducers/current-user.reducer";
import { getCurrentUserFailed } from "../reducers/current-user.reducer";
import { getCurrentUserLoaded } from "../reducers/current-user.reducer";
import { getCurrentUserLoading } from "../reducers/current-user.reducer";
import { AdminPermissionItem } from "src/app/common/models/admin-permission-item";
import { SpacePermissionItem } from "src/app/common/models/space-permission-item";

/**
 * Селектор данных текущего пользователя.
 */
export const currentUserInfoSelector = createSelector(currentUserStateSelector, getCurrentUserInfo);

/**
 * Селектор пространств документов текущего пользователя.
 */
export const currentUserSpacesSelector = createSelector(
    currentUserInfoSelector,
    (user) => user ? user.spaces : null
);

/**
 * Селектор языка текущего пользователя.
 */
export const currentUserLangSelector = createSelector(currentUserInfoSelector, getCurrentUserLang);

/**
 * Селектор баланса клиента пользователя.
 */
export const currentUserClientBalance = createSelector(
    currentUserInfoSelector,
    user => user && user.client && user.client.balance || 0,
);

/**
 * Селектор даты истечения баланса клиента пользователя.
 */
export const currentUserClientBalanceExpiration = createSelector(
    currentUserInfoSelector,
    user => user && user.client && user.client.balanceExpirationDate,
);

/**
 * Селектор прав текущего пользователя в виде объекта.
 */
export const currentUserPermissionsSelector = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo) => user.permissions
        .reduce((p: { [key: string]: boolean }, item: string) => { p[item] = true; return p; }, {})
);

/**
 * Селектор прав текущего пользователя в виде списка.
 */
export const permissionsSelector = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo) => user && user.permissions
);

/**
 * Селектор прав по папкам текущего пользователя.
 */
export const currentUserSpacePermissionsSelector = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo) => user && user.spacePermissions
);

/**
 * Селектор наличия указанного права.
 */
export const hasPermission = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo, props: { permission: PermissionItem }): boolean => user.permissions
        .some(
            (permission: string) => props.permission.value === permission
    )
);

/**
 * Селектор наличия всех указанных прав.
 */
export const hasAllPermissions = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo, props: { permissions: PermissionItem[] }): boolean =>
        props.permissions.every((permissionItem) =>
            user.permissions.includes(permissionItem.value)
        )
);

/**
 * Селектор наличия некоторых указанных прав.
 */
export const hasAnyPermission = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo, props: { permissions: PermissionItem[] }): boolean =>
        props.permissions.some((permissionItem) =>
            user.permissions.includes(permissionItem.value)
        )
);

/**
 * Селектор наличия права в папке.
 */
export const hasSpacePermission = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo, props: { spaceId: string, permission: PermissionItem }): boolean =>
        user.spacePermissions
            .some(spacePermission => spacePermission.spaceId === props.spaceId
                && spacePermission.permissions.some(
                    permission => props.permission.value === permission
                )
            )
);

/**
 * Селектор наличия всех указанных прав в папке.
 */
export const hasAllSpacePermissions = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo, props: { spaceId: string, permissions: PermissionItem[] }): boolean =>
        user.spacePermissions.some(spacePermission =>
            spacePermission.spaceId === props.spaceId &&
            props.permissions.every(permissionItem =>
                spacePermission.permissions.includes(permissionItem.value)
            )
        )
);

/**
 * Селектор наличия некоторых указанных прав в папке.
 */
export const hasAnySpacePermission = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo, props: { spaceId: string, permissions: PermissionItem[] }): boolean =>
        user.spacePermissions.some(spacePermission =>
            spacePermission.spaceId === props.spaceId &&
            props.permissions.some(permissionItem =>
                spacePermission.permissions.includes(permissionItem.value)
            )
        )
);


/**
 * Селектор флага успешной загрузки данных текущего пользователя.
 */
export const currentUserLoadedSelector = createSelector(currentUserStateSelector, getCurrentUserLoaded);

/**
 * Селектор флага выполняющейся загрузки данных текущего пользователя.
 */
export const currentUserLoadingSelector = createSelector(currentUserStateSelector, getCurrentUserLoading);

/**
 * Селектор флага была ли ошибка при загрузке данных текущего пользователя.
 */
export const currentUserFailedSelector = createSelector(currentUserStateSelector, getCurrentUserFailed);

/**
 * Селектор списка пространств документов пользователя, в которые у него есть права на приглашение
 */
const spacesForInvitations = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo): Space[] => (user ? user.spaces : [])
        .filter((space: Space): boolean =>
            user.spacePermissions.some((spacePermission: SpacePermission) =>
                spacePermission.spaceId === space.id
                && spacePermission.permissions.indexOf(SpacePermissionItem.INVITE_USERS.value) !== -1
            )
        )
);

/**
 * Селектор списка пространств документов пользователя, в которых у него есть права управлять интеграциями.
 */
const spacesForManagingIntegrations = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo): Space[] => (user ? user.spaces : [])
        .filter((space: Space): boolean =>
            user.spacePermissions.some((spacePermission: SpacePermission) =>
                spacePermission.spaceId === space.id
                && spacePermission.permissions.indexOf(SpacePermissionItem.INTEGRATION.value) !== -1
            )
        )
);

/**
 * Селектор списка пространств документов пользователя, в которых у него есть права управлять уведомлениями
 * пользователей на почту о состоянии задачи на распознавание.
 */
const spacesForManagingEmailNotifications = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo): Space[] => (user ? user.spaces : [])
        .filter((space: Space): boolean =>
            user.spacePermissions.some((spacePermission: SpacePermission) =>
                spacePermission.spaceId === space.id
                && spacePermission.permissions.indexOf(SpacePermissionItem.MANAGE_EMAIL_NOTIFICATIONS.value) !== -1
            )
        )
);

/**
 * Селектор наличия у текущего пользователя права приглашать хотя бы в одно своё пространство документов.
 */
const canInviteInAnySpace = createSelector(
    spacesForInvitations,
    (spaces: Space[]): boolean => spaces.length > 0
);

/**
 * Селектор наличия у текущего пользователя права видеть список доверенных почт.
 */
const canSeeTrustedEmails = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo): boolean => user.permissions.some((permission: string) =>
        AdminPermissionItem.SEE_TRUSTED_EMAILS.value === permission
    )
);

/**
 * Селектор активности подписки на функционал отчёта реализации текущего пользователя.
 */
const salesReportSubscriptionActive = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo): boolean => {

        if (user) {

            return (
                user.permissions.includes(AdminPermissionItem.SEE_ALL_DOCUMENTS.value)
                || user.client.salesReportSubscriptionActive
            );
        }

        return false;
    }
);

const spacesForDocumentRedirectionRules = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo): Space[] => {

        const hasPermission = (space: Space): boolean =>
            user.spacePermissions.some(
                (perm: SpacePermission) =>
                    perm.spaceId === space.id
                    && (
                        perm.permissions.includes(SpacePermissionItem.SEE_REDIRECT_RULES.value)
                        || perm.permissions.includes(SpacePermissionItem.EDIT_REDIRECT_RULES.value)
                    )
            );

        return user && user.spaces && user.spaces.filter(hasPermission) || [];

    }
);

/**
 * Селектор того, скоро ли истекает баланс или страницы у клиента.
 */
export const soonExpirationSelector = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo) => !!user
        && !user.permissions.includes(AdminPermissionItem.SEE_ALL_DOCUMENTS.value)
        && !!user.client.expiryNotificationType
        && !!user.client.expiryNotificationType.needShowOnUi
        && !!user.client.expiryNotificationStartDate
        && user.spaces.every(space => space.paid)
);

/**
 * Селектор типа уведомления клиента об истечении.
 */
export const expiryNotificationTypeSelector = createSelector(
    currentUserInfoSelector,
    (user: CurrentUserInfo): ExpiryNotificationType => !!user && user.client.expiryNotificationType || null
);

/**
 * Объект, объединяющий селекторы, которые относятся к состоянию данных текущего пользователя.
 */
export const currentUserSelectors = {
    spacesForInvitations,
    canInviteInAnySpace,
    canSeeTrustedEmails,
    hasPermission,
    hasAllPermissions,
    hasAnyPermission,
    hasSpacePermission,
    hasAllSpacePermissions,
    hasAnySpacePermission,
    spacesForManagingIntegrations,
    spacesForManagingEmailNotifications,
    salesReportSubscriptionActive,
    spacesForDocumentRedirectionRules,
};
