import { MoveDocumentsDlgEffect } from "src/app/common/store/effects/move-documents-dlg.effect";
import { PhoneConfirmDlgEffects } from "./phone-confirm-dlg.effect";

/**
 * Все side-эффекты, определённые в этой папке.
 */
export const effects: any[] = [
    PhoneConfirmDlgEffects,
    MoveDocumentsDlgEffect,
];
