import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { HostListener } from "@angular/core";
import { Inject } from "@angular/core";

import { Constants } from "../../../common/models";
import { RecognitionTask } from "../../../common/models";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

/**
 * Компонент диалога для отображения ошибки завершения задачи на распознавание.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "recognition-task-error-dlg",
    styleUrls: ["./recognition-task-error-dlg.component.scss"],
    templateUrl: "./recognition-task-error-dlg.component.html"
})
export class RecognitionTaskErrorDlgComponent {
    //region Constants

    /**
     * Максимальная длина строки имен файлов, которая будет отображаться в диалоге.
     */
    public static readonly FILES_MAX_LENGTH: number = 100;

    /**
     * Максимальная длина строки имени файла.
     *
     * Используется для определения необходимости переноса части имени на новую строку.
     */
    public static readonly FILE_MAX_LENGTH: number = 30;

    //endregion
    //region Fields

    /**
     * Задача на распознавание, которая завершилась ошибкой.
     */
    public readonly recognitionTask: RecognitionTask;

    /**
     * Диалог, в котором открывается текущий компонент.
     */
    private readonly _dialogRef: MatDialogRef<RecognitionTaskErrorDlgComponent>;

    //endregion
    //region Ctor

    /**
     * Конструктор компонента диалога для отображения ошибки завершения задачи на распознавание.
     *
     * @param dialogRef Диалог, в котором открывается текущий компонент.
     * @param recognitionTask Задача на распознавание, которая завершилась ошибкой.
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) recognitionTask: RecognitionTask,
        dialogRef: MatDialogRef<RecognitionTaskErrorDlgComponent>,
    ) {
        this.recognitionTask = recognitionTask;
        this._dialogRef = dialogRef;
    }

    //endregion
    //region Getters and Setters

    /**
     * Отформатированный список имен файлов в задаче на распознавание.
     */
    get files(): string[] {

        let result: string[] = [];
        let resultSumLength: number = 0;

        for (const file of this.recognitionTask.files) {

            if (resultSumLength < RecognitionTaskErrorDlgComponent.FILES_MAX_LENGTH) {

                let fileName: string;

                if (resultSumLength + file.name.length <= RecognitionTaskErrorDlgComponent.FILES_MAX_LENGTH) {

                    fileName = file.name;
                    resultSumLength += file.name.length;
                }
                else {

                    let fileNameLength: number = RecognitionTaskErrorDlgComponent.FILES_MAX_LENGTH - resultSumLength;
                    fileNameLength -= Constants.ELLIPSIS.length;

                    fileName = file.name.slice(0, fileNameLength) + Constants.ELLIPSIS;
                    resultSumLength += fileName.length + Constants.ELLIPSIS.length;
                }

                result = [
                    ...result,
                    fileName,
                ];
            }
        }

        return result;
    }

    //endregion
    //region Public

    /**
     * Имя файла слишком длинное для отображения в диалоговом окне в одну строчку?
     *
     * @param fileName Имя файла.
     *
     * @return Да/Нет.
     */
    isFileNameTooLong(fileName: string): boolean {

        return fileName.length > RecognitionTaskErrorDlgComponent.FILE_MAX_LENGTH;
    }

    //endregion
    //region Events

    /**
     * Обработчик нажатия на Esc.
     *
     * Закрывает диалог.
     */
    @HostListener("window:keyup.esc")
    onKeyUp() {

        this._dialogRef.close();
    }

    //endregion
}
